import React from 'react';
import {
  Box,
  Typography,
  Container,
  AppBar,
  Toolbar,
  useTheme
} from '@mui/material';
import logo from '../../images/logos/elevaide-logo.png';

const Terms = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Header */}
      <AppBar position="sticky" color="default" elevation={0} sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        bgcolor: 'background.default',
        backdropFilter: 'blur(8px)'
      }}>
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: 'space-between', height: 64, px: 0 }}>
            <Box component="a" href="/" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div className="logo-container">
                <img 
                  src={logo} 
                  alt="Elevaide Logo" 
                  style={{ width: 100, height: 'auto' }}
                />
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" component="h1" sx={{ 
          fontWeight: 'bold',
          mb: 4,
          background: 'linear-gradient(to right, #004D40, #80CBC4)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Terms and Conditions
        </Typography>
        
        <Typography variant="h5" sx={{ 
          color: 'text.secondary',
          textAlign: 'center',
          mt: 8
        }}>
          Terms and conditions are being written at the moment
        </Typography>
      </Container>
    </Box>
  );
};

export default Terms;
