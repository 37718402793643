import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Divider,
  Button,
  useTheme,
  alpha
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import GroupIcon from '@mui/icons-material/Group';
import BackupIcon from '@mui/icons-material/Backup';
import '../meetings/Meeting.css';
import './Upload.css';
import axios from "axios";
import config from "../../config";
import { trackProduct } from "../../common/googleanalytics";
import RacingGreenPalette from "../../RacingGreenPalette";

// Define SectionHeader component locally or import if centralized
const SectionHeader = ({ children }) => (
    <ListSubheader sx={{
        fontWeight: 'bold',
        color: RacingGreenPalette.primary.main, // Use the same color as in TeamFilterDropdown
        lineHeight: '2.5em',
        backgroundColor: 'background.paper' // Ensure background matches dropdown
    }}>
        {children}
    </ListSubheader>
);

// Helper to sort teams
const sortTeamsByName = (a, b) => a.name.localeCompare(b.name);

const Upload = () => {
    const theme = useTheme();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [allApiTeams, setAllApiTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [teamsLoading, setTeamsLoading] = useState(false);
    const [managerTeamIds, setManagerTeamIds] = useState(new Set());
    const [coachTeamIds, setCoachTeamIds] = useState(new Set());

    // Racing Green palette integration
    const racingGreen = {
        main: RacingGreenPalette.primary.main,
        light: RacingGreenPalette.primary.light,
        dark: RacingGreenPalette.primary.dark,
        background: RacingGreenPalette.background.main,
        gold: RacingGreenPalette.secondary.main
    };

    // Fetch teams and roles on component mount
    useEffect(() => {
        const fetchTeams = async () => {
            setTeamsLoading(true);
            setManagerTeamIds(new Set());
            setCoachTeamIds(new Set());
            setAllApiTeams([]);
            try {
                const response = await axios.get(`${config.backendUrlApiV1}/user/teams/`);
                const fetchedTeams = response.data.allTeams || [];
                const managerTeams = response.data.teamsUserIsManager || [];
                const coachTeams = response.data.teamsUserIsCoach || [];

                setManagerTeamIds(new Set(managerTeams.map(t => t.id)));
                setCoachTeamIds(new Set(coachTeams.map(t => t.id)));

                setAllApiTeams(fetchedTeams);

            } catch (error) {
                console.error('Error fetching teams:', error);
                setManagerTeamIds(new Set());
                setCoachTeamIds(new Set());
                setAllApiTeams([]);
            } finally {
                setTeamsLoading(false);
            }
        };
        
        fetchTeams();
    }, []);

    const handleOpenSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const generateFileName = (originalFileName) => {
        const now = new Date();
        const datePart = now.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD
        const timePart = now.toISOString().slice(11, 19).replace(/:/g, ''); // HHMMSS
        const randomPart = Math.random().toString(36).substring(2, 7); // 5 random chars
        const fileExtension = originalFileName.split('.').pop();
        const sanitizedOriginalName = originalFileName.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_');

        return `${datePart}_${timePart}_${randomPart}_${sanitizedOriginalName}.${fileExtension}`;
    };

    const getSignedUrl = async (file) => {
        try {
            const newFileName = generateFileName(file.name);
            const audioFileName = `audio_files/${newFileName}`;
            
            // Include team information if available
            const payload = {
                file_name: audioFileName,
                content_type: file.type,
                file_size: file.size,
            };
            
            if (selectedTeam) {
                payload.team_id = selectedTeam;
            }
            
            const response = await axios.post(`${config.backendUrlApiV1}/generate-signed-url/`, payload);

            const signedUrl = response.data.url;
            return {signedUrl, newFileName};
        } catch (error) {
            console.error('Error getting signed URL:', error);
            handleOpenSnackbar('Failed to get upload URL. Please try again.', 'error');
            throw error;
        }
    };

    const uploadFileToGCS = async (file, signedUrl) => {
        try {
            await axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });
            return true;
        } catch (error) {
            console.error('Error uploading file to GCS:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
            handleOpenSnackbar('Failed to upload file. Please try again.', 'error');
            return false;
        }
    };

    const notifyBackendForProcessing = async (fileName) => {
        try {
            const payload = {
                file_name: `audio_files/${fileName}`,
            };
            
            // Include team information if available
            if (selectedTeam) {
                payload.team_id = selectedTeam;
            }
            
            await axios.post(`${config.backendUrlApiV1}/process-gcs-file/`, payload);
            return true;
        } catch (error) {
            console.error('Error notifying backend for processing:', error);
            handleOpenSnackbar('Error notifying backend for processing. Please check the file status.', 'warning');
            return false;
        }
    };

    const onUpload = async (file) => {
        setIsLoading(true);
        try {
            if (file.size > 32 * 1024 * 1024) {
                if (file.size > config.maxFileSize) {
                    handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                    return;
                }

                // Handle files larger than 32MB
                let signedUrl, newFileName;
                try {
                    ({signedUrl, newFileName} = await getSignedUrl(file));
                } catch (error) {
                    console.error('Failed to get signed URL:', error);
                    handleOpenSnackbar('Error uploading file. Please try again.', 'error');
                    return;
                }

                try {
                    await uploadFileToGCS(file, signedUrl);
                } catch (error) {
                    console.error('Error uploading file to GCS:', error);
                    handleOpenSnackbar('Error uploading file. Please try again.', 'error');
                    return;
                }

                try {
                    await notifyBackendForProcessing(newFileName);
                    handleOpenSnackbar('File is being processed, please refresh Meetings tab in 5-10 minutes to see the insights.', 'success');
                } catch (error) {
                    console.error('Error notifying backend:', error);
                    handleOpenSnackbar('Error processing file. Please try again.', 'error');
                }

            } else {
                // Handle files smaller than or equal to 32MB
                const formData = new FormData();
                formData.append('file', file);
                
                // Include team information if available
                if (selectedTeam) {
                    formData.append('team_id', selectedTeam);
                }
                
                handleOpenSnackbar('File is being processed, please refresh Meetings tab in 5-10 minutes to see the insights', 'success');
                const uploadResponse = await axios.post(`${config.backendUrlApiV1}/upload-and-process/`, formData);
                const data = uploadResponse.data;
                if (uploadResponse.status === 200) {
                    handleOpenSnackbar(data.message, 'success');
                }
            }
            trackProduct.success('upload_file')
        } catch (error) {
            handleOpenSnackbar('An unexpected error occurred. Please try again.', 'error');
            trackProduct.error('upload_file_error')
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
            setUploadProgress(0);
        }
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleTeamChange = (event) => {
        const value = event.target.value;
        // Prevent selecting the header if somehow clickable
        if (typeof value === 'string' && value.startsWith('header-')) {
            return;
        }
        setSelectedTeam(value);
    };
    
    // Memoize the dropdown items generation
    const dropdownItems = useMemo(() => {
        const items = [];

        // 1. "None" option
        items.push(
            <MenuItem key="none" value="">
                <em>None</em>
            </MenuItem>
        );

        // Filter and sort teams based on roles
        const managedTeams = allApiTeams
            .filter(team => managerTeamIds.has(team.id))
            .sort(sortTeamsByName);
        const coachedTeams = allApiTeams
            .filter(team => coachTeamIds.has(team.id) && !managerTeamIds.has(team.id)) // Avoid duplicates
            .sort(sortTeamsByName);
        const otherTeams = allApiTeams
            .filter(team => !managerTeamIds.has(team.id) && !coachTeamIds.has(team.id))
            .sort(sortTeamsByName);

        // 2. "My Teams" section (Managed + Other)
        const myTeams = [...managedTeams, ...otherTeams].sort(sortTeamsByName);
        if (myTeams.length > 0) {
            items.push(<SectionHeader key="header-my-teams">My Teams</SectionHeader>);
            myTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        // 3. "Coached Teams" section
        if (coachedTeams.length > 0) {
            items.push(<SectionHeader key="header-coached">Coached Teams</SectionHeader>);
            coachedTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        // Handle case where no teams are available besides "None"
        if (items.length === 1 && !teamsLoading) { // Only "None" is present
             items.push(<MenuItem key="no-teams" disabled>No teams available</MenuItem>);
        }

        return items;
    }, [allApiTeams, managerTeamIds, coachTeamIds, teamsLoading]);

    // Helper to render file type icons
    const renderFileTypeIcons = () => (
        <Box sx={{ 
            display: 'flex', 
            gap: 3, 
            justifyContent: 'center', 
            mt: 2,
            mb: 2
        }}>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                color: racingGreen.main
            }}>
                <AudioFileIcon sx={{ fontSize: 32 }} />
                <Typography variant="caption" sx={{ mt: 0.5 }}>Audio</Typography>
            </Box>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                color: racingGreen.main
            }}>
                <VideocamIcon sx={{ fontSize: 32 }} />
                <Typography variant="caption" sx={{ mt: 0.5 }}>Video</Typography>
            </Box>
        </Box>
    );

    return (
        <Box className="tab-curved-view">
            {/* Header area with title */}
            <Box className="tab-header">
                <Typography 
                    variant="h5" 
                    sx={{ 
                        color: racingGreen.main,
                        fontWeight: 600,
                        py: 2,
                        px: 3
                    }}
                >
                    Upload Meeting Recording
                </Typography>
            </Box>

            {/* Main Content */}
            <Box className="tab-panel" sx={{ px: 3, py: 4 }}>
                <Box sx={{ maxWidth: 800, mx: 'auto' }}>
                    {/* Team selection dropdown */}
                    <FormControl 
                        fullWidth 
                        variant="outlined" 
                        sx={{ mb: 3 }}
                        disabled={isLoading || teamsLoading}
                    >
                        <InputLabel id="team-select-label">
                            Assign to Team (Optional)
                        </InputLabel>
                        <Select
                            labelId="team-select-label"
                            id="team-select"
                            value={selectedTeam}
                            onChange={handleTeamChange}
                            label="Assign to Team (Optional)"
                            startAdornment={
                                teamsLoading ? <CircularProgress size={20} sx={{ mr: 1 }} /> : <GroupIcon sx={{ mr: 1, color: racingGreen.main }} />
                            }
                            MenuProps={{ // Add MenuProps like in TeamFilterDropdown for consistency
                                PaperProps: {
                                    style: {
                                        maxHeight: 400, // Adjust as needed
                                    },
                                },
                            }}
                        >
                            {/* Render memoized dropdown items */}
                            {teamsLoading && dropdownItems.length <= 1 ? ( // Show loading only if no items yet besides maybe "None"
                                <MenuItem disabled>Loading teams...</MenuItem>
                            ) : (
                                dropdownItems
                            )}
                        </Select>
                    </FormControl>
                    
                    {renderFileTypeIcons()}
                    
                    <Box
                        sx={{
                            border: `2px dashed ${isLoading ? alpha(racingGreen.main, 0.3) : racingGreen.main}`,
                            borderRadius: 1,
                            p: 4,
                            mt: 2,
                            textAlign: 'center',
                            transition: 'all 0.3s',
                            cursor: isLoading ? 'default' : 'pointer',
                            '&:hover': {
                                backgroundColor: isLoading ? 'transparent' : alpha(racingGreen.main, 0.03),
                            },
                            height: { xs: 200, md: 250 },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                        onClick={() => !isLoading && document.getElementById('fileInput').click()}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        {isLoading ? (
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                alignItems: 'center', 
                                gap: 2,
                                width: '100%',
                                maxWidth: 400,
                                mx: 'auto',
                            }}>
                                <BackupIcon 
                                    sx={{ 
                                        fontSize: 48, 
                                        color: racingGreen.main,
                                        opacity: 0.8,
                                        animation: 'pulse 2s infinite ease-in-out',
                                        '@keyframes pulse': {
                                            '0%': { opacity: 0.6 },
                                            '50%': { opacity: 1 },
                                            '100%': { opacity: 0.6 }
                                        }
                                    }} 
                                />
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: racingGreen.main,
                                        mb: 1
                                    }}
                                >
                                    Uploading {selectedFile?.name}
                                </Typography>
                                <Box sx={{ width: '100%', position: 'relative' }}>
                                    <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            mb: 1, 
                                            display: 'flex', 
                                            justifyContent: 'space-between',
                                            color: alpha(theme.palette.text.primary, 0.7)
                                        }}
                                    >
                                        <span>Progress</span>
                                        <span>{uploadProgress.toFixed(0)}%</span>
                                    </Typography>
                                    <LinearProgress 
                                        variant="determinate" 
                                        value={uploadProgress} 
                                        sx={{ 
                                            height: 6, 
                                            borderRadius: 3,
                                            backgroundColor: alpha(racingGreen.main, 0.1),
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: racingGreen.main
                                            }
                                        }}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".mp3,.m4a,.wav,.mp4"
                                    style={{ display: 'none' }}
                                    onChange={handleFileSelect}
                                />
                                <Box 
                                    sx={{ 
                                        fontSize: 48, 
                                        color: racingGreen.main,
                                        mb: 2,
                                        width: 50,
                                        height: 50,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box 
                                        component="span" 
                                        sx={{
                                            fontSize: 48,
                                            lineHeight: 1,
                                            transform: 'rotate(180deg)',
                                            display: 'block'
                                        }}
                                    >
                                        ↓
                                    </Box>
                                </Box>
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        color: racingGreen.main,
                                        fontWeight: 500,
                                        mb: 1
                                    }}
                                >
                                    Drag and drop your file here
                                </Typography>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        color: alpha(theme.palette.text.primary, 0.7),
                                        mb: 3
                                    }}
                                >
                                    MP3, M4A, WAV or MP4 files • 2GB maximum
                                </Typography>
                                <Button
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    onClick={() => document.getElementById('fileInput').click()}
                                    sx={{
                                        backgroundColor: racingGreen.main,
                                        color: 'white',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: racingGreen.dark
                                        },
                                        px: 3
                                    }}
                                >
                                    Browse Files
                                </Button>
                            </>
                        )}
                    </Box>
                    
                    <Box sx={{ mt: 4 }}>
                        <Divider sx={{ mb: 2 }} />
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: alpha(theme.palette.text.primary, 0.6)
                            }}
                        >
                            You can access the meeting insights in the Meetings tab after processing.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleCloseSnackbar} 
                    severity={snackbarSeverity} 
                    sx={{ 
                        width: '100%',
                        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: 1,
                        ...(snackbarSeverity === 'success' && {
                            backgroundColor: alpha(racingGreen.main, 0.9),
                            color: 'white',
                            '& .MuiAlert-icon': {
                                color: 'white'
                            }
                        })
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Upload;
