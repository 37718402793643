const textColors = {
  primary: '#212121',    // Almost black - for headings and important text
  secondary: '#424242',  // Dark grey - for body text
  tertiary: '#757575',   // Medium grey - for less important text
  disabled: '#9E9E9E',   // Light grey - for disabled text
};

const RacingGreenPalette = {
    // Primary racing green colors
    primary: {
      main: '#004225', // British Racing Green
      // make main best practice color for headers 
      light: '#005c33', // Lighter racing green
      dark: '#00331c', // Darker racing green
      contrast: '#ffffff', // White text for contrast
    },
    
    // Secondary colors that complement racing green
    secondary: {
      main: '#b8860b', // Dark goldenrod - complementary to racing green
      light: '#d6a81d', // Lighter gold
      dark: '#9e7409', // Darker gold
      contrast: '#ffffff', // White text for contrast
    },
    
    // Accent colors for charts and visualizations
    accent: {
      green1: '#004225', // British Racing Green
      green2: '#006633', // Medium racing green
      green3: '#008040', // Light racing green
      green4: '#00994d', // Lighter racing green
      gold: '#d4af37',   // Gold
      bronze: '#cd7f32', // Bronze
      silver: '#aaa9ad', // Silver
      champagne: '#f7e7ce', // Light cream/champagne
    },
    
    // Chart color arrays for consistent data visualization
    // Main chart colors for sequential data (e.g., pie charts, bar charts)
    chartColors: [
      '#004225', // British Racing Green
      '#006633', // Medium racing green
      '#d4af37', // Gold
      '#cd7f32', // Bronze
      '#00994d', // Lighter racing green
      '#aaa9ad', // Silver
      '#008040', // Light racing green
      '#f7e7ce', // Champagne
    ],
    
    // Alternative set with more contrast for comparative visualizations
    contrastChartColors: [
      '#004225', // British Racing Green
      '#d4af37', // Gold
      '#008040', // Light racing green
      '#cd7f32', // Bronze
      '#00994d', // Lighter racing green
      '#aaa9ad', // Silver
    ],
    
    // Color set for interaction maps
    interactionColors: {
      node: {
        background: '#004225', // Racing green for nodes
        border: '#d4af37',     // Gold border
        highlight: {
          background: '#008040', // Lighter green when highlighted
          border: '#d4af37'      // Gold border maintained
        }
      },
      edge: {
        color: '#00331c',      // Dark racing green
        highlight: '#006633'   // Medium racing green for highlighted edges
      }
    },
    
    // Colors for radar charts
    radarColors: {
      team: {
        stroke: '#004225',     // Racing green stroke
        fill: '#00422533',     // Racing green with opacity
      },
      individual: {
        stroke: '#d4af37',     // Gold stroke
        fill: '#d4af3733',     // Gold with opacity
      }
    },
    
    // Background and UI element colors
    background: {
      main: '#f8f9f8',        // Very light green-tinted background
      card: '#ffffff',        // White for cards
      highlight: '#e9f0ea',   // Light green for highlights/hovers
    },
    
    // Text colors
    text: {
      primary: textColors.primary,
      secondary: textColors.secondary,
      tertiary: textColors.tertiary,
      disabled: textColors.disabled
    }
  };
  
  export default RacingGreenPalette;
  export { textColors };
