import React, { useState } from 'react';
import {
  Box,
  Typography,
  TableCell,
  TableRow,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import {
  Warning as WarningIcon,
  CheckCircle as CheckIcon,
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon
} from '@mui/icons-material';
import { UserSelectionInput } from '../../../components/usersearchinput/UserSelection';

const getConfidenceDisplay = (confidence, isEdited) => {
    if (!confidence) {
        return {
          label: '',
          color: '',
          bgColor: ''
        };
      }
      
  if (isEdited) {
    return {
      label: 'Manual Edit',
      color: '#1565C0',
      bgColor: '#E3F2FD'
    };
  }
  
  if (confidence < 0.9) {
    return {
      label: `${Math.round(confidence * 100)}%`,
      color: '#ED6C02',
      bgColor: '#FFF3E0'
    };
  }
  
  if (confidence >= 0.85) {
    return {
      label: `${Math.round(confidence * 100)}%`,
      color: '#2E7D32',
      bgColor: '#E8F5E9'
    };
  }
  
  return {
    label: 'Manual Match',
    color: '#757575',
    bgColor: '#F5F5F5'
  };
};

const SpeakerRow = ({ 
    match, 
    availableUsers, 
    audioSnippets, 
    onSelect, 
    onRemove, 
    editedMatches,
    currentPlayingId,
    onPlayAudio
}) => {
    const [guestNameDialogOpen, setGuestNameDialogOpen] = useState(false);
    const [guestName, setGuestName] = useState('Guest');
    
    const confidenceDisplay = getConfidenceDisplay(match.confidence, match.isEdited);
    
    const selectedUser = (match.userId || match.userName === 'Guest' || match.isGuest) ? {
        id: match.userId || 'guest',
        name: match.userName || 'Guest',
        email: match.email || '',
        isGuest: match.userName === 'Guest' || match.isGuest,
        confidence: match.confidence
    } : null;

    const mappedAvailableUsers = availableUsers.map(user => ({
        ...user,
        email: user.email || '',
        name: user.name || user.userName || ''
    }));

    const snippets = audioSnippets?.[match.speakerId] || [];
    const hasAudioSnippets = snippets.length > 0;

    const handlePlayPause = (snippetIndex) => {
        const snippetId = `${match.speakerId}-${snippetIndex}`;
        if (currentPlayingId === snippetId) {
            onPlayAudio(null);
        } else {
            const audioData = snippets[snippetIndex];
            onPlayAudio(snippetId, audioData);
        }
    };

    const handleUserSelect = (role, user) => {
        if (user.isGuest) {
            setGuestName('Guest');
            setGuestNameDialogOpen(true);
        } else {
            onSelect(role, user);
        }
    };

    const handleGuestNameConfirm = () => {
        setGuestNameDialogOpen(false);
        onSelect(match.speakerId, {
            id: 'guest-' + Date.now(),
            name: guestName || 'Guest',
            email: '',
            isGuest: true
        });
    };
    return (
        <>
            <TableRow sx={{ height: '72px' }}>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Typography sx={{ fontWeight: 500, minWidth: '80px' }}>
                            Speaker {match.speakerId}
                        </Typography>
                    </Box>
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        height: '100%'
                    }}>
                        <UserSelectionInput
                            role={match.speakerId}
                            label=""
                            availableUsers={[
                                { id: 'guest', name: 'Add as Guest...', isGuest: true },
                                ...mappedAvailableUsers
                            ]}
                            selectedUser={selectedUser}
                            onSelect={handleUserSelect}
                            onRemove={(_, userId) => onRemove(match.speakerId)}
                            placeholder="Select a team member"
                            width={300}
                            sx={{ 
                                width: '300px',
                                '& .MuiInputBase-root': {
                                    height: '32px',
                                    maxWidth: '300px',
                                    padding: 0
                                },
                                '& .MuiAutocomplete-input': {
                                    padding: '0 4px !important',
                                },
                                '& .MuiAutocomplete-popper': {
                                    width: '300px !important'
                                }
                            }}
                        />
                        {!match.isEdited && selectedUser && match.confidence >= 0.85 && (
                            <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                (Auto-matched)
                            </Typography>
                        )}
                        {editedMatches.some(m => 
                            m.speakerId !== match.speakerId && 
                            m.userId === match.userId && 
                            match.userId
                        ) && (
                            <Typography 
                                variant="caption" 
                                sx={{ 
                                    ml: 1,
                                    color: 'warning.main',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <WarningIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                Also assigned elsewhere
                            </Typography>
                        )}
                    </Box>
                </TableCell>
                <TableCell>
                    <Box
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            px: 1.5,
                            py: 0.5,
                            borderRadius: 1,
                            backgroundColor: confidenceDisplay.bgColor,
                            color: confidenceDisplay.color
                        }}
                    >
                        {match.isEdited ? (
                            <>
                                <CheckIcon sx={{ fontSize: 16, mr: 0.5 }} />
                                Manual Edit
                            </>
                        ) : (
                            <>
                                {confidenceDisplay.label}
                            </>
                        )}
                    </Box>
                </TableCell>
                <TableCell>
                    {hasAudioSnippets && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            {snippets.map((snippet, snippetIndex) => {
                                const snippetId = `${match.speakerId}-${snippetIndex}`;
                                const isCurrentlyPlaying = currentPlayingId === snippetId;
                                
                                return (
                                    <IconButton
                                        key={snippetIndex}
                                        onClick={() => handlePlayPause(snippetIndex)}
                                        size="small"
                                        sx={{ 
                                            bgcolor: 'grey.50',
                                            borderRadius: 1,
                                            border: '1px solid',
                                            borderColor: 'divider',
                                        }}
                                    >
                                        {isCurrentlyPlaying ? (
                                            <PauseIcon fontSize="small" />
                                        ) : (
                                            <PlayArrowIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                );
                            })}
                        </Box>
                    )}
                </TableCell>
            </TableRow>

            {/* Guest Name Dialog */}
            <Dialog open={guestNameDialogOpen} onClose={() => setGuestNameDialogOpen(false)}>
                <DialogTitle>Guest Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="guestName"
                        label="Enter guest name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setGuestNameDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleGuestNameConfirm}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SpeakerRow;
