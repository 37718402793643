// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004225',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", -apple-system, BlinkMacSystemFont, sans-serif',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 500,
          minWidth: 100,
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(to bottom, #F0F5F0, #E5EFE5)',
          minHeight: '100vh',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          color: '#212121',
        },
      },
    },
  }

});
export default theme;
