import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  IconButton,
  Button,
  Tooltip,
  Chip,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';

// Icons
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import SpeakerMatchingDialog from './meetingoverview/components/SpeakerMatchingDialog';

import axios from 'axios';
import config from '../config';
import TranscriptEditor from './meetings/transcript/TranscriptEditor';
import { RefreshProvider } from './../auth/RefreshProvider';
import TeamFilterDropdown from './../components/TeamFilterDropdown';
import { useQuery } from 'react-query';
import TranscriptViewer from './meetingoverview/components/TranscriptViewer';
import UsersFilterDropdown from './../components/UsersFilterDropdown';
import RacingGreenPalette from './../RacingGreenPalette';
import { useTheme } from '@mui/material/styles';
// import { textColors } from './../RacingGreenPalette';
/**
 * Meetings (Refactored)
 * - Redesigned with consistent Racing Green theme
 * - Improved card-based layout
 * - Enhanced visualizations and status indicators
 */

// Simple helper function for formatting duration
const formatDuration = (durationMs) => {
  if (!durationMs) return '00:00';
  
  const totalSeconds = Math.floor(durationMs / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  
  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const Meetings = () => {
  // --------------------
  // State
  // --------------------
  const [page, setPage] = useState(1);
  const [teamFilter, setTeamFilter] = useState("00000000-0000-0000-0000-000000000000");
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [userFilter, setUserFilter] = useState('me');
  const [coachees, setCoachees] = useState([]);
  const [isSpeakerMatchingOpen, setIsSpeakerMatchingOpen] = useState(false);
  const [teams, setTeams] = useState([]);

  const navigate = useNavigate();

  const theme = useTheme();
  const textColors = theme.palette.text;

  // --------------------
  // Data Fetch (React Query)
  // --------------------
  const {
    data: pageData = {},
    isLoading: meetingsLoading,
    error: meetingsError,
    refetch,
  } = useQuery(['meetings', page, teamFilter, userFilter], async () => {
    const params = { 
      page,
      teamId: teamFilter,
      userId: userFilter !== 'me' ? userFilter : undefined
    };
          
    const response = await axios.get(`${config.backendUrlApiV1}/meeting/list_meetings/`, {
      params,
    });
  
    
    // Just return the data as is, without transforming it
    return {
      meetings: response.data.data?.meetings || [],
      currentPage: response.data.data?.currentPage || 1,
      totalPages: response.data.data?.totalPages || 1,
      totalRecords: response.data.data?.totalRecords || 0,
    };
  }, {
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // Auto-display the first ready meeting when data is loaded
      if (data?.meetings?.length > 0 && !selectedMeeting) {
        const firstReadyMeeting = data.meetings.find(meeting => meeting.ready);
        if (firstReadyMeeting) {
          handleMeetingClick(firstReadyMeeting);
        }
      }
    }
  });

  // Fetch coachees on mount
  useEffect(() => {
    const fetchCoachees = async () => {
      try {
        const response = await axios.get(`${config.backendUrlApiV1}/coach/list_coachees/`);
        setCoachees(response.data.coachees || []);
      } catch (error) {
        console.error('Error fetching coachees:', error);
        setCoachees([]);
      }
    };
    
    fetchCoachees();
  }, []);

  // Fetch teams on mount - update to use the correct API endpoint
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get(`${config.backendUrlApiV1}/team/`);        
        const teamsData = response.data.results || response.data || [];
        
        // Ensure teams have the expected format (id and name properties)
        const formattedTeams = teamsData.map(team => ({
          id: team.id || team.team_id,
          name: team.name || team.team_name,
        }));
        
        setTeams(formattedTeams);
      } catch (error) {
        console.error('Error fetching teams:', error);
        
        // Fallback: Create a default team if API fails
        // This ensures the dialog will still work
        setTeams([{
          id: "default",
          name: "Default Team"
        }]);
      }
    };
    
    fetchTeams();
  }, []);

  const {
    currentPage = 1,
    totalPages = 1,
    meetings = [],
    totalRecords = 0
  } = pageData;

  // --------------------
  // Meeting Details
  // --------------------
  const fetchMeetingDetails = useCallback(async (meetingId) => {
    if (!meetingId) return;
    setDetailsLoading(true);

    try {
      const cached = sessionStorage.getItem(`meeting_details_${meetingId}`);
      if (cached) {
        const data = JSON.parse(cached);
        setDetails({
          summary: data.summary,
          transcript: data.transcript,
          metaData: data.metaData,
        });
        setDetailsLoading(false);
        return;
      }

      const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-metrics/`, {
        params: {
          selectedMeetingId: meetingId,
          getTranscript: true,
          getSummary: true,
        },
      });

      const data = response.data;
      sessionStorage.setItem(`meeting_details_${meetingId}`, JSON.stringify(data));
      setDetails({
        summary: data.summary,
        transcript: data.transcript,
        metaData: data.metaData,
      });
    } catch (err) {
      console.error('Error fetching meeting details:', err);
    } finally {
      setDetailsLoading(false);
    }
  }, []);

  const handleMeetingClick = (meeting) => {
    // If a meeting isn't "ready," we can skip opening the drawer
    if (!meeting.ready) return;
    
    
    setSelectedMeeting(meeting);
    setDrawerOpen(true);
    fetchMeetingDetails(meeting.id);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedMeeting(null);
    setDetails(null);
  };

  const handleSpeakerMatchingComplete = useCallback(() => {
    if (!selectedMeeting?.id) return;
    
    // Clear the cached meeting details
    sessionStorage.removeItem(`meeting_details_${selectedMeeting.id}`);
    
    // Fetch updated meeting details
    fetchMeetingDetails(selectedMeeting.id);
    
    // Update the selected meeting's speaker matching status
    setSelectedMeeting(prev => ({
      ...prev,
      isSpeakerMatched: true
    }));
    
    // Refresh the meetings list by refetching the current page
    refetch();
    
  }, [selectedMeeting, fetchMeetingDetails, refetch]);

  const handleSpeakerMatchingOpen = () => setIsSpeakerMatchingOpen(true);
  const handleSpeakerMatchingClose = () => setIsSpeakerMatchingOpen(false);

  // --------------------
  // Editor
  // --------------------
  const handleEditorClose = () => setIsEditorOpen(false);
  const handleEditorSave = (updatedDetails) => {
    if (selectedMeeting?.id) {
      setSelectedMeeting(prev => ({ ...prev, ...updatedDetails }));
      setIsEditorOpen(false);
    }
  };

  // --------------------
  // Pagination
  // --------------------
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // --------------------
  // Components
  // --------------------
  const MeetingCard = ({ meeting, onClick, isDisabled, isSelected }) => {
    // Determine meeting status
    const getMeetingStatus = () => {
      if (!meeting.ready) {
        return {
          label: "Processing",
          icon: <HourglassEmptyIcon />,
          className: "processing",
          color: "#b8860b",
          bgColor: "rgba(184, 134, 11, 0.1)"
        };
      } else if (meeting.isSpeakerMatched === false) {
        return {
          label: "Match Speakers",
          icon: <PlaylistAddCheckIcon />,
          className: "needs-matching",
          color: "#d32f2f",
          bgColor: "rgba(211, 47, 47, 0.1)"
        };
      } else {
        return {
          label: "Ready",
          icon: <CheckCircleIcon />,
          className: "ready",
          color: "#004225",
          bgColor: "rgba(0, 66, 37, 0.1)"
        };
      }
    };

    const status = getMeetingStatus();

    // Make sure we have a team name to display
    const teamName = meeting.teamName || 'No Team';

    return (
      <Paper
        elevation={isSelected ? 3 : 1}
        className={`meeting-card ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`}
        onClick={() => !isDisabled && onClick(meeting)}
        sx={{
          borderLeft: isSelected ? '2px solid #004225' : '4px solid transparent',
          backgroundColor: isSelected ? 'rgba(0, 66, 37, 0.03)' : 'white',
          transition: 'all 0.2s ease-in-out',
          mb: 0,
          p: 1.5,
          borderRadius: 1,
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          position: 'relative',
          '&:hover': !isDisabled ? {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 8px rgba(0, 66, 37, 0.15)',
            backgroundColor: 'rgba(0, 66, 37, 0.03)',
            '&::after': {
            }
          } : {}
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" className="meeting-title" sx={{ 
              fontWeight: 600,
              color: textColors.primary,
              fontFamily: "'Montserrat', 'Roboto', sans-serif",
              fontSize: '0.875rem',
              mb: 0.5
            }}>
              {meeting.name || 'Untitled Meeting'}
            </Typography>
                  
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 0.5, 
              mt: 0.5,
              color: textColors.secondary
            }}>
              <GroupIcon sx={{ fontSize: 14 }} />
              <Typography variant="caption" sx={{ color: textColors.secondary }}>
                {teamName}
              </Typography>
            </Box>
          </Box>
          
          <Box>
            <Chip
              icon={status.icon}
              label={status.label}
              size="small"
              className={`status-chip ${status.className}`}
              sx={{ 
                backgroundColor: status.bgColor,
                color: status.color,
                '& .MuiChip-icon': {
                  color: 'inherit'
                },
                height: '22px',
                '& .MuiChip-label': {
                  fontSize: '0.65rem',
                  px: 1
                }
              }}
            />
          </Box>
        </Box>
        
        <Box className="meeting-details" sx={{ 
          display: 'flex', 
          flexWrap: 'wrap',
          gap: 1.5,
          mt: 1,
          alignItems: 'center'
        }}>
          <Box className="meeting-date" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <EventIcon sx={{ fontSize: 14 }} />
            <Typography variant="caption" sx={{ color: textColors.tertiary, fontSize: '0.7rem' }}>
              {meeting.date || 'No date'}
            </Typography>
          </Box>
          
          {meeting.time && (
            <Box className="meeting-time" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <AccessTimeIcon sx={{ fontSize: 14 }} />
              <Typography variant="caption" sx={{ color: textColors.tertiary, fontSize: '0.7rem' }}>
                {meeting.time}
              </Typography>
            </Box>
          )}
          
          {/* Only show duration if available */}
          {meeting.durationMs && (
            <Box className="meeting-duration" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <HourglassEmptyIcon sx={{ fontSize: 14 }} />
              <Typography variant="caption" sx={{ color: textColors.tertiary, fontSize: '0.7rem' }}>
                {formatDuration(meeting.durationMs)}
              </Typography>
            </Box>
          )}
        </Box>
        
        {isDisabled && (
          <Box 
            className="processing-indicator" 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mt: 1,
              backgroundColor: 'rgba(184, 134, 11, 0.05)',
              p: 0.75,
              borderRadius: 1
            }}
          >
            <CircularProgress size={12} sx={{ color: '#b8860b' }} />
            <Typography variant="caption" sx={{ color: '#b8860b', fontSize: '0.7rem' }}>
              Processing meeting data...
            </Typography>
          </Box>
        )}
      </Paper>
    );
  };

  const PaginationControls = () => (
    <Paper 
      elevation={1} 
      className="pagination-container"
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        mt: 2,
        borderRadius: 1
      }}
    >
      <Typography variant="body2" className="pagination-info" sx={{ color: textColors.secondary }}>
        {meetings.length} of {totalRecords}
      </Typography>
      
      <Box className="pagination-controls" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconButton
          className="pagination-button"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          size="small"
          sx={{ 
            border: '1px solid rgba(0, 66, 37, 0.2)',
            color: '#004225',
            '&:hover': {
              backgroundColor: 'rgba(0, 66, 37, 0.05)'
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 66, 37, 0.3)'
            }
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        
        <Typography variant="body2" className="pagination-page" sx={{ px: 2, color: '#004225' }}>
          Page {currentPage} of {totalPages}
        </Typography>
        
        <IconButton
          className="pagination-button"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          size="small"
          sx={{ 
            border: '1px solid rgba(0, 66, 37, 0.2)',
            color: '#004225',
            '&:hover': {
              backgroundColor: 'rgba(0, 66, 37, 0.05)'
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 66, 37, 0.3)'
            }
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Paper>
  );

  const MeetingHeader = ({ meeting, onClose, onViewDetails, onSpeakerMatching }) => {
    if (!meeting) return null;
    
    // Determine if we should show speaker matching as urgent (red)
    const needsSpeakerMatching = meeting.ready && meeting.isSpeakerMatched === false;
    
    // Theme colors that match the sidebar but with standard text colors
    const themeColors = {
      primary: textColors.primary,        // Use standardized text color
      secondary: textColors.secondary,    // Use standardized text color
      background: '#E5EFE5',              // Light green background (unchanged)
      hover: 'rgba(0, 0, 0, 0.08)',       // Hover state (unchanged)
      active: 'rgba(0, 0, 0, 0.12)',      // Active state (unchanged)
      urgent: 'rgba(211, 47, 47, 0.8)',   // Softer red for urgent actions (unchanged)
      urgentHover: 'rgba(211, 47, 47, 0.9)' // Slightly darker red for hover state (unchanged)
    };
    
    // Get team name from meeting data
    const teamName =  meeting.teamName || 'No Team';
    
    return (
      <Box 
        className="details-header"
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          p: 2,
          borderBottom: '1px solid #e0e0e0',
          borderRadius: '0px',
          gap: 2
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography 
              variant="h6" 
              className="details-title"
              sx={{ 
                fontWeight: 600,
                color: themeColors.primary,
                fontFamily: "'Montserrat', 'Roboto', sans-serif"
              }}
            >
              {meeting.name || 'Untitled Meeting'}
            </Typography>
            
            <Typography 
              variant="body2" 
              sx={{ 
                color: themeColors.secondary,
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <GroupIcon sx={{ fontSize: 16 }} />
              {teamName}
            </Typography>
            
            <Typography 
              variant="body2" 
              sx={{ 
                color: themeColors.secondary,
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 0.5
              }}
            >
              <EventIcon sx={{ fontSize: 16 }} />
              {meeting.date || 'No Date'} {meeting.time ? `at ${meeting.time}` : ''}
            </Typography>
          </Box>
          
          <IconButton 
            size="small" 
            onClick={onClose}
            sx={{ 
              color: themeColors.secondary,
              border: `1px solid ${themeColors.hover}`,
              '&:hover': {
                backgroundColor: themeColors.hover
              }
            }}
            aria-label="Close panel"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <Button
            variant="contained"
            startIcon={<PlaylistAddCheckIcon />}
            onClick={onSpeakerMatching}
            sx={{ 
              backgroundColor: needsSpeakerMatching ? themeColors.urgent : themeColors.background,
              color: needsSpeakerMatching ? 'white' : 'black',
              border: needsSpeakerMatching ? 'none' : `1px solid ${themeColors.hover}`,
              '&:hover': {
                backgroundColor: needsSpeakerMatching ? themeColors.urgentHover : themeColors.hover,
              },
              fontWeight: 500,
              textTransform: 'none',
              flex: { xs: '1 0 auto', sm: '0 1 auto' }
            }}
            size="medium"
          >
            Match Speakers
          </Button>
          
          <Button
            variant="contained"
            startIcon={<OpenInNewIcon />}
            onClick={() => onViewDetails(meeting.id)}
            sx={{ 
              backgroundColor: themeColors.background,
              color: 'black',
              border: `1px solid ${themeColors.hover}`,
              '&:hover': {
                backgroundColor: themeColors.hover,
              },
              fontWeight: 500,
              textTransform: 'none',
              flex: { xs: '1 0 auto', sm: '0 1 auto' }
            }}
            size="medium"
          >
            View Full Insights
          </Button>
        </Box>
        
        {needsSpeakerMatching && (
          <Box 
            sx={{ 
              backgroundColor: 'rgba(211, 47, 47, 0.08)', 
              p: 1.5, 
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <PlaylistAddCheckIcon fontSize="small" sx={{ color: themeColors.urgent }} />
            <Typography variant="body2" sx={{ color: themeColors.urgent }}>
              Speaker matching required: Identify who said what to unlock full meeting insights.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  // --------------------
  // Render methods
  // --------------------
  const renderMeetingsList = () => {
    if (meetingsLoading) {
      return (
        <Box className="loading-container" sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          gap: 2
        }}>
          <CircularProgress sx={{ color: '#004225' }} />
          <Typography variant="body2" className="loading-text" sx={{ color: '#426853' }}>
            Loading meetings...
          </Typography>
        </Box>
      );
    }
    
    if (meetingsError) {
      return (
        <Box className="empty-state" sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          textAlign: 'center'
        }}>
          <Typography variant="body1" className="empty-text" color="error">
            Error loading meetings: {String(meetingsError)}
          </Typography>
        </Box>
      );
    }
    
    if (!meetings.length) {
      return (
        <Box className="empty-state" sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          textAlign: 'center',
          p: 3
        }}>
          <VideocamOffIcon className="empty-icon" sx={{ fontSize: 48, color: 'rgba(0, 66, 37, 0.2)', mb: 2 }} />
          <Typography variant="body1" className="empty-text" sx={{ color: '#426853', maxWidth: '300px' }}>
            No meetings found. Try adjusting your filters or check back later.
          </Typography>
        </Box>
      );
    }
    
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {meetings.map(meeting => (
          <MeetingCard
            key={meeting.id}
            meeting={meeting}
            onClick={handleMeetingClick}
            isDisabled={!meeting.ready}
            isSelected={selectedMeeting?.id === meeting.id}
          />
        ))}
      </Box>
    );
  };

  // --------------------
  // Main Layout Components
  // --------------------
  const renderFilters = () => (
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      {/* Team Filter */}
      <TeamFilterDropdown
        onFilterChange={(value) => {
          setTeamFilter(value);
          setPage(1);
        }}
        currentFilter={teamFilter}
        sx={{ 
          minWidth: '200px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 66, 37, 0.2)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 66, 37, 0.5)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#004225',
            },
          },
          '& .MuiSelect-select': {
            color: '#004225',
            fontFamily: "'Montserrat', 'Roboto', sans-serif",
          },
        }}
      />
      
      {/* {coachees?.length > 0 && (
        <UsersFilterDropdown
          onFilterChange={(value) => {
            setUserFilter(value);
            setPage(1);
          }}
          currentFilter={userFilter}
          sx={{ 
            minWidth: '200px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 66, 37, 0.2)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(0, 66, 37, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#004225',
              },
            },
            '& .MuiSelect-select': {
              color: '#004225',
              fontFamily: "'Montserrat', 'Roboto', sans-serif",
            },
          }
        }
        />
      )} */}
    </Box>
  );

  // Token validation (optional)
  useEffect(() => {
    const validateToken = async () => {
      try {
        // For example, a quick request to see if token is still valid:
        // await axios.get(`${config.backendUrlApiV1}/some-endpoint`);
      } catch (error) {
        if (error.response?.status === 401) {
          // Token is invalid, redirect to login
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          navigate('/sign-in');
        }
      }
    };

    // Run validation check every 5 minutes
    const tokenCheckInterval = setInterval(validateToken, 5 * 60 * 1000);
    validateToken();
    return () => clearInterval(tokenCheckInterval);
  }, [navigate]);


  return (
    <Box 
      className="tab-curved-view" 
      sx={{ 
        // p: 3, // Removed
      }}
    >
      <Box 
        className="tab-header"
        sx={{ 
          // No sx needed here if class handles border and structure
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            variant="h5" 
            sx={{ 
              color: RacingGreenPalette.primary.main,
              fontWeight: 600,
              py: 2, // Controls space above title and between title & divider
              px: 3, // Controls horizontal space for title
            }}
          >
            Meetings
          </Typography>
        </Box>
      </Box>
      
      <Box 
        className="tab-panel"
        sx={{ 
          px: 3, // Add horizontal padding for panel content
          py: 4, // Add vertical padding for panel content
        }}
      >
        <Box className="meetings-controls" sx={{ mb: 3 }}>
          {renderFilters()}
        </Box>

        <Box sx={{
          display: 'flex', 
          flexDirection: { xs: 'column', lg: 'row' },
          gap: 1,
          alignItems: 'stretch', 
        }}>
          <Box 
            sx={{ 
              width: { xs: '100%', lg: '400px' },
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              p: 1,
              borderRadius: 1,
              border: '1px solid #e0e0e0',
              overflow: 'hidden',
              backgroundColor: 'white',
            }}
          >
            <Box sx={{ flex: 1, overflow: 'auto', px: 1 }}>
              {renderMeetingsList()}
            </Box>
              
            {meetings.length > 0 && <PaginationControls />}
          </Box>
          
          {drawerOpen && (
            <Box 
              sx={{ 
                flex: 1,
                display: { xs: 'none', lg: 'flex' },
                flexDirection: 'column',
                position: 'sticky', 
                top: 80,           
                borderRadius: 1,
                border: '1px solid #e0e0e0',
                overflow: 'hidden',
                backgroundColor: 'white',
              }}
            >
              <MeetingHeader
                meeting={selectedMeeting}
                onClose={handleDrawerClose}
                onViewDetails={(meetingId) => window.open(`/product/meeting/${meetingId}`, '_blank')}
                onSpeakerMatching={handleSpeakerMatchingOpen}
              />
              
              <Box sx={{ flex: 1, overflow: 'hidden' }}>
                <TranscriptViewer
                  details={details}
                  detailsLoading={detailsLoading}
                  onSpeakerMatchingComplete={handleSpeakerMatchingComplete}
                  removeDividers={true}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      
      {details && (
        <RefreshProvider>
          <TranscriptEditor
            isOpen={isEditorOpen}
            onClose={handleEditorClose}
            onSave={handleEditorSave}
            transcript={{
              metaData: {
                transcriptId: details.metaData?.transcriptId,
                meetingId: selectedMeeting?.id,
                ...details.metaData,
              },
              ...details.transcript,
            }}
          />
        </RefreshProvider>
      )}
      
      {selectedMeeting && details?.metaData?.transcriptId && (
        <SpeakerMatchingDialog
          open={isSpeakerMatchingOpen}
          onClose={handleSpeakerMatchingClose}
          transcriptId={details.metaData.transcriptId}
          meetingId={selectedMeeting.id}
          onMatchingComplete={handleSpeakerMatchingComplete}
          currentTeam={selectedMeeting.teamId 
            ? teams.find(t => t.id === selectedMeeting.teamId) || (teams.length > 0 ? teams[0] : null)
            : (teams.length > 0 ? teams[0] : null)
          }
        />
      )}
    </Box>
  );
};

export default Meetings;
