import React from 'react';
import { Tooltip, Chip, Avatar } from '@mui/material';

const UserChip = ({ user, onDelete }) => {
    const displayName = user.name || 'Unknown';
    // const displayName = isGuest ? `${user.name} (Guest)` : (user.name || user.email || 'Unknown');

    return (
        <Tooltip title={displayName} arrow>
        {/* <Tooltip title={isGuest ? 'Guest Speaker' : user.email} arrow> */}
            <Chip
                avatar={
                    <Avatar sx={{ width: 24, height: 24, fontSize: '0.8rem' }}>
                            {(user.name || user.email).charAt(0).toUpperCase()}
                    </Avatar>
                }
                //     isGuest ? (
                //         <Avatar sx={{ bgcolor: 'grey.100', width: 24, height: 24 }}>
                //             <PersonIcon color="action" sx={{ fontSize: 16 }} />
                //         </Avatar>
                //     ) : (
                //         <Avatar sx={{ width: 24, height: 24, fontSize: '0.8rem' }}>
                //             {(user.name || user.email).charAt(0).toUpperCase()}
                //         </Avatar>
                //     )
                // }
                label={displayName}
                onDelete={onDelete}
                className="member-chip"
                // variant={isGuest ? "outlined" : "filled"}
                // color={isGuest ? "default" : "primary"}
                sx={{
                    height: '28px',
                    '& .MuiChip-label': {
                        fontSize: '0.8rem',
                        padding: '0 8px'
                    },
                    '& .MuiChip-deleteIcon': {
                        fontSize: '16px',
                        margin: '0 4px'
                    }
                }}
            />
        </Tooltip>
    );
};

export default UserChip;
