import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Box,
  Grid,
  Container,
  TextField,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
  Card,
  CardContent,
  Paper
} from '@mui/material';
import './LandingPage.css';
import config from "../config";
import { 
  ArrowForward, 
  LinkedIn, 
  Psychology, 
  Analytics,
  KeyboardArrowDown,
  WorkspacePremium
} from '@mui/icons-material';
import logo from './../images/logos/elevaide-logo-white.png';
import RotatingText from './RotatingText';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // For scroll animations
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Words for rotating text animation
  const rotatingWords = [
    "Communication",
    "Collaboration",
    "Connections",
    "Leadership",
    "Performance",
    "Effectiveness",
    "Teamwork",
    "Productivity"
  ];

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleJoinClick = () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    setError('Processing your request...');

    axios.post(`${config.backendUrl}/waiting-list/`, { email, reason })
      .then(response => {
        setIsLoading(false);
        setError('');
        setIsAdded(true);
      })
      .catch(error => {
        setIsLoading(false);
        setError('Error adding to the waiting list. Please try again.');
      });
  };

  // The scroll indicator animation
  const handleScrollDown = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box className="main-container landing-page" role="main" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Simplified background shapes */}
      <div className="gradient-shape-primary"></div>
      <div className="gradient-shape-secondary"></div>
      <div className="gradient-shape-accent"></div>

      {/* Simplified floating elements */}
      <div className="floating-element floating-element-1"></div>
      <div className="floating-element floating-element-2"></div>
      <div className="floating-element floating-element-3"></div>
      
      {/* Logo in the top left corner */}
      <Box 
        sx={{ 
          position: 'fixed', 
          top: 16, 
          left: 24, 
          zIndex: 10,
          display: 'flex', 
          alignItems: 'center', 
          gap: 1 
        }}
      >
        <div className="logo-container">
          <img 
            src={logo} 
            alt="Elevaide Logo" 
            className="logo-image"
          />
        </div>
      </Box>

      <Box component="main" sx={{ flex: 1 }}>
        {/* Enhanced Hero Section with Rotating Text */}
        <Box component="section" sx={{ 
          pt: { xs: 10, md: 14, lg: 16 }, // Increased top padding to account for removed header
          pb: { xs: 6, md: 10, lg: 12 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
        }}>
          <Container maxWidth="lg">
            <Box sx={{ maxWidth: 900, mx: 'auto', mb: 2, position: 'relative', zIndex: 1 }}>
              <Typography 
                variant="h2" 
                component="h1" 
                className="hero-title"
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '3.75rem', lg: '4.5rem' },
                  lineHeight: 1.1,
                  mb: 2,
                  textShadow: '0 2px 10px rgba(0,0,0,0.05)'
                }}
              >
                Elevate Your<br />
                Executive Team's<br />
                <RotatingText 
                  words={rotatingWords} 
                  typingSpeed={100} 
                  deletingSpeed={50} 
                  pauseDuration={2000} 
                />
              </Typography>
              <Typography variant="body1" sx={{ 
                color: 'text.secondary',
                fontSize: { md: '1.25rem' },
                maxWidth: 700,
                mx: 'auto',
                mb: 10
              }}>
                We unlock deeper human connections in the workplace.  
                We help teams, individuals and organizations to grow and thrive.
              </Typography>
            </Box>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              justifyContent: 'center',
              mt: 4,
              mb: 4
            }}>
              <Button 
                variant="contained" 
                size="large"
                endIcon={<LinkedIn />}
                className="linkedin-button"
                href="https://www.linkedin.com/company/elevaide/"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  px: 3, 
                  py: 1.5,
                  borderRadius: '28px !important',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  zIndex: 2,
                  background: 'linear-gradient(90deg, #00753a, #20c17a) !important',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #005f2e, #1aaa6a) !important',
                    boxShadow: '0 6px 25px rgba(0, 117, 58, 0.6) !important',
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                Connect With Us
              </Button>
              
            </Box>
            
            {/* Scroll down indicator */}
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                mt: 3,
                cursor: 'pointer',
                opacity: 0.8,
                transition: 'all 0.3s ease',
                '&:hover': {
                  opacity: 1,
                  transform: 'translateY(5px)'
                }
              }}
              onClick={handleScrollDown}
            >
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                Discover How
              </Typography>
              <KeyboardArrowDown 
                sx={{ 
                  color: 'primary.main',
                  animation: 'bounce 2s infinite'
                }} 
              />
            </Box>
          </Container>
        </Box>
        
        {/* Features Section with glass-morphism cards */}
        <Box component="section" id="features" sx={{ 
          pt: { xs: 11, md: 11 },
          pb: { xs: 8, md: 12 },
          position: 'relative'
        }}>
          <Container maxWidth="lg">
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <Typography 
                variant="h3" 
                component="h2" 
                sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                  mb: 2
                }}
              >
                Why Choose <span 
                  style={{
                    background: 'linear-gradient(90deg, #00753a, #20c17a)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    color: 'transparent'
                  }}
                >
                  Elevaide
                </span>
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'text.secondary',
                  fontSize: { md: '1.25rem' },
                  maxWidth: 700,
                  mx: 'auto'
                }}
              >
                Our platform helps teams identify communication patterns, 
                improve collaboration, and enhance team dynamics.
              </Typography>
            </Box>
            
            <Grid container spacing={4}>
              {/* Feature Card 1 */}
              <Grid item xs={12} md={4}>
                <Paper className="feature-card" elevation={0}>
                  <Box className="feature-icon-wrapper">
                    <Analytics className="feature-icon" />
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    AI-Powered Insights
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Our advanced AI analyzes communication patterns in real-time, 
                    providing actionable insights that lead to improved team dynamics.
                  </Typography>
                </Paper>
              </Grid>
              
              {/* Feature Card 2 */}
              <Grid item xs={12} md={4}>
                <Paper className="feature-card" elevation={0}>
                  <Box className="feature-icon-wrapper">
                    <Psychology className="feature-icon" />
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Human-Centered Approach
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    We believe in the power of human connection. Our tools are designed 
                    to enhance, not replace, the human elements that make teams successful.
                  </Typography>
                </Paper>
              </Grid>
              
              {/* Feature Card 3 */}
              <Grid item xs={12} md={4}>
                <Paper className="feature-card" elevation={0}>
                  <Box className="feature-icon-wrapper">
                    <WorkspacePremium className="feature-icon" />
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Built with Experts
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Our approach is backed by top executive coaches helping real teams.  
                    Elevaide provides actionable insights that drive real change.
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
        
        {/* CTA Section with glass effect */}
        <Box component="section" id="waitlist-section" sx={{ 
          py: { xs: 8, md: 12 },
          position: 'relative'
        }}>
          <Container maxWidth="md">
            <Paper 
              elevation={0} 
              sx={{ 
                p: { xs: 4, md: 6 },
                borderRadius: '16px',
                background: 'rgba(0, 50, 30, 0.85) !important',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(0, 77, 64, 0.1)',
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                zIndex: 2
              }}
            >
              <Box sx={{ 
                maxWidth: 600, 
                mx: 'auto',
                textAlign: 'center'
              }}>
                <Typography variant="h3" component="h2" sx={{ 
                  fontWeight: 'bold',
                  fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                  mb: 2
                }}>
                  Join the Community
                </Typography>
                <Typography variant="body1" sx={{ 
                  color: 'text.secondary',
                  fontSize: { md: '1.25rem' },
                  mb: 4,
                  maxWidth: 500,
                  mx: 'auto'
                }}>
                  Stay updated on when additional pilot slots become available.
                </Typography>
                
                <Box sx={{ maxWidth: 400, mx: 'auto' }}>
                  <TextField
                    variant="outlined"
                    placeholder="Enter your work email"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ mb: 2 }}
                    InputProps={{
                      sx: {
                        borderRadius: '8px',
                        height: 48
                      },
                    }}
                  />
                  
                  <TextField
                    variant="outlined"
                    placeholder="Why does your organization need Elevaide? (optional)"
                    fullWidth
                    multiline
                    rows={3}
                    value={reason}
                    onChange={handleReasonChange}
                    sx={{ mb: 2 }}
                    InputProps={{
                      sx: {
                        borderRadius: '8px',
                      },
                    }}
                  />
                  
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={handleJoinClick}
                    disabled={isLoading}
                    endIcon={!isLoading && <ArrowForward />}
                    sx={{ height: 48, mb: 2 }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" className="loading-spinner" /> : "Join the Waitlist"}
                  </Button>
                  
                  {error && (
                    <Typography
                      variant="caption"
                      className={error.includes('Processing') ? 'loading-message' : 'error-message'}
                      sx={{ display: 'block', textAlign: 'center', mb: 2, height: '20px' }}
                    >
                      {error}
                    </Typography>
                  )}
                  
                  {isAdded && (
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ display: 'block', textAlign: 'center', mb: 2, fontWeight: 'bold' }}
                    >
                      Thank you for joining our waiting list!
                    </Typography>
                  )}
                  
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ display: 'block', textAlign: 'center' }}
                  >
                    By signing up, you agree to our{' '}
                    <MuiLink 
                      href="/privacy" 
                      component={Link} 
                      to="/privacy" 
                      className="footer-link" 
                      sx={{ textDecoration: 'none', color: 'primary.main' }}
                    >
                      Privacy Policy
                    </MuiLink>
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Container>
        </Box>
      </Box>
      
      {/* Enhanced Footer with integrated background - no divider */}
      <Box component="footer" sx={{ 
        py: { xs: 4, md: 6 },
        position: 'relative',
        zIndex: 5
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box sx={{ mb: { xs: 3, md: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <img 
                    src={logo} 
                    alt="Elevaide Logo" 
                    style={{ width: 120, height: 'auto' }}
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">
                  ©{new Date().getFullYear()} Elevaide, with ❤️ from Amsterdam
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                Company
              </Typography>
              <Box component="nav" sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <MuiLink href="/about" className="footer-link" underline="none" sx={{ 
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  width: 'fit-content'
                }}>
                  About Us
                </MuiLink>
                <MuiLink href="/contact" className="footer-link" underline="none" sx={{ 
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  width: 'fit-content'
                }}>
                  Contact
                </MuiLink>
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }}>
                Legal
              </Typography>
              <Box component="nav" sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <MuiLink href="/privacy" className="footer-link" underline="none" sx={{ 
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  width: 'fit-content'
                }}>
                  Privacy Policy
                </MuiLink>
                <MuiLink href="/terms" className="footer-link" underline="none" sx={{ 
                  fontSize: '0.875rem',
                  color: 'text.secondary',
                  width: 'fit-content'
                }}>
                  Terms of Service
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
