import React from 'react';
import {NavLink} from 'react-router-dom';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import PersonIcon from '@mui/icons-material/Person';
import EventNoteIcon from '@mui/icons-material/EventNote';
import {Box, ListItemButton, Tooltip, Avatar} from '@mui/material';
import './Sidebar.css';
import SettingsPanel from "./settings/SettingsPanel";
import Favicon from '../images/logos/favicon.png';

// Use a consistent font family
const fontFamily = 'Montserrat';

// Navigation items (without Home)
const sidebarItems = [
    {to: '/product/meetings', icon: <EventNoteIcon/>, text: 'Meetings'},
    // {to: '/product/analytics', icon: <AssessmentIcon/>, text: 'Analytics'},
    {to: '/product/company', icon: <PeopleAltIcon/>, text: 'Teams'},
    {to: '/product/upload', icon: <CloudUploadIcon/>, text: 'Upload'},
];

const Sidebar = () => {
    const [settingsPanelToggle, setSettingsPanelToggle] = React.useState(() => {
        // Initialize with a no-op function to prevent undefined errors
        return () => {};
    });
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);

    const handleSettingsClick = (event) => {
        // Make sure we have an event and a toggle function before proceeding
        if (event && settingsPanelToggle) {
            // Safely call the toggle function
            try {
                settingsPanelToggle(event);
            } catch (error) {
                console.error("Error toggling settings panel:", error);
            }
            setIsSettingsOpen(!isSettingsOpen);
        }
    };

    return (
        <>
            <Box className="styled-sidebar">
                {/* Logo at the top (unclickable) */}
                <Box className="logo-container">
                    <Avatar 
                        src={Favicon} 
                        alt="Elevaide Logo" 
                        className="elevaide-logo-image"
                        sx={{ 
                            width: 36, 
                            height: 36, 
                            borderRadius: '12px',
                            margin: '16px auto 8px auto'
                        }}
                    />
                </Box>
                
                {/* Main navigation items */}
                <List className="sidebar-list">
                    {sidebarItems.map((item, index) => (
                        <Tooltip title={item.text} placement="right" key={index}>
                            <ListItemButton 
                                component={NavLink} 
                                to={item.to} 
                                className="styled-list-item"
                                sx={{
                                    '&.active': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                    }
                                }}
                            >
                                <ListItemIcon className="styled-list-item-icon">
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.text}
                                    primaryTypographyProps={{
                                        variant: 'caption',
                                        fontFamily: fontFamily,
                                        fontSize: '0.75rem',
                                    }}
                                    className="styled-list-item-text"
                                />
                            </ListItemButton>
                        </Tooltip>
                    ))}
                </List>

                {/* Settings button at bottom */}
                <List className="sidebar-list" sx={{ mt: 'auto' }}>
                    <Tooltip title="Settings" placement="right">
                        <ListItemButton
                            onClick={handleSettingsClick}
                            className={`styled-list-item settings-item ${isSettingsOpen ? 'settings-active' : ''}`}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                textAlign: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ListItemIcon className="styled-list-item-icon">
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Settings"
                                primaryTypographyProps={{
                                    variant: 'caption',
                                    fontFamily: fontFamily,
                                    fontSize: '0.75rem',
                                }}
                                className="styled-list-item-text"
                            />
                        </ListItemButton>
                    </Tooltip>
                </List>
            </Box>
            <SettingsPanel setToggleFunction={setSettingsPanelToggle}/>
        </>
    );
};

export default Sidebar;
