import React, { useState, useEffect } from 'react';
import {
    Box,
    CircularProgress,
    Alert
} from '@mui/material';
import axios from 'axios';
import config from '../../../config';
import SpeakerMatchingDialog from './SpeakerMatchingDialog';

const SpeakerMatchingWrapper = ({ 
    transcriptId,
    meetingId, 
    onMatchingComplete 
}) => {
    const [currentTeam, setCurrentTeam] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasTranscriptId, setHasTranscriptId] = useState(false);

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                setIsLoading(true);
                setError(null);

                if (!transcriptId) {
                    setError('No transcript ID available');
                    setHasTranscriptId(false);
                    return;
                }
                setHasTranscriptId(true);

                const response = await axios.get(
                    `${config.backendUrlApiV1}/transcript/${transcriptId}/get_speakers_initial`,
                    {
                        params: { meetingId }
                    }
                );

                const { teams } = response.data;
                
                setCurrentTeam(teams.selected || null);
                
            } catch (err) {
                console.error('Error fetching team data:', err);
                let errorMessage = 'Failed to load team information';
                
                if (err.response?.data?.error) {
                    errorMessage = err.response.data.error;
                } else if (err.message) {
                    errorMessage = err.message;
                }
                
                setError(errorMessage);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTeamData();
    }, [transcriptId, meetingId]);

    if (isLoading) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%',
                p: 4
            }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 4 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <SpeakerMatchingDialog
            open={true}
            onClose={onMatchingComplete}
            transcriptId={transcriptId}
            meetingId={meetingId}
            onMatchingComplete={onMatchingComplete}
            currentTeam={currentTeam}
        />
    );
};

export default SpeakerMatchingWrapper;
