import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Chip, Popover, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TopicInfoCard from './TopicInfoCard';
import SignificantEventInfoCard from './SignificantEventInfoCard';
import { trackInteraction } from "../../../common/googleanalytics";
import RacingGreenPalette from '../../../RacingGreenPalette';
import './HeartbeatTimeline.css';

const TIMELINE_ROW_HEIGHT = 30;
const CHIP_HEIGHT = 24;

// Define speaker colors - this ensures a consistent set of distinct colors
const SPEAKER_COLOR_PALETTE = [
  RacingGreenPalette.primary.main,       // British Racing Green - #004225
  RacingGreenPalette.accent.green3,      // Light racing green - #008040
  RacingGreenPalette.secondary.main,     // Dark goldenrod - #b8860b
  RacingGreenPalette.accent.green4,      // Lighter racing green - #00994d
  RacingGreenPalette.accent.bronze,      // Bronze - #cd7f32
  RacingGreenPalette.primary.light,      // Lighter racing green - #005c33
  RacingGreenPalette.accent.silver,      // Silver - #aaa9ad
  RacingGreenPalette.secondary.light,    // Lighter gold - #d6a81d
];

const HeartbeatTimeline = ({ 
  transcriptData,
  meetingIntelligence,
  duration = 0,  // in seconds
  currentTime = 0,  // in seconds
  onTimeChange,
  speakerColors = {} // Add this prop to receive speaker colors from parent
}) => {
  const theme = useTheme();
  const [localSpeakerColors, setLocalSpeakerColors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(null);

  const open = Boolean(anchorEl);
  const { transcript } = transcriptData || {};
  const { utterances = [], speakerNames = [] } = transcript || {};

  const sortedSpeakerNames = useMemo(() =>
    [...speakerNames].sort((a, b) => a.localeCompare(b)),
  [speakerNames]);

  // Use external speaker colors if provided, otherwise generate our own
  useEffect(() => {
    if (Object.keys(speakerColors).length > 0) {
      // Use the colors provided by the parent component
      setLocalSpeakerColors(speakerColors);
    } else if (sortedSpeakerNames.length > 0) {
      // Generate our own colors if none are provided
      const newSpeakerColors = {};
      
      // Create a map of speaker names to colors
      sortedSpeakerNames.forEach((speaker, index) => {
        newSpeakerColors[speaker] = SPEAKER_COLOR_PALETTE[index % SPEAKER_COLOR_PALETTE.length];
      });
      
      setLocalSpeakerColors(newSpeakerColors);
    }
  }, [sortedSpeakerNames, speakerColors]);

  const handleTimelineClick = (event, timelineElement) => {
    const rect = timelineElement.getBoundingClientRect();
    const clickPosition = event.clientX - rect.left;
    const percentClick = (clickPosition / rect.width) * 100;
    const timeClick = (duration * percentClick) / 100;
    
    if (onTimeChange) {
      onTimeChange(timeClick);
      trackInteraction.buttonClick('timeline_speaker_navigation');
    }
  };

  const handlePopoverOpen = (event, content) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const handleJumpToTopic = (startTime) => {
    if (onTimeChange) {
      onTimeChange(startTime / 1000);
    }
  };

  const calculateItemPosition = (startTime, endTime) => {
    const durationMs = duration * 1000;
    const start = (startTime / durationMs) * 100;
    const width = Math.max(((endTime - startTime) / durationMs) * 100, 2);
    return { left: `${start}%`, width: `${width}%` };
  };

  const handleSignificantEventClick = (event, e) => {
    trackInteraction.buttonClick('timeline_significant_event');
    handlePopoverOpen(e,
      <SignificantEventInfoCard
        event={event}
        open={true}
        onClose={handlePopoverClose}
        onJumpToEvent={() => handleJumpToTopic(event.startTime)}
      />
    );
  };

  const handleTopicClick = (topic, e) => {
    trackInteraction.buttonClick('timeline_topic_card');
    handlePopoverOpen(e,
      <TopicInfoCard
        topic={topic}
        open={true}
        onClose={handlePopoverClose}
        onJumpToTopic={handleJumpToTopic}
      />
    );
  };

  if (!transcriptData || !transcript || utterances.length === 0 || duration === 0) {
    return <Typography>Loading transcript data...</Typography>;
  }

  return (
    <Box 
      className="timeline-content"
      sx={{ 
        backgroundColor: 'white',
        width: '100%',
        position: 'relative',
        padding: '12px',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      {/* Topics Timeline */}
      <Box className="timeline-section">
        <Typography variant="body2" className="timeline-section-title" sx={{ 
          fontWeight: 'bold', 
          mb: 1,
          color: RacingGreenPalette.text.secondary
        }}>
          Topics
        </Typography>
        <Box className="timeline-row" sx={{ 
          position: 'relative', 
          height: TIMELINE_ROW_HEIGHT, 
          bgcolor: RacingGreenPalette.background.highlight, 
          borderRadius: 1,
          overflow: 'hidden'
        }}>
          {meetingIntelligence?.topics?.map((topic, index) => {
            const position = calculateItemPosition(topic.startTime, topic.endTime);
            return (
              <Tooltip 
                key={index}
                title={`${formatTimeDisplay(topic.startTime)} - ${formatTimeDisplay(topic.endTime)}`}
              >
                <Chip
                  label={topic.name}
                  size="small"
                  sx={{
                    position: 'absolute',
                    left: position.left,
                    width: position.width,
                    height: CHIP_HEIGHT,
                    bgcolor: RacingGreenPalette.primary.dark,
                    color: 'white',
                    fontSize: '0.75rem',
                    border: `1px solid ${RacingGreenPalette.primary.dark}`,
                    '&:hover': {
                      bgcolor: RacingGreenPalette.primary.main,
                      transform: 'scale(1.02)',
                    },
                    transition: theme.transitions.create(['transform', 'background-color']),
                    zIndex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(e) => handleTopicClick(topic, e)}
                />
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      {/* Significant Events Timeline */}
      <Box className="timeline-section">
        <Typography variant="body2" className="timeline-section-title" sx={{ 
          fontWeight: 'bold', 
          mb: 1,
          color: RacingGreenPalette.text.secondary
        }}>
          Significant Events
        </Typography>
        <Box className="timeline-row" sx={{ 
          position: 'relative', 
          height: TIMELINE_ROW_HEIGHT, 
          bgcolor: '#f9f9f9', // Lighter background color
          borderRadius: 1,
          overflow: 'hidden'
        }}>
          {meetingIntelligence?.significantEvents?.map((event, index) => {
            const position = calculateItemPosition(event.startTime, event.endTime);
            return (
              <Tooltip 
                key={index}
                title={`${formatTimeDisplay(event.startTime)} - ${formatTimeDisplay(event.endTime)}`}
              >
                <Chip
                  label={event.eventName}
                  size="small"
                  sx={{
                    position: 'absolute',
                    left: position.left,
                    width: position.width,
                    minWidth: '75px',
                    height: CHIP_HEIGHT,
                    bgcolor: RacingGreenPalette.primary.light,
                    color: 'white',
                    fontSize: '0.75rem',
                    border: `1px solid ${RacingGreenPalette.primary.light}`,
                    '&:hover': {
                      bgcolor: RacingGreenPalette.secondary.main,
                      transform: 'scale(1.02)',
                    },
                    transition: theme.transitions.create(['transform', 'background-color']),
                    zIndex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(e) => handleSignificantEventClick(event, e)}
                />
              </Tooltip>
            );
          })}
        </Box>
      </Box>

      {/* Speaker Timelines - Show all speakers instead of limiting to 2 */}
      {sortedSpeakerNames.map((speaker) => (
        <Box key={speaker} className="timeline-section">
          <Typography variant="body2" className="speaker-label" sx={{ 
            fontWeight: 'bold', 
            mb: 1,
            color: localSpeakerColors[speaker] || RacingGreenPalette.text.secondary
          }}>
            {speaker}
          </Typography>
          <Box 
            className="timeline-row"
            sx={{ 
              position: 'relative', 
              height: TIMELINE_ROW_HEIGHT, 
              bgcolor: '#f9f9f9', // Lighter background color
              borderRadius: 1,
              cursor: 'pointer',
              overflow: 'hidden'
            }}
            onClick={(e) => handleTimelineClick(e, e.currentTarget)}
          >
            {utterances
              .filter(entry => entry.speakerName === speaker)
              .map((entry, index) => (
                <Tooltip 
                  key={index}
                  title={`${formatTimeDisplay(entry.start)} - ${formatTimeDisplay(entry.end)}`}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: `${(entry.start / (duration * 1000)) * 100}%`,
                      width: `${((entry.end - entry.start) / (duration * 1000)) * 100}%`,
                      height: '100%',
                      bgcolor: localSpeakerColors[speaker] || RacingGreenPalette.primary.main,
                      borderRadius: 1,
                      '&:hover': {
                        opacity: 0.8,
                      },
                      transition: theme.transitions.create('opacity'),
                    }}
                  />
                </Tooltip>
              ))}
          </Box>
        </Box>
      ))}

      {/* Playhead - Fixed version with proper containment */}
      <Box
        className="timeline-playhead"
        sx={{
          position: 'absolute',
          left: `${(currentTime / duration) * 100}%`,
          top: '35px', 
          bottom: '12px',
          width: '2px',
          bgcolor: RacingGreenPalette.secondary.main, // Use the standard color from palette
          zIndex: 2,
          pointerEvents: 'none',
          maxHeight: 'calc(100% - 47px)'
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {popoverContent}
      </Popover>
    </Box>
  );
};

const formatTimeDisplay = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export default HeartbeatTimeline;
