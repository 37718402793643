import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {
    Box,
    Grid,
    Chip,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Tooltip,
    IconButton,
} from '@mui/material';
import Transcription from './transcript/Transcription';
import './Indicators.css';
import config from '../../config';
import IndicatorCard from '../../components/IndicatorCard';
import {trackInteraction} from "../../common/googleanalytics";
import RacingGreenPalette from '../../RacingGreenPalette';
import { textColors } from '../../RacingGreenPalette';

// Import MUI icons
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const AnalysisSection = ({analysis, score, scoreBreakdown, scoreConfidence, improvementTips}) => (
    <Box className="analysis-container">
        <Box className="analysis-header">
            <Typography className="analysis-title" sx={{ color: textColors.primary }}>Analysis</Typography>
            <Tooltip
                title={
                    <Box className="custom-tooltip">
                        <Typography className="tooltip-title">Score Confidence</Typography>
                        <Typography className="tooltip-content">{scoreConfidence}</Typography>
                        <Divider sx={{ my: 1, backgroundColor: `rgba(0, 66, 37, 0.1)` }} />
                        <Typography className="tooltip-title">Score Breakdown</Typography>
                        <Typography className="tooltip-content">{scoreBreakdown}</Typography>
                    </Box>
                }
                placement="top"
                arrow
            >
                <Box className="analysis-score">
                    <Typography className="score-value" sx={{ color: textColors.primary }}>
                        Score: {score} / 100
                    </Typography>
                    <InfoIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main, opacity: 0.7 }} />
                </Box>
            </Tooltip>
        </Box>
        <Box className="analysis-content">
            <Typography variant="body1" paragraph sx={{ color: textColors.secondary }}>{analysis}</Typography>
            {improvementTips && (
                <Box className="improvement-container">
                    <Typography className="improvement-title" sx={{ color: textColors.primary }}>
                        <TipsAndUpdatesIcon fontSize="small" sx={{ mr: 1, verticalAlign: 'text-bottom' }} />
                        Improvement Tips
                    </Typography>
                    <Typography variant="body2" sx={{ color: textColors.secondary }}>{improvementTips}</Typography>
                </Box>
            )}
        </Box>
    </Box>
);

const StatementBox = ({statement, onClick}) => (
    <Box
        className="statement-box"
        onClick={() => onClick(statement)}
    >
        <Typography className="statement">{statement}</Typography>
    </Box>
);

const Indicators = ({indicatorsData, transcript}) => {
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [view, setView] = useState('all');
    const containerRef = useRef(null);
    const [indicatorCards, setIndicatorCards] = useState({});

    const transcriptionRef = useRef(null);
    const [transcriptData, setTranscriptData] = useState(transcript);

    useEffect(() => {
        fetchIndicatorCards();
    }, []);

    const fetchIndicatorCards = async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/indicator-cards`);
            setIndicatorCards(response.data);
        } catch (error) {
            console.error('Error fetching indicator cards:', error);
        }
    };

    const viewOptions = [
        'all',
        ...Object.keys(indicatorsData)
            .filter(key => key !== 'all')
            .sort((a, b) => a.localeCompare(b))
    ];

    const getFilteredData = () => {
        return view === 'all' ? indicatorsData.all : indicatorsData[view] || {};
    };

    const filteredData = getFilteredData();
    const llmIndicators = Object.keys(filteredData).filter(key =>
        !['ratio', 'segments', 'affirmations', 'sentiment', 'interactionMap', 'llmIndicators', 'userId'].includes(key)
    );
    const speakerName = view === 'all' ? 'All Speakers' : filteredData.speakerName || `${view}`;

    useEffect(() => {
        if (llmIndicators.length > 0 && !selectedIndicator) {
            setSelectedIndicator(llmIndicators[0]);
        }
    }, [llmIndicators, selectedIndicator]);

    const handleChipClick = (indicator) => {
        setSelectedIndicator(indicator);
        trackInteraction.buttonClick('indicator_'+indicator);
    };

    const handleStatementClick = (statement) => {
        if (transcriptionRef.current) {
            transcriptionRef.current.highlightAndScrollTo(statement);
        }
    };

    const formatKeyFromCamelCaseToNormal = (key) => {
        return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    };

    const selectedData = selectedIndicator ? filteredData[selectedIndicator] : null;

    if (!selectedData) {
        return <Typography>No indicator data available</Typography>;
    }

    const meetingTitle = transcript?.metaData?.meetingName || 'Unknown Meeting Name';

    return (
        <Box className="indicators-container" sx={{ backgroundColor: RacingGreenPalette.background.main }}>
            <Paper elevation={0} className="page-header" 
                sx={{ backgroundColor: RacingGreenPalette.background.card }}>
                <Box className="title-container">
                    <Typography variant="h4" className="indicators-title"
                        sx={{ color: textColors.primary }}>
                        {meetingTitle}
                    </Typography>
                </Box>
                <Box className="indicators-controls">
                    <FormControl className="view-select" variant="outlined" size="small">
                        <InputLabel id="view-select-label"
                            sx={{ color: RacingGreenPalette.text.secondary }}>
                            Data View
                        </InputLabel>
                        <Select
                            labelId="view-select-label"
                            id="view-select"
                            value={view}
                            label="Data View"
                            onChange={(e) => setView(e.target.value)}
                            sx={{ 
                                color: RacingGreenPalette.text.primary,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: `${RacingGreenPalette.primary.main}33`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: RacingGreenPalette.primary.light,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: RacingGreenPalette.primary.main,
                                }
                            }}
                        >
                            {viewOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option === 'all' ? 'All Participants' : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Paper>

            <Grid container spacing={2} className="indicators-content">
                <Grid item xs={12} md={8}>
                    <Paper elevation={2} className="indicator-card"
                        sx={{ 
                            backgroundColor: RacingGreenPalette.background.card,
                            borderTop: `1px solid ${RacingGreenPalette.primary.main}`
                        }}>
                        <Box className="card-header">
                            <Box className="card-title">
                                <AssessmentIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />
                                <Typography variant="h6" sx={{ color: RacingGreenPalette.text.primary }}>
                                    {speakerName}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ backgroundColor: `${RacingGreenPalette.primary.main}22` }} />
                        <Box className="card-content">
                            <Box className="chip-container">
                                {llmIndicators.map((key) => (
                                    <Chip
                                        key={key}
                                        label={formatKeyFromCamelCaseToNormal(key)}
                                        onClick={() => handleChipClick(key)}
                                        className={`indicator-chip ${selectedIndicator === key ? 'selected-chip' : ''}`}
                                    />
                                ))}
                            </Box>

                            <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
                                <Typography variant="h6" sx={{ 
                                    color: RacingGreenPalette.text.primary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px'
                                }}>
                                    <AssessmentIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />
                                    {formatKeyFromCamelCaseToNormal(selectedIndicator)}
                                </Typography>
                                <Box sx={{ ml: 2 }}>
                                    <IndicatorCard
                                        indicator={selectedIndicator}
                                        indicatorCards={indicatorCards}
                                    />
                                </Box>
                            </Box>

                            <AnalysisSection
                                analysis={selectedData.analysis}
                                improvementTips={selectedData.improvementTips}
                                score={selectedData.score}
                                scoreBreakdown={selectedData.scoreBreakdown}
                                scoreConfidence={selectedData.scoreConfidence}
                            />

                            {Object.entries(selectedData).map(([subIndicator, value]) => (
                                !['speakerName', 'speakerId', 'score', 'analysis', 'scoreBreakdown', 'scoreConfidence', 'improvementTips', 'userId'].includes(subIndicator) &&
                                value && (
                                    <Box key={subIndicator} className="statement-container">
                                        <Box className="statement-header">
                                            <Typography className="statement-title">
                                                {formatKeyFromCamelCaseToNormal(subIndicator)}
                                            </Typography>
                                        </Box>
                                        <Box className="statement-content">
                                            {Array.isArray(value) ? (
                                                value.map((statement, index) => (
                                                    <StatementBox
                                                        key={index}
                                                        statement={statement}
                                                        onClick={handleStatementClick}
                                                    />
                                                ))
                                            ) : (
                                                <Typography variant="body1">
                                                    {value}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                )
                            ))}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} className="transcription-column">
                    <Paper elevation={2} className="dashboard-card transcript"
                        sx={{ 
                            backgroundColor: RacingGreenPalette.background.card,
                            borderTop: `1px solid ${RacingGreenPalette.primary.dark}`
                        }}>
                        <div className="card-header">
                            <div className="card-title">
                                <DescriptionIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />
                                <Typography variant="h6" sx={{ color: RacingGreenPalette.text.primary }}>
                                    Transcript
                                </Typography>
                            </div>
                        </div>
                        <Divider sx={{ backgroundColor: `${RacingGreenPalette.primary.main}22` }} />
                        <Box className="transcript-container" 
                            sx={{ backgroundColor: RacingGreenPalette.background.card }}>
                            <Transcription 
                                data={transcriptData} 
                                setData={setTranscriptData} 
                                ref={transcriptionRef} 
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Indicators;
