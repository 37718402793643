import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import SpeakerRow from './SpeakerRow';

const EditableSpeakerMatchingPreview = ({ 
    matches, 
    teamMembers, 
    audioSnippets, 
    onConfirm, 
    onCancel,
    isEditingExisting = false
}) => {
    const [editedMatches, setEditedMatches] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [currentPlayingId, setCurrentPlayingId] = useState(null);
    const audioContextRef = useRef(null);
    const activeSourceRef = useRef(null);

    useEffect(() => {
        return () => {
            if (activeSourceRef.current) {
                activeSourceRef.current.stop();
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    // In SpeakerMatchingPreview.js, update the useEffect:
    useEffect(() => {
        // Keep all speakers but only clear user mapping if confidence is low
        setEditedMatches(matches.map(match => ({
            ...match,
            // Preserve all speaker IDs but clear user mapping if confidence is low
            speakerId: match.speakerId, // Always keep speaker ID
            userId: isEditingExisting ? match.userId : (match.confidence >= 0.85 ? match.userId : ''),
            userName: isEditingExisting ? match.userName : (match.confidence >= 0.85 ? match.userName : ''),
            email: isEditingExisting ? match.email : (match.confidence >= 0.85 ? match.email : ''),
            isEdited: false,
            isGuest: match.isGuest
        })));
    
        // Make sure we only set availableUsers if teamMembers is actually an array with items
        if (Array.isArray(teamMembers) && teamMembers.length > 0) {
            setAvailableUsers(teamMembers);
        }
    }, [matches, teamMembers, isEditingExisting]);



    const handleSelect = (speakerId, selectedUser) => {
        setEditedMatches(prevMatches => prevMatches.map(match => {
            if (match.speakerId === speakerId) {
                return {
                    ...match,
                    userId: selectedUser.isGuest ? 'guest' : selectedUser.id,
                    userName: selectedUser.name,
                    email: selectedUser.email,
                    isEdited: true,
                    confidence: selectedUser.confidence
                };
            }
            return match;
        }));
    };

    const handleRemove = (speakerId) => {
        setEditedMatches(prevMatches => prevMatches.map(match => {
            if (match.speakerId === speakerId) {
                return {
                    ...match,
                    userId: '',
                    userName: '',
                    email: '',
                    isEdited: true,
                    confidence: 0,
                    isGuest: false
                };
            }
            return match;
        }));
    };

    const handleConfirm = async () => {
        try {
            setIsSubmitting(true);
            await onConfirm(editedMatches);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handlePlayAudio = async (snippetId, audioData) => {
        if (activeSourceRef.current) {
            activeSourceRef.current.stop();
            activeSourceRef.current = null;
        }

        if (!snippetId) {
            setCurrentPlayingId(null);
            return;
        }

        try {
            if (!audioContextRef.current) {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            }

            const arrayBuffer = await decodeBase64Audio(audioData.audioData);
            const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
            
            const source = audioContextRef.current.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContextRef.current.destination);
            
            source.onended = () => {
                setCurrentPlayingId(null);
                activeSourceRef.current = null;
            };

            activeSourceRef.current = source;
            source.start(0);
            setCurrentPlayingId(snippetId);

        } catch (error) {
            console.error('Error playing audio:', error);
            setCurrentPlayingId(null);
        }
    };

    const decodeBase64Audio = (base64Data) => {
        const binaryString = atob(base64Data);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const sortedMatches = [...editedMatches].sort((a, b) => {
        // Sort by speakerId numerically
        return parseInt(a.speakerId) - parseInt(b.speakerId);
    });


    const hasAutomatedMatches = matches.some(match => match.confidence >= 0.85);

    return (
        <Paper 
            elevation={0} 
            sx={{ 
                width: '100%', 
                maxWidth: '4xl',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'visible'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2 }}>
                <PersonIcon color="primary" />
                <Typography variant="h6">
                    Speaker Mapping
                </Typography>
            </Box>

            {!hasAutomatedMatches ? (
                <Alert severity="warning" sx={{ mb: 3 }}>
                    <AlertTitle>No Automatic Matches Found</AlertTitle>
                    We couldn't automatically match any speakers with high confidence. 
                    Please manually assign speakers using the selection boxes below.
                </Alert>
            ) : (
                <Alert severity="info" sx={{ mb: 3 }}>
                    <AlertTitle>Please Review and Edit if Needed</AlertTitle>
                    We've automatically matched speakers based on voice recognition. 
                    You can review the audio snippets and edit these matches before confirming.
                </Alert>
            )}

            <TableContainer sx={{ 
                mb: 3,
                maxHeight: 'unset', // Remove any max height constraints
                overflow: 'visible' // This ensures dropdowns aren't cut off
            }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 500 }}>Speaker</TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>Matched To</TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>Confidence</TableCell>
                            <TableCell sx={{ fontWeight: 500 }}>Audio Samples</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedMatches.map((match) => (
                            <SpeakerRow
                                key={match.speakerId}
                                match={match}
                                availableUsers={availableUsers}
                                audioSnippets={audioSnippets}
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                editedMatches={editedMatches}
                                currentPlayingId={currentPlayingId}
                                onPlayAudio={handlePlayAudio}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                gap: 2, 
                mt: 4,
                px: 0
            }}>
                <Button
                    variant="outlined"
                    onClick={onCancel}
                    disabled={isSubmitting}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConfirm}
                    disabled={isSubmitting}
                    startIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                >
                    {isSubmitting ? 'Confirming...' : 'Confirm Matches'}
                </Button>
            </Box>
        </Paper>
    );
};

export default EditableSpeakerMatchingPreview;
