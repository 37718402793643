import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import config from '../../config';
import {
    Box,
    Typography,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdvancedAnalyses = ({ meetingId }) => {
    const [analyses, setAnalyses] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnalyses = async () => {
            try {
                const response = await axios.get(
                    `${config.backendUrlApiV1}/meeting/${meetingId}/advanced-analyses/`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );
                setAnalyses(response.data.analyses);
            } catch (err) {
                setError(err.response?.data?.error || 'Failed to fetch analyses');
            } finally {
                setLoading(false);
            }
        };

        fetchAnalyses();
    }, [meetingId]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={2}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (!analyses) {
        return (
            <Box p={2}>
                <Alert severity="info">No analyses available for this meeting.</Alert>
            </Box>
        );
    }

    const analysisTypes = {
        'nonViolentCommunication': 'Non-Violent Communication',
        'energyFlow': 'Energy Flow',
        'processEmotion': 'Process Emotion',
        'interactionAnalysis': 'Interaction Analysis',
        'mentalModel': 'Mental Model',
        'elementsFramework': 'Elements Framework'
    };

    return (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>
                Advanced Meeting Analyses
            </Typography>
            {Object.entries(analysisTypes).map(([key, title]) => (
                analyses[key] && (
                    <Accordion key={key}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ '& p': { marginBottom: '1em' } }}>
                                <ReactMarkdown>{analyses[key]}</ReactMarkdown>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )
            ))}
        </Box>
    );
};

export default AdvancedAnalyses; 
