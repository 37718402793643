import React, {useEffect} from 'react';
import {useAuth} from "./AuthProvider";
import {useNavigate, useLocation} from "react-router-dom";
import {CircularProgress, Box} from '@mui/material';

const AuthCallback = () => {
    const {handleLogin} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const email = params.get('email');
        const provider = params.get('provider');
        const errorParam = params.get('error');
        // const reasonParam = params.get('reason'); // Optional: Can get reason too if needed

        // Check for whitelist error first
        if (errorParam === 'access_denied') {
            // Redirect to sign-in page WITH the error parameter
            navigate(`/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3?error=access_denied`, { replace: true });
        }
        // If no error, check for successful login parameters
        else if (token && email && provider) {
            handleLogin(token, email, provider);
            navigate('/product', { replace: true });
        }
        // Otherwise, it's an unexpected state (missing params, no specific error)
        else {
            console.error('Missing or unexpected auth parameters:', { token, email, provider, error: errorParam });
            // Redirect to sign-in without error params in this case
            navigate('/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3', { replace: true });
        }
    }, [location, navigate, handleLogin]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );
};

export default AuthCallback;
