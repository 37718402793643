import React from 'react';
import { Box, Typography } from '@mui/material';
import UserSearchInput from './UserSearchInput';
import UserChip from "./UserChip";

export const UserSelectionInput = ({
    role,
    label,
    availableUsers,
    onSelect,
    selectedUser = null,
    onRemove,
    placeholder,
    containerSx = {},  // Add style prop
    searchBoxSx = {}   // Add style prop for search box
}) => {
    return (
        <Box 
            sx={{ 
                width: '100%',
                margin: 0,
                padding: 0,
                '& .MuiBox-root': {
                    height: 'auto'
                },
                ...containerSx  // Merge custom styles
            }}
        >
            {selectedUser ? (
                <UserChip
                    user={selectedUser}
                    onDelete={() => onRemove(role, selectedUser.id)}
                />
            ) : (
                <Box sx={{ width: '100%', margin: 0, padding: 0, ...searchBoxSx }}>
                    <UserSearchInput
                        availableUsers={availableUsers}
                        onUserSelect={(user) => onSelect(role, user)}
                        placeholder={placeholder}
                        openOnFocus={true}
                    />
                </Box>
            )}
        </Box>
    );
};

export const UserMultiSelectionInput = ({
    role,
    label,
    availableUsers,
    selectedUsers = [],
    onSelect,
    onRemove,
    containerSx = {},    // Add style prop
    searchBoxSx = {}     // Add style prop for search box
}) => (
    <Box 
        className="user-selection-container" 
        sx={{ 
            width: '100%',
            ...containerSx  // Merge custom styles
        }}
    >
        {label && (
            <Typography variant="subtitle1" className="input-label">
                {label} ({selectedUsers.length})
            </Typography>
        )}

        <Box 
            className="chip-container" 
            sx={{ 
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                mb: selectedUsers.length > 0 ? 2 : 0
            }}
        >
            {selectedUsers.map((user) => (
                <UserChip
                    key={user.id}
                    user={user}
                    onDelete={() => onRemove(role, user.id)}
                />
            ))}
        </Box>

        <Box sx={{ width: '100%', ...searchBoxSx }}>
            <UserSearchInput
                availableUsers={availableUsers}
                onUserSelect={(user) => onSelect(role, user)}
                openOnFocus={true}
            />
        </Box>
    </Box>
);
