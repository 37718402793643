import React, {useState, useRef, useEffect} from 'react';
import axios from 'axios';
import {
    Box, 
    IconButton, 
    Typography, 
    CircularProgress, 
    Grid, 
    Tabs, 
    Tab,
    Paper,
    Popover,
    Tooltip,
    Divider
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import ForumIcon from '@mui/icons-material/Forum';
import DescriptionIcon from '@mui/icons-material/Description';
import TimelineIcon from '@mui/icons-material/Timeline';
import './Heartbeat.css';
import './HeartbeatTimeline.css'; // Import the new timeline CSS
import HeartbeatTimeline from "./HeartbeatTimeline";
import Transcription from "../transcript/Transcription";
import config from '../../../config';
import ChatWithAI from "./ChatWithAI";
import RacingGreenPalette from '../../../RacingGreenPalette';
import AudioControls from './AudioControls';

const MIN_SPEED = 0.1;
const MAX_SPEED = 2.0;
const DEFAULT_SPEED = 1.0;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            style={{ height: '100%', borderRadius: '1px' }}
            {...other}
        >
            {value === index && (
                <Box sx={{ 
                    height: '100%', 
                    overflow: 'auto',
                    borderLeft: `1px solid ${RacingGreenPalette.primary.main}22`,
                    borderRight: `1px solid ${RacingGreenPalette.primary.main}22`,
                }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const Heartbeat = ({transcriptData}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoadingAudio, setIsLoadingAudio] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [transcriptState, setTranscriptState] = useState(transcriptData);
    const [tabIndex, setTabIndex] = useState(0); 
    const [meetingIntelligence, setMeetingIntelligence] = useState(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const audioRef = useRef(null);
    const [isChatExpanded, setIsChatExpanded] = useState(false);
    const [speedAnchorEl, setSpeedAnchorEl] = useState(null);
    const [speakerColors, setSpeakerColors] = useState({});

    useEffect(() => {
        if (transcriptData && transcriptData.metaData && transcriptData.metaData.meetingId) {
            fetchAudioUrl(transcriptData.metaData.meetingId);
            fetchMeetingIntelligence(transcriptData.metaData.meetingId);
        }
    }, [transcriptData]);

    useEffect(() => {
        if (transcriptData) {
            setTranscriptState(prevState => ({
                ...prevState,
                ...transcriptData
            }));
        }
    }, [transcriptData]);

    const fetchAudioUrl = async (meetingId) => {
        setIsLoadingAudio(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meeting/${meetingId}/get-audio-file/`);
            setAudioUrl(response.data.url);
        } catch (error) {
            console.error('Error fetching audio URL:', error);
        } finally {
            setIsLoadingAudio(false);
        }
    };

    const fetchMeetingIntelligence = async (meetingId) => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-intelligence/${meetingId}/`);
            setMeetingIntelligence(response.data);
        } catch (error) {
            console.error('Error fetching meeting intelligence:', error);
        }
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.src = audioUrl;
            audioRef.current.load();
        }
    }, [audioUrl]);

    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = playbackSpeed;
        }
    }, [playbackSpeed]);

    useEffect(() => {
        const audio = audioRef.current;
        const updateTime = () => {
            setCurrentTime(audio.currentTime);
        };
        const setAudioData = () => {
            setDuration(audio.duration);
        };
        audio.addEventListener('timeupdate', updateTime);
        audio.addEventListener('loadedmetadata', setAudioData);
        return () => {
            audio.removeEventListener('timeupdate', updateTime);
            audio.removeEventListener('loadedmetadata', setAudioData);
        };
    }, []);

    const handleTogglePlayPause = () => {
        const audio = audioRef.current;
        if (isPlaying) {
            audio.pause();
        } else {
            audio.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleSkipTime = (seconds) => {
        const audio = audioRef.current;
        audio.currentTime = Math.min(Math.max(0, audio.currentTime + seconds), duration);
    };

    const handleSeek = (event, newValue) => {
        const audio = audioRef.current;
        audio.currentTime = newValue;
        setCurrentTime(newValue);
    };

    const handleSpeedClick = (event) => {
        setSpeedAnchorEl(event.target);
    };

    const handleSpeedClose = () => {
        setSpeedAnchorEl(null);
    };

    const handleSpeedChange = () => {
        // Cycle through common speed values: 1.0 -> 1.5 -> 2.0 -> 0.5 -> 0.75 -> 1.0
        let newSpeed;
        if (playbackSpeed === 1.0) newSpeed = 1.5;
        else if (playbackSpeed === 1.5) newSpeed = 2.0;
        else if (playbackSpeed === 2.0) newSpeed = 0.5;
        else if (playbackSpeed === 0.5) newSpeed = 0.75;
        else newSpeed = 1.0;
        
        setPlaybackSpeed(newSpeed);
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = newSpeed;
        }
    };

    const handleSpeedSliderChange = (event, newValue) => {
        setPlaybackSpeed(newValue);
        const audio = audioRef.current;
        if (audio) {
            audio.playbackRate = newValue;
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTimeChange = (newTime) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    const handleDownload = async () => {
        if (audioUrl) {
            try {
                const response = await fetch(audioUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `meeting-${transcriptData.metaData.meetingId}.mp3`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const toggleChatExpand = () => {
        setIsChatExpanded(!isChatExpanded);
    };
    
    // Get meeting title from transcript data
    const meetingTitle = transcriptData?.metaData?.meetingName || 'Meeting Playback';

    // Add handler for speaker colors update
    const handleSpeakerColorsUpdate = (colors) => {
        setSpeakerColors(colors);
    };

    return (
        <Box className="heartbeat-container" sx={{ backgroundColor: 'white' }}>
            {/* Page header with meeting title - exactly matching MeetingAnalysis and Indicators */}
            <Paper elevation={0} className="page-header" 
                sx={{ backgroundColor: RacingGreenPalette.background.card }}>
                <Box className="title-container">
                    <Typography variant="h4" className="dashboard-title"
                        sx={{ color: RacingGreenPalette.primary.main }}>
                        {meetingTitle}
                    </Typography>
                </Box>
                <Box className="dashboard-controls">
                    {/* Audio controls will be below the header */}
                </Box>
            </Paper>

            <Grid container spacing={2} sx={{ padding: '0px 2px' }}>
                <Grid 
                    item 
                    xs={12} 
                    md={isChatExpanded ? 4 : 8}
                    className="playback-grid"
                    sx={{
                        transition: 'all 0.3s ease-in-out'
                    }}
                >
                
                {/* Timeline section - fixed to exactly match reference image with proper containment */}
                {/* Audio Controls - Updated to match the timeline card border */}
                {isLoadingAudio ? (
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            height: '100px',
                            backgroundColor: 'white',
                            borderTop: `1px solid ${RacingGreenPalette.primary.main}`,
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                            mb: 2,
                            borderLeft: 'none',
                            borderRight: 'none'
                        }}
                    >
                        <CircularProgress size={24} sx={{ color: RacingGreenPalette.primary.main }}/>
                    </Box>
                ) : (
                    <AudioControls 
                        currentTime={currentTime}
                        duration={duration}
                        isPlaying={isPlaying}
                        onPlayPause={handleTogglePlayPause}
                        onSeek={handleSeek}
                        onSkip={handleSkipTime}
                        playbackSpeed={playbackSpeed}
                        onSpeedChange={handleSpeedChange}
                        audioUrl={audioUrl}
                        onDownload={handleDownload}
                    />
                )}

                    {/* Timeline section - fixed to exactly match reference image with proper containment */}
                    <Paper 
                        elevation={2} 
                        className="dashboard-card"
                        sx={{ 
                            backgroundColor: 'white',
                            borderTop: `1px solid ${RacingGreenPalette.primary.main}`,
                            width: '100%',
                            marginBottom: '16px',
                            overflowY: 'auto',
                            position: 'relative',
                            maxHeight: '777px',
                            borderLeft: 'none',
                            borderRight: 'none',
                        }}
                    >
                        <div className="card-header">
                            <div className="card-title">
                                <TimelineIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />
                                <Typography variant="h6" className="card-title-text"
                                    sx={{ 
                                        color: RacingGreenPalette.text.primary 
                                    }}
                                >
                                    Meeting Timeline
                                </Typography>
                            </div>
                        </div>
                        <Divider sx={{ backgroundColor: `${RacingGreenPalette.primary.main}22` }} />
                        <Box 
                            sx={{ 
                                position: 'relative',
                                borderLeft: 'none',
                                borderRight: 'none',
                                height: 'auto'
                            }}
                        >
                            <HeartbeatTimeline
                                transcriptData={transcriptData}
                                meetingIntelligence={meetingIntelligence}
                                duration={duration}
                                currentTime={currentTime}
                                onTimeChange={handleTimeChange}
                                speakerColors={speakerColors}
                            />
                        </Box>
                    </Paper>
                
                <audio ref={audioRef}/>
            </Grid>
            
            {/* Transcript/Chat Section */}
            <Grid 
                item 
                xs={12} 
                md={isChatExpanded ? 8 : 4}
                className="tab-view-grid"
                sx={{
                    transition: 'all 0.3s ease-in-out'
                }}
            >
                <Paper 
                    elevation={2} 
                    className="dashboard-card transcript"
                    sx={{ 
                        backgroundColor: 'white',
                        borderTop: `1px solid ${RacingGreenPalette.primary.main}`,
                        borderLeft: `1px solid ${RacingGreenPalette.primary.main}22`,
                        borderRight: `1px solid ${RacingGreenPalette.primary.main}22`,
                        borderBottom: `1px solid ${RacingGreenPalette.primary.main}22`,
                        borderRadius: '1px',
                        height: 'calc(100% - 16px)',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <Box 
                        sx={{ 
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            zIndex: 10
                        }}
                    >
                        <Tooltip 
                            title={isChatExpanded ? "Collapse" : "Expand"} 
                            placement="left"
                        >
                            <IconButton
                                onClick={toggleChatExpand}
                                size="small"
                                sx={{
                                    color: RacingGreenPalette.text.secondary,
                                    backgroundColor: 'white',
                                    boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5'
                                    },
                                    transition: 'transform 0.2s ease-in-out',
                                    transform: isChatExpanded ? 'rotate(180deg)' : 'none',
                                    borderRadius: '1px'
                                }}
                            >
                                {isChatExpanded ? 
                                    <CloseFullscreenIcon fontSize="small" /> : 
                                    <OpenInFullIcon fontSize="small" />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                    
                    <Tabs 
                        value={tabIndex} 
                        onChange={handleTabChange} 
                        aria-label="Transcript Tabs"
                        sx={{ 
                            borderBottom: `1px solid ${RacingGreenPalette.primary.main}22`,
                            '& .MuiTabs-indicator': {
                                backgroundColor: RacingGreenPalette.primary.main
                            },
                            '& .MuiTab-root': {
                                color: RacingGreenPalette.text.secondary,
                                fontSize: '14px',
                                fontWeight: 600,
                                padding: '14px 16px',
                                minWidth: 'unset',
                                textTransform: 'none',
                                '&.Mui-selected': {
                                    color: RacingGreenPalette.primary.main
                                }
                            }
                        }}
                    >
                        <Tab 
                            icon={<DescriptionIcon sx={{ fontSize: 18, mr: 1 }} />}
                            iconPosition="start"
                            label="Transcript" 
                        />
                        <Tab 
                            icon={<ForumIcon sx={{ fontSize: 18, mr: 1 }} />}
                            iconPosition="start"
                            label="Ask Elevaide" 
                        />
                    </Tabs>
                    
                    <Box 
                        sx={{
                            height: 'calc(100% - 48px)',
                            overflow: 'auto',
                            padding: 0
                        }}
                    >
                        <TabPanel value={tabIndex} index={0}>
                            <Transcription 
                                data={transcriptState} 
                                setData={setTranscriptState}
                                onSpeakerColorsUpdate={handleSpeakerColorsUpdate}
                            />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <ChatWithAI transcriptData={transcriptState}/>
                        </TabPanel>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    </Box>
    );
};

export default Heartbeat;
