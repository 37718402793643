import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, Button, Grid, CircularProgress } from '@mui/material'; // Removed TextField import as it's now in ProfileSettingsForm
import './Settings.css';
import ModalOverlay from '../../components/modal/ModalOverlay';
import ProfileSettingsForm from './ProfileSettingsForm'; // Import the new component
const botJoiningSettings = [
    {
        value: 'all',
        label: 'Join all meetings',
        description: 'The meeting assistant attends all meetings in your calendar',
    },
    {
        value: 'accepted',
        label: 'Join all accepted meetings',
        description: 'The meeting Assistant attends all meetings accepted by you or organized by you',
    },
    {
        value: 'organized',
        label: 'Join meetings organized by you',
        description: 'The meeting assistant attends all meetings only organized by you',
    },
    {
        value: 'invited',
        label: 'Join when invited',
        description: 'Invite bot@elevaide.com to your calendar event, and the meeting assistant will attend',
    },
];

const Settings = ({ open, onClose }) => {
    // Settings state removed

    // Profile state
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '', // Keep email potentially read-only or handle changes carefully
        jobTitle: '',
        department: '',
        location: '',
        phoneNumber: '',
        personalGrowthGoals: '', // Renamed from bio
        focusAreas: [],
    });
    const [initialProfileData, setInitialProfileData] = useState(null); // Store initial profile data
    const [profileLoading, setProfileLoading] = useState(false);
    // const [settingsLoading, setSettingsLoading] = useState(false); // Removed settings loading state
    const [saving, setSaving] = useState(false); // State for save operation
    const [error, setError] = useState(''); // State for errors

    useEffect(() => {
        const fetchInitialData = async () => {
            setError('');
            // setSettingsLoading(true); // Removed
            setProfileLoading(true);
            try {
                // Fetch only profile data now
                const profileResponse = await axios.get(`${config.backendUrlApiV1}/user/profile/`);

                // Process profile
                if (profileResponse.data) {
                    setProfileData({
                        firstName: profileResponse.data.firstName || '',
                        lastName: profileResponse.data.lastName || '',
                        email: profileResponse.data.email || '',
                        jobTitle: profileResponse.data.jobTitle || '',
                        department: profileResponse.data.department || '',
                        location: profileResponse.data.location || '',
                        phoneNumber: profileResponse.data.phoneNumber || '',
                        personalGrowthGoals: profileResponse.data.personalGrowthGoals || '', // Renamed key
                        focusAreas: profileResponse.data.focusAreas || [],
                    });
                    setInitialProfileData(profileResponse.data); // Set initial profile data
                }

            } catch (err) {
                console.error('Error fetching initial profile data:', err); // Keep single catch block
                setError('Failed to load profile data. Please try again.');
            } finally {
                // setSettingsLoading(false); // Removed
                setProfileLoading(false);
            }
        };

        if (open) {
            fetchInitialData();
        }
    }, [open]);

    // handleBotJoiningChange removed as the setting is no longer displayed/editable

    const handleProfileChange = (event) => {
        const { name, value } = event.target;
        setProfileData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handler for focusAreas specifically (comma-separated string to array)
    const handleFocusAreasChange = (event) => {
         setProfileData(prevData => ({
            ...prevData,
            focusAreas: event.target.value.split(',').map(s => s.trim()).filter(Boolean) // Convert string to array
        }));
    };

    const handleSave = async () => {
        setError('');
        setSaving(true);
        try {
            // Prepare profile data payload (handle focusAreas conversion if needed)
             const profilePayload = { ...profileData };
             // Ensure focusAreas is an array if it was handled differently
             if (typeof profilePayload.focusAreas === 'string') {
                 profilePayload.focusAreas = profilePayload.focusAreas.split(',').map(s => s.trim()).filter(Boolean);
             }


            const apiCalls = [];

            // Check if profile data has changed
            // Simple comparison using JSON stringify; consider a deep comparison library for more complex objects if needed
            const profileChanged = JSON.stringify(profileData) !== JSON.stringify(initialProfileData);
            if (profileChanged) {
                 apiCalls.push(axios.put(`${config.backendUrlApiV1}/user/profile/`, profilePayload));
            }

            // Settings change check and API call removed

            // Only proceed if there are changes to save
            if (apiCalls.length > 0) {
                await Promise.all(apiCalls);
                // Optionally update initial state after successful save if modal stays open
                // setInitialProfileData(profileData);
                // setInitialBotJoiningSetting(botJoiningSetting);
            }

            onClose(); // Close modal regardless of whether changes were saved or not
        } catch (err) {
            console.error('Error saving settings/profile:', err);
            setError(err.response?.data?.error || 'Failed to save changes. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    return (
        <ModalOverlay isOpen={open} onClose={onClose} title="Profile"> {/* Title already updated */}
            <Box>
                {/* Loading Indicator */}
                 {profileLoading && ( // Only check profileLoading now
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                        <CircularProgress />
                    </Box>
                )}

                 {/* Error Message */}
                 {error && (
                    <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>
                 )}

                {/* Profile Section */}
                 {/* Render Profile Section using the new component */}
                 {!profileLoading && !error && (
                     <ProfileSettingsForm
                         profileData={profileData}
                         handleProfileChange={handleProfileChange}
                         handleFocusAreasChange={handleFocusAreasChange}
                         saving={saving}
                     />
                 )}


                 {/* Meeting Settings Section Removed */}
                <Button
                    variant="contained"
                    color="primary"
                    className="modal-save-button"
                    onClick={handleSave}
                    disabled={profileLoading || saving} // Removed settingsLoading check
                >
                    {saving ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
                    {/* Removed duplicate static text */}
                </Button>
            </Box>
        </ModalOverlay>
    );
};

export default Settings;
