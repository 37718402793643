import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const RotatingText = ({ words, typingSpeed = 100, deletingSpeed = 50, pauseDuration = 2000 }) => {
  const [currentText, setCurrentText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [showCursor, setShowCursor] = useState(true);
  const theme = useTheme();
  
  // This will help us control the typing speed
  const typingSpeedRef = useRef(typingSpeed);
  
  useEffect(() => {
    const word = words[currentIndex];
    
    // Set the typing speed based on whether we're typing or deleting
    typingSpeedRef.current = isDeleting ? deletingSpeed : typingSpeed;
    
    // Handle typing animation
    const handleTyping = () => {
      if (!isDeleting) {
        // Typing forward
        setCurrentText(word.substring(0, currentText.length + 1));
        
        // If we've completed the word, pause then start deleting
        if (currentText.length === word.length) {
          setIsActive(true);
          setShowCursor(false); // Hide cursor when word is complete
          setTimeout(() => {
            setIsDeleting(true);
            setIsActive(false);
            setShowCursor(true); // Show cursor again when deleting starts
          }, pauseDuration);
        }
      } else {
        // Deleting
        setCurrentText(word.substring(0, currentText.length - 1));
        
        // If we've deleted the word, move to the next word
        if (currentText.length === 0) {
          setIsDeleting(false);
          setCurrentIndex((currentIndex + 1) % words.length);
        }
      }
    };
    
    // Set the interval for the typing animation
    const animationInterval = setTimeout(handleTyping, typingSpeedRef.current);
    
    // Clean up
    return () => clearTimeout(animationInterval);
  }, [currentText, isDeleting, currentIndex, words, typingSpeed, deletingSpeed, pauseDuration]);
  
  return (
    <Box className="rotating-text-container">
      <Typography
        variant="inherit"
        component="span"
        className={`rotating-text ${isActive ? 'active' : ''}`}
        sx={{
          position: 'relative',
          fontWeight: 'bold',
          textShadow: '0 0 1px rgba(32, 193, 122, 0.2)',
          display: 'inline-block',
          background: 'linear-gradient(90deg, #00753a, #20c17a)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          color: 'transparent',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '3px',
            bottom: '-5px',
            left: 0,
            background: 'linear-gradient(90deg, #00753a, #20c17a)',
            transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
            transformOrigin: isActive ? 'bottom left' : 'bottom right',
            transition: 'transform 0.5s ease-out',
          }
        }}
      >
        {currentText}
        {showCursor && <span style={{ opacity: '0.3' }}>|</span>}
      </Typography>
    </Box>
  );
};

export default RotatingText;
