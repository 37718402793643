import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Link,
  useTheme,
  Stack,
  AppBar,
  Toolbar
} from '@mui/material';
import {
  LocationOn,
  Email,
  Phone,
  Business,
  Person,
} from '@mui/icons-material';
import logo from '../../images/logos/elevaide-logo.png';

const Contact = () => {
  const theme = useTheme();

  const styles = {
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    contentContainer: {
      flex: 1,
      backgroundColor: theme.palette.grey[50],
      py: 8,
      px: 2,
    },
    title: {
      fontWeight: 'bold',
      mb: 4,
      background: 'linear-gradient(to right, #004D40, #80CBC4)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    subtitle: {
      color: theme.palette.text.secondary,
      mb: 6,
    },
    card: {
      height: '100%',
      boxShadow: theme.shadows[3],
      '&:hover': {
        boxShadow: theme.shadows[6],
        transition: 'box-shadow 0.3s ease-in-out',
      },
    },
    cardTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      mb: 2,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    icon: {
      color: theme.palette.primary.main,
    },
    contactLink: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  };

  return (
    <Box sx={styles.pageContainer}>
      {/* Header */}
      <AppBar position="sticky" color="default" elevation={0} sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        bgcolor: 'background.default',
        backdropFilter: 'blur(8px)'
      }}>
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: 'space-between', height: 64, px: 0 }}>
            <Box component="a" href="/" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <div className="logo-container">
                <img 
                  src={logo} 
                  alt="Elevaide Logo" 
                  style={{ width: 100, height: 'auto' }}
                />
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box sx={styles.contentContainer}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h1" sx={styles.title}>
            Contact Us
          </Typography>
          <Typography variant="h6" sx={styles.subtitle}>
            We're here to help and answer any questions you might have
          </Typography>

          <Grid container spacing={4}>
            {/* Company Information Card - remains the same */}
            <Grid item xs={12} md={6}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h5" sx={styles.cardTitle}>
                    <Business /> Company Details
                  </Typography>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Legal Name
                      </Typography>
                      <Typography color="text.secondary">
                        Elevaide B.V.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="medium">
                        Registration
                      </Typography>
                      <Typography color="text.secondary">
                        KVK: 94485682
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            {/* Contact Information Card */}
            <Grid item xs={12} md={6}>
              <Card sx={styles.card}>
                <CardContent>
                  <Typography variant="h5" sx={styles.cardTitle}>
                    <Email /> Contact Information
                  </Typography>
                  <Stack spacing={2}>
                    {/* New Contact Person Section */}
                    <Box sx={styles.infoRow}>
                      <Person sx={styles.icon} />
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          Main Contact
                        </Typography>
                        <Typography color="text.secondary">
                          Jeroen van Velzen, Co-founder & CEO
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={styles.infoRow}>
                      <LocationOn sx={styles.icon} />
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          Address
                        </Typography>
                        <Typography color="text.secondary">
                          Van Vollenhovenlaan 16 A
                          <br />
                          2111 HH Aerdenhout
                          <br />
                          Netherlands
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={styles.infoRow}>
                      <Phone sx={styles.icon} />
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          Phone
                        </Typography>
                        <Link href="tel:+31613131717" sx={styles.contactLink}>
                          +31613131717
                        </Link>
                      </Box>
                    </Box>

                    <Box sx={styles.infoRow}>
                      <Email sx={styles.icon} />
                      <Box>
                        <Typography variant="subtitle1" fontWeight="medium">
                          Email
                        </Typography>
                        <Link href="mailto:jeroen@elevaide.com" sx={styles.contactLink}>
                          jeroen@elevaide.com
                        </Link>
                      </Box>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Contact;
