import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import axios from "axios";
import config from "../../../config";
import {useRefresh} from "../../../auth/RefreshProvider";
import './Transcription.css';
import RacingGreenPalette from '../../../RacingGreenPalette';
import { useTheme } from '@mui/material/styles';

const SearchInputWrapper = styled('div')(({ theme }) => ({
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: theme.palette.grey[50]
}));

const Transcription = forwardRef(({data, setData, onSpeakerColorsUpdate}, ref) => {
    const containerRef = useRef(null);
    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [editBoxIndex, setEditBoxIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [speakers, setSpeakers] = useState([]);
    const itemRefs = useRef([]);
    const {triggerRefresh} = useRefresh();
    const [teamId, setTeamId] = useState(null);
    const [speakerColors, setSpeakerColors] = useState({});

    const {transcript = {utterances: [], speakerNames: []}, metaData = {}} = data;
    const {utterances = [], speakerNames = []} = transcript;

    const theme = useTheme();
    const textColors = theme.palette.text;

    // Define speaker colors - this ensures a consistent set of distinct colors
    const SPEAKER_COLOR_PALETTE = [
        RacingGreenPalette.primary.main,       // British Racing Green - #004225
        RacingGreenPalette.accent.green3,      // Light racing green - #008040
        RacingGreenPalette.secondary.main,     // Dark goldenrod - #b8860b
        RacingGreenPalette.accent.green4,      // Lighter racing green - #00994d
        RacingGreenPalette.accent.bronze,      // Bronze - #cd7f32
        RacingGreenPalette.primary.light,      // Lighter racing green - #005c33
        RacingGreenPalette.accent.silver,      // Silver - #aaa9ad
        RacingGreenPalette.secondary.light,    // Lighter gold - #d6a81d
    ];

    useEffect(() => {
        const fetchSpeakersData = async () => {
            try {
                const response = await axios.get(
                    `${config.backendUrlApiV1}/transcript/${metaData.transcriptId}/get_speakers_initial/`,
                    {
                        params: {
                            meetingId: metaData.meetingId
                        }
                    }
                );
                if (response.data.speakers) {
                    setSpeakers(response.data.speakers);
                    
                    // Generate consistent unique colors for each speaker
                    const colors = {};
                    
                    // Create a map of speaker names to colors
                    const speakerNameColors = {};
                    const uniqueSpeakerNames = [...new Set(response.data.speakers.map(s => s.speakerName))];
                    
                    uniqueSpeakerNames.forEach((name, index) => {
                        speakerNameColors[name] = SPEAKER_COLOR_PALETTE[index % SPEAKER_COLOR_PALETTE.length];
                    });
                    
                    // Assign colors by speakerId if available, otherwise by name
                    response.data.speakers.forEach((speaker, index) => {
                        if (speaker.speakerId) {
                            colors[speaker.speakerId] = SPEAKER_COLOR_PALETTE[index % SPEAKER_COLOR_PALETTE.length];
                        }
                        // Also store by name for fallback
                        colors[speaker.speakerName] = speakerNameColors[speaker.speakerName];
                    });
                    
                    setSpeakerColors(colors);
                }
                if (response.data.teams?.selected?.id) {
                    setTeamId(response.data.teams.selected.id);
                }
            } catch (error) {
                console.error('Error fetching speakers data:', error);
            }
        };
        
        if (metaData.transcriptId && metaData.meetingId) {
            fetchSpeakersData();
        }
    }, [metaData.transcriptId, metaData.meetingId]);

    // Update parent component when speaker colors change
    useEffect(() => {
        if (onSpeakerColorsUpdate && Object.keys(speakerColors).length > 0) {
            onSpeakerColorsUpdate(speakerColors);
        }
    }, [speakerColors, onSpeakerColorsUpdate]);

    const getSpeakerColor = (speakerId, speakerName) => {
        // Use stored color by ID if available
        if (speakerId && speakerColors[speakerId]) {
            return speakerColors[speakerId];
        }
        
        // Use stored color by name if available
        if (speakerName && speakerColors[speakerName]) {
            return speakerColors[speakerName];
        }
        
        // If we don't have a color yet, assign one based on the speaker name
        // This ensures consistent colors even without IDs
        if (speakerName) {
            // Find all unique speaker names in the utterances
            const uniqueSpeakerNames = [...new Set(utterances.map(u => u.speakerName))];
            const speakerIndex = uniqueSpeakerNames.indexOf(speakerName);
            
            if (speakerIndex !== -1) {
                const color = SPEAKER_COLOR_PALETTE[speakerIndex % SPEAKER_COLOR_PALETTE.length];
                
                // Store this color for future use
                const updatedColors = {...speakerColors};
                updatedColors[speakerName] = color;
                setSpeakerColors(updatedColors);
                
                return color;
            }
        }
        
        // Fallback to British Racing Green if no color is assigned
        return RacingGreenPalette.primary.main;
    };

    const handleSpeakerUpdate = async (utteranceIndex, speakerId) => {
        try {
            const utterance = utterances[utteranceIndex];
            const speaker = speakers.find(s => s.speakerId === speakerId);
            if (!speaker) {
                console.error('Speaker not found:', speakerId);
                return;
            }

            const response = await axios.post(
                `${config.backendUrlApiV1}/transcript/${metaData.transcriptId}/edit_speaker/`,
                {
                    speakerId: speakerId,
                    speakerName: speaker.speakerName,
                    utterance_index: utterance.jsonIndex,
                    edit_type: 'edit_utterance'
                }
            );

            if (response.status === 200) {
                triggerRefresh();
                setEditBoxIndex(null);
                setAnchorEl(null);
            }
        } catch (error) {
            console.error('Error updating speaker:', error);
        }
    };

    const handleNameClick = (event, index) => {
        setEditBoxIndex(index);
        setAnchorEl(event.currentTarget);
        setSearchTerm('');
    };

    const handleClose = () => {
        setAnchorEl(null);
        setEditBoxIndex(null);
        setSearchTerm('');
    };

    useImperativeHandle(ref, () => ({
        highlightAndScrollTo(statement) {
            const index = utterances.findIndex(u => 
                u.text.toLowerCase().includes(statement.toLowerCase())
            );
            if (index !== -1 && itemRefs.current[index]) {
                setHighlightedIndex(index);
                const element = itemRefs.current[index];
                const scrollPosition = element.offsetTop - containerRef.current.offsetTop - 100;
                containerRef.current.scrollTo({top: scrollPosition, behavior: 'smooth'});
                element.classList.remove('bounce-animation');
                void element.offsetWidth;
                element.classList.add('bounce-animation');
            }
        },
    }));

    const SpeakerMenu = ({ index, currentSpeakerId }) => {
        const filteredSpeakers = speakers.filter(speaker => {
            if (!speaker || !speaker.speakerName) {
                console.warn('Invalid speaker object:', speaker);
                return false;
            }
            return speaker.speakerName.toLowerCase().includes(searchTerm.toLowerCase());
        });

        return (
            <Menu
                anchorEl={anchorEl}
                open={editBoxIndex === index}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        maxHeight: 300,
                        width: 250,
                    },
                }}
            >
                <SearchInputWrapper>
                    <SearchIcon sx={{ color: 'action.active', fontSize: 20 }} />
                    <InputBase
                        placeholder="Search speakers..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        autoFocus
                        className="search-input"
                        sx={{ fontSize: '14px' }}
                    />
                </SearchInputWrapper>
                
                {filteredSpeakers.map((speaker) => {
                    const isSelected = speaker.speakerId === currentSpeakerId;
                    const speakerColor = getSpeakerColor(speaker.speakerId, speaker.speakerName);
                    
                    return (
                        <MenuItem
                            key={speaker.speakerId}
                            onClick={() => handleSpeakerUpdate(index, speaker.speakerId)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                py: 1,
                                px: 2,
                            }}
                        >
                            <Box 
                                sx={{ 
                                    width: 3, 
                                    height: 16, 
                                    bgcolor: speakerColor,
                                    mr: 1,
                                    borderRadius: 1
                                }}
                            />
                            
                            <span style={{ 
                                marginLeft: 4,
                                fontWeight: isSelected ? 500 : 400,
                                color: speakerColor // Apply speaker color to the name
                            }}>
                                {speaker.speakerName}
                            </span>
                            
                            {isSelected && (
                                <CheckIcon 
                                    color="primary" 
                                    sx={{ 
                                        fontSize: 18, 
                                        marginLeft: 'auto' 
                                    }} 
                                />
                            )}
                        </MenuItem>
                    );
                })}
            </Menu>
        );
    };

    if (!data || !utterances || utterances.length === 0) {
        return <Typography variant="body1">No transcript provided</Typography>;
    }

    return (
        <Box className="transcription-container" ref={containerRef}>
            {utterances.map((item, index) => {
                const isCurrentUser = item.currentUser;
                const isHighlighted = index === highlightedIndex;
                const speakerColor = getSpeakerColor(item.speakerId, item.speakerName);
                
                return (
                    <Box
                        key={index}
                        id={`transcription-item-id-${index}`}
                        className={`transcription-item ${isCurrentUser ? 'current-user' : 'other-user'} ${isHighlighted ? 'highlighted' : ''}`}
                        ref={(el) => (itemRefs.current[index] = el)}
                    >
                        {!isCurrentUser && (
                            <Box className="speaker-container">
                                <Typography
                                    variant="subtitle1"
                                    className="speaker"
                                    sx={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        gap: 0.5,
                                        color: speakerColor, // Apply the speaker color directly here
                                        fontWeight: 600
                                    }}
                                >
                                    <span style={{ color: speakerColor }}>{item.speakerName}</span>
                                    <IconButton 
                                        size="small" 
                                        onClick={(e) => handleNameClick(e, index)}
                                        sx={{ 
                                            padding: '2px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                            }
                                        }}
                                    >
                                        <KeyboardArrowDownIcon className="edit-icon" fontSize="small" />
                                    </IconButton>
                                    <Typography variant="caption" className="time">
                                        {item.startTimeFormatted}
                                    </Typography>
                                </Typography>
                                <SpeakerMenu 
                                    index={index}
                                    currentSpeakerId={item.speakerId}
                                />
                            </Box>
                        )}
                        <Box
                            className={`text ${isCurrentUser ? 'current-user' : 'other-user'} ${isHighlighted ? 'highlighted-text' : ''}`}
                        >
                            <Typography variant="body1" sx={{ 
                                fontFamily: '"Roboto", Arial, sans-serif',
                                fontSize: '14px',
                                color: textColors.primary,
                                lineHeight: 1.5
                            }}>
                                {item.text}
                            </Typography>
                        </Box>
                        {isCurrentUser && (
                            <Typography variant="caption" className="time" sx={{ alignSelf: 'flex-end', mr: 1 }}>
                                {item.startTimeFormatted}
                            </Typography>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
});

export default Transcription;
