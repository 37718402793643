import React, {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import {
    Typography, 
    Button, 
    Grid, 
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import TeamCard from './TeamCard';
import './css/Teams.css';
import CreateTeamPanel from "./CreateTeamPanel";
import config from '../../config';
import DeleteModal from "../../components/deletemodal/DeleteModal";

const Teams = () => {
    const [teams, setTeams] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [isCreateTeamOpen, setIsCreateTeamOpen] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/team/user-teams/`);
            setTeams(response.data || []);
        } catch (error) {
            console.error('Error fetching teams:', error);
            setTeams([]);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const [teamsResponse, companiesResponse] = await Promise.all([
                    axios.get(`${config.backendUrlApiV1}/team/user-teams/`),
                    axios.get(`${config.backendUrlApiV1}/user/companies/`)
                ]);
    
                const fetchedCompanies = companiesResponse.data.allCompanies || [];
                setTeams(teamsResponse.data || []);
                setCompanies(fetchedCompanies);
    
                let defaultCompany = null;
                if (fetchedCompanies.length > 0) {
                    defaultCompany = fetchedCompanies.find(
                        company => company.name !== 'Elevaide Demo Company'
                    );
                    
                    if (!defaultCompany) {
                        defaultCompany = fetchedCompanies[0];
                    }
                }
                
                setSelectedCompany(defaultCompany); 
                
                if (defaultCompany) {
                    setUserRole(defaultCompany.role || 'member');
                } else {
                    setUserRole('member');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setCompanies([]);
                setTeams([]);
                setSelectedCompany(null);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const filteredTeams = useMemo(() => {
        if (!selectedCompany) {
            return [];
        }
        return teams.filter(team => team.company?.id === selectedCompany.id);
    }, [teams, selectedCompany]);

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.id === companyId);
        setSelectedCompany(company);
        if (company) {
            setUserRole(company.role || 'member');
        }
    };

    const handleCreateTeam = () => {
        setSelectedTeamId(null);
        setIsCreateTeamOpen(true);
    };

    const closeCreateTeam = () => {
        setIsCreateTeamOpen(false);
        setSelectedTeamId(null);
    };

    const handleTeamCardClick = (teamId) => {
        setSelectedTeamId(teamId);
        setIsCreateTeamOpen(true);
    };

    const handleDeleteClick = (team) => {
        setTeamToDelete(team);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setTeamToDelete(null);
    };

    const deleteTeam = async () => {
        if (!teamToDelete) return;
        try {
            await axios.delete(`${config.backendUrlApiV1}/team/${teamToDelete.id}/`);
            await fetchTeams();
            closeDeleteModal();
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    const canCreateTeam = () => {
        return !!selectedCompany;
    };
    

    const canDeleteTeam = (team) => {
        return true;
    };

    return (
        <Box sx={{ 
            display: 'flex',          // Use flexbox
            flexDirection: 'column',  // Arrange children vertically
            height: '100%',           // Fill height of parent (tab-panel)
        }}> 
            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 3 }}>
                {companies.length > 1 && (
                    <FormControl sx={{ minWidth: 200, width: { xs: '100%', sm: 'auto' } }} size="small">
                        <InputLabel id="company-select-label">Company</InputLabel>
                        <Select
                            labelId="company-select-label"
                            id="company-select"
                            value={selectedCompany?.id || ''}
                            label="Company"
                            onChange={handleCompanyChange}
                            disabled={loading}
                        >
                            {companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleCreateTeam}
                    disabled={!canCreateTeam() || loading}
                    sx={{ width: { xs: '100%', sm: 'auto' } }} 
                >
                    Create New Team
                </Button>
            </Box>
        
            <Box 
                className="teams-content"
                sx={{
                    flexGrow: 1,        // Allow this area to grow
                    overflowY: 'auto',  // Add scrollbar if content overflows
                }}
            > 
                {loading ? (
                    <Typography sx={{ textAlign: 'center', mt: 4 }}>Loading...</Typography>
                ) : !selectedCompany ? (
                    <Typography variant="body1" sx={{ textAlign: 'center', mt: 4 }}>
                        {companies.length > 0 ? "Select a company to view teams." : "You are not assigned to any companies."}
                    </Typography>
                ) : filteredTeams.length === 0 ? (
                    <Typography variant="body1" className="no-teams-message" sx={{ textAlign: 'center', mt: 4 }}>
                        No teams found for {selectedCompany.name}. 
                        {canCreateTeam() ? " Click 'Create New Team' to add one." : ""}
                    </Typography>
                ) : (
                    <Grid container spacing={3} className="teams-grid">
                        {filteredTeams.map((team) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={team.id}>
                                <TeamCard
                                    team={team}
                                    onClick={() => handleTeamCardClick(team.id)}
                                    onDeleteClick={() => handleDeleteClick(team)}
                                    showDeleteButton={canDeleteTeam(team)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            {isCreateTeamOpen && (
                <CreateTeamPanel
                    isOpen={isCreateTeamOpen}
                    onClose={closeCreateTeam}
                    teamId={selectedTeamId}
                    initialCompanyId={!selectedTeamId ? selectedCompany?.id : null}
                    onSave={async () => {
                        await fetchTeams();
                        closeCreateTeam();
                    }}
                />
            )}
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={closeDeleteModal}
                onDelete={deleteTeam}
                title="Delete Team"
                message={`Are you sure you want to delete the team "${teamToDelete?.name}"? This action cannot be undone.`}
            />
        </Box >
    );
};

export default Teams;
