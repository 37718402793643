import * as React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthButtons from './AuthButtons';
import { useAuth } from './AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import ElevaideLogo from '../images/logos/elevaide-logo-inverted.png';
import config from '../config';
import RacingGreenPalette from '../RacingGreenPalette';

const theme = createTheme({
    palette: {
        primary: { main: RacingGreenPalette.primary.main }, // Use palette primary
        background: { 
            default: RacingGreenPalette.background.main, // Use palette background
            paper: '#FFFFFF' 
        },
        text: {
            primary: RacingGreenPalette.text.primary, // Use palette text
            secondary: RacingGreenPalette.text.secondary, // Use palette text
        },
        racingGreen: RacingGreenPalette.primary.main, // Reference primary racing green
        error: { main: '#d32f2f' }, // Standard MUI error color (keep as is or add to palette if needed)
    },
    shadows: ['none', '0px 4px 16px rgba(0, 0, 0, 0.1)'],
});

export default function SignIn() {
    const { user, error: authError, isLoading, handleLogin } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(''); // Added OTP state
    const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been requested
    const [localError, setLocalError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State for submission loading

    useEffect(() => {
        if (!isLoading && !isSubmitting && user?.isAuthenticated) {
            navigate('/product', { replace: true });
        }
    }, [user, isLoading, isSubmitting, navigate]);
    // Check for redirect errors from social login on component mount
    const location = useLocation();
    useEffect(() => {
        console.log("Checking for redirect errors..."); // Log: Hook runs
        const params = new URLSearchParams(location.search);
        const errorParam = params.get('error');
        console.log("URL Search Params:", location.search); // Log: URL params
        console.log("Parsed errorParam:", errorParam); // Log: Parsed error

        if (errorParam === 'access_denied') {
            console.log("Access denied error detected, setting local error."); // Log: Condition met
            setLocalError('Access Denied. Please contact jelle@elevaide.com if you believe this is an error.');
            // Clean the URL parameters to prevent error showing on refresh
            navigate(location.pathname, { replace: true });
        } else {
            console.log("No access denied error found in URL params."); // Log: Condition not met
        }
    }, [location, navigate]); // Dependencies


    const handleRequestOtp = async (event) => {
        event.preventDefault();
        setLocalError('');
        setIsSubmitting(true);
        setOtpSent(false); // Reset OTP sent status on new request

        try {
            // Use axios.post
            const response = await axios.post(`${config.backendUrlApiV1}/auth/otp/request/`, {
                email: email,
            });

            // Axios wraps the response data in `response.data`
            if (response.status === 200) {
                setOtpSent(true); // Show OTP input field
                setLocalError(''); // Clear previous errors
                // Optionally focus the OTP input field here
            } else {
                 // This part might not be reached if axios throws an error for non-2xx status
                 throw new Error(response.data.error || 'Failed to request OTP.');
            }

        } catch (error) {
            // Axios errors often have response data in error.response.data
            if (error.response?.status === 403) {
                // Specific message for whitelist denial
                setLocalError('Access Denied. Please contact jelle@elevaide.com if you believe this is an error.');
            } else {
                // General error handling
                const message = error.response?.data?.error || error.message || 'An unexpected error occurred.';
                setLocalError(message);
            }
            setOtpSent(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleVerifyOtp = async (event) => {
        event.preventDefault();
        setLocalError('');
        setIsSubmitting(true);

        try {
            // Use axios.post
            const response = await axios.post(`${config.backendUrlApiV1}/auth/otp/verify/`, {
                email: email,
                otp: otp,
            });

            // Axios wraps the response data in `response.data`
            const data = response.data;

            if (response.status === 200 && data.key && data.user) {
                 handleLogin(data.key, data.user.email, 'otp'); // Use 'otp' as provider
                 navigate('/product', { replace: true });
            } else {
                 // This part might not be reached if axios throws an error for non-2xx status
                 // Or if the response structure is unexpected
                 throw new Error(data.error || 'OTP verification failed or response missing data.');
            }

        } catch (error) {
            // Axios errors often have response data in error.response.data
            if (error.response?.status === 403) {
                 // Specific message for whitelist denial
                 setLocalError('Access Denied. Please contact jelle@elevaide.com if you believe this is an error.');
            } else {
                 // General error handling
                 const message = error.response?.data?.error || error.message || 'An unexpected error occurred.';
                 setLocalError(message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };


    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                sx={{
                    background: `linear-gradient(180deg, ${RacingGreenPalette.background.main} 0%, ${RacingGreenPalette.background.highlight} 100%)`, // Use palette colors
                }}
            >
                <CircularProgress sx={{ color: theme.palette.primary.main }} />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    background: `linear-gradient(180deg, ${RacingGreenPalette.background.main} 0%, ${RacingGreenPalette.background.highlight} 100%)`, // Use palette colors
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 2,
                }}
            >
                <Container
                    component="main"
                    sx={{
                        maxWidth: '400px !important',
                        width: '100%',
                        backgroundColor: RacingGreenPalette.background.card,
                        borderRadius: '16px',
                        boxShadow: theme.shadows[1],
                        textAlign: 'center',
                        py: '20px', // Reduced padding top/bottom
                        px: '30px',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            mb: '-30px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={ElevaideLogo}
                            alt="Elevaide"
                            style={{ width: '300px', height: 'auto' }}
                        />
                    </Box>
                    <Typography
                        variant="h1"
                        sx={{
                            color: theme.palette.primary.main,
                            fontSize: '24px',
                            fontWeight: 700,
                            mb: '0px', // Removed margin bottom
                        }}
                    >
                        Welcome back!
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.secondary, // Already using theme which uses palette
                            fontSize: '16px',
                            mb: '30px',
                        }}
                    >
                        Access your account
                    </Typography>
                    {(authError || localError) && ( // Display auth context error OR local form error
                        <Box
                            sx={{
                                backgroundColor: 'rgba(234, 67, 53, 0.1)',
                                borderRadius: '8px',
                                p: 2,
                                mb: '20px',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ color: theme.palette.error.main }} // Use error color for errors
                            >
                                {localError || authError}
                            </Typography>
                        </Box>
                    )}

                    {/* Email OTP Form */}
                    {/* Use different handlers based on whether OTP input is shown */}
                    <Box component="form" onSubmit={otpSent ? handleVerifyOtp : handleRequestOtp} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={isSubmitting || otpSent} // Disable email field after OTP is sent
                        />

                        {/* Conditionally render OTP field */}
                        {otpSent && (
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="otp"
                                label="One-Time Password"
                                type="text" // Or "number" - consider inputMode="numeric" pattern="[0-9]*"
                                id="otp"
                                autoComplete="one-time-code"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                disabled={isSubmitting}
                                inputProps={{ maxLength: 6 }} // Assuming 6-digit OTP
                            />
                        )}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting || !email} // Disable if no email or submitting
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: RacingGreenPalette.primary.dark, // Use dark variant from palette
                                },
                            }}
                        >
                            {isSubmitting ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : otpSent ? (
                                'Verify OTP & Sign In'
                            ) : (
                                'Send Login Code'
                            )}
                        </Button>
                         {/* Optionally add a way to resend OTP or go back */}
                         {otpSent && (
                             <Button
                                 fullWidth
                                 variant="text"
                                 onClick={() => { setOtpSent(false); setOtp(''); setLocalError(''); }} // Reset state to re-enter email
                                 disabled={isSubmitting}
                                 sx={{ color: theme.palette.primary.main, textTransform: 'none', mb: 2 }}
                             >
                                 Use a different email address?
                             </Button>
                         )}
                    </Box>

                    <Divider sx={{ my: 3 }}>OR</Divider>

                    {/* Social Auth Buttons */}
                    <AuthButtons />
                </Container>
            </Box>
        </ThemeProvider>
    );
}
