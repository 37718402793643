import React from 'react';
import {
    Box, 
    IconButton, 
    Typography, 
    Slider, 
    Button,
    Paper
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import DownloadIcon from '@mui/icons-material/Download';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import RacingGreenPalette from '../../../RacingGreenPalette';

const AudioControls = ({ 
    currentTime, 
    duration, 
    isPlaying, 
    onPlayPause, 
    onSeek, 
    onSkip,
    playbackSpeed,
    onSpeedChange,
    audioUrl,
    onDownload 
}) => {
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    return (
        <Paper
            elevation={2}
            className="audio-controls-card"
            sx={{
                width: '100%',
                mb: 2,
                borderTop: `1px solid ${RacingGreenPalette.primary.main}`,
                borderBottom: '1px solid #e0e0e0',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: 1,
                backgroundColor: 'white',
                padding: '0',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            {/* Header */}
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                px: 3,
                py: 1.5,
                borderBottom: `1px solid ${RacingGreenPalette.primary.main}22`,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HeadphonesIcon 
                        fontSize="small" 
                        sx={{ 
                            color: RacingGreenPalette.primary.main,
                            mr: 1
                        }} 
                    />
                    <Typography 
                        variant="h6"
                        sx={{ 
                            color: RacingGreenPalette.text.primary,
                            fontWeight: 600,
                            fontSize: '14px'
                        }}
                    >
                        Audio Controls
                    </Typography>
                </Box>
                
                <Typography 
                    variant="body2" 
                    sx={{ 
                        color: '#555',
                        fontSize: '13px',
                        fontWeight: 500
                    }}
                >
                    {formatTime(currentTime)} / {formatTime(duration)}
                </Typography>
            </Box>

            {/* Progress bar with green dot */}
            <Box sx={{ position: 'relative', px: 3, mt: 2, mb: 1 }}>
                <Box sx={{ 
                    position: 'relative', 
                    height: '4px', 
                    backgroundColor: '#e0e0e0',
                    borderRadius: '2px'
                }}>
                    {/* Filled part of the slider */}
                    <Box 
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            width: `${(currentTime / duration) * 100}%`,
                            backgroundColor: RacingGreenPalette.primary.main,
                            borderRadius: '2px'
                        }}
                    />
                    
                    {/* Green dot indicator */}
                    <Box
                        sx={{
                            position: 'absolute',
                            left: `${(currentTime / duration) * 100}%`,
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: RacingGreenPalette.primary.main,
                            zIndex: 2
                        }}
                    />
                    
                    {/* Invisible slider for interaction */}
                    <Slider
                        value={currentTime}
                        max={duration}
                        onChange={onSeek}
                        sx={{ 
                            position: 'absolute',
                            top: -8,
                            padding: 0,
                            height: 20,
                            width: '100%',
                            opacity: 0,
                            cursor: 'pointer'
                        }}
                    />
                </Box>
            </Box>

            {/* Controls row */}
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                px: 3,
                pb: 1.5,
                position: 'relative'
            }}>
                <IconButton 
                    onClick={() => onSkip(-15)}
                    size="small"
                    sx={{ color: '#555', mr: 1 }}
                >
                    <SkipPreviousIcon fontSize="small" />
                </IconButton>
                
                <IconButton 
                    onClick={onPlayPause}
                    size="small"
                    sx={{ 
                        color: 'white',
                        backgroundColor: RacingGreenPalette.primary.main,
                        '&:hover': {
                            backgroundColor: RacingGreenPalette.primary.dark
                        },
                        width: 36,
                        height: 36
                    }}
                >
                    {isPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" />}
                </IconButton>
                
                <IconButton 
                    onClick={() => onSkip(15)}
                    size="small"
                    sx={{ color: '#555', ml: 1 }}
                >
                    <SkipNextIcon fontSize="small" />
                </IconButton>
                
                <Box sx={{ 
                    display: 'flex', 
                    position: 'absolute',
                    right: 24,
                    alignItems: 'center'
                }}>
                    <Button
                        onClick={() => onSpeedChange()}
                        size="small"
                        sx={{ 
                            minWidth: 'auto',
                            color: '#555',
                            mr: 1,
                            fontSize: '13px'
                        }}
                    >
                        {playbackSpeed.toFixed(1)}×
                    </Button>
                    
                    <Button
                        startIcon={<DownloadIcon fontSize="small" />}
                        onClick={onDownload}
                        disabled={!audioUrl}
                        size="small"
                        sx={{
                            textTransform: 'none',
                            color: RacingGreenPalette.primary.main,
                            fontSize: '13px'
                        }}
                    >
                        Download
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default AudioControls;
