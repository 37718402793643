import React from 'react';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer, Legend } from 'recharts';
import { Box, Typography } from '@mui/material';
import RacingGreenPalette from '../../../RacingGreenPalette';

const IndicatorsRadarChart = ({ data, view, allData }) => {
    if (!data || data.length === 0) {
        return (
            <Box sx={{ 
                height: 300, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                color: RacingGreenPalette.text.secondary,
                fontFamily: "'Roboto', Arial, sans-serif", // Consistent font family
                fontSize: '24px' // Consistent font size
            }}>
                <Typography sx={{ 
                    fontFamily: "'Roboto', Arial, sans-serif", 
                    fontSize: '24px',
                    color: RacingGreenPalette.text.secondary
                }}>
                    No valid indicator data available
                </Typography>
            </Box>
        );
    }

    // Transform data to include both team and individual scores separately
    const transformedData = data.map(item => {
        const teamScore = allData.find(teamItem => teamItem.indicator === item.indicator)?.score || 0;
        return {
            indicator: item.indicator,
            individual: item.score,
            team: teamScore
        };
    });

    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart 
                cx="50%" 
                cy="50%" 
                outerRadius="80%" 
                data={transformedData}
                style={{ 
                    background: 'transparent',
                    fontFamily: "'Roboto', Arial, sans-serif" // Consistent font family
                }}
            >
                {/* <PolarGrid 
                    stroke={RacingGreenPalette.text.muted} 
                    gridType="circle"  // Add circular grid type
                    strokeWidth={0.5}  // Make the grid lines thinner
                /> */}
                Add outline
                <PolarGrid 
                    stroke={RacingGreenPalette.primary.light}
                    gridType="polygon"
                    strokeWidth={0.5}
                    radialLines={false}
                />
                <PolarAngleAxis 
                    dataKey="indicator" 
                    tick={{ 
                        fill: RacingGreenPalette.text.primary, 
                        fontSize: 14,
                        fontFamily: "'Roboto', Arial, sans-serif" // Consistent font family
                    }}
                />
                <PolarRadiusAxis 
                    angle={30} 
                    domain={[0, 100]} 
                    tick={{ 
                        fill: RacingGreenPalette.text.secondary,
                        fontSize: 12,
                        fontFamily: "'Roboto', Arial, sans-serif" // Consistent font family
                    }}
                    stroke={RacingGreenPalette.text.muted}
                />
                <Radar
                    name="Team"
                    dataKey="team"
                    stroke={RacingGreenPalette.radarColors.team.stroke}
                    fill={RacingGreenPalette.radarColors.team.fill}
                    fontSize={14}
                    fillOpacity={0.3}
                    strokeWidth={2}
                />
                {view !== 'all' && (
                    <Radar
                        name={view}
                        dataKey="individual"
                        stroke={RacingGreenPalette.radarColors.individual.stroke}
                        fill={RacingGreenPalette.radarColors.individual.fill}
                        fillOpacity={0.3}
                        strokeWidth={2}
                        fontSize={14}
                    />
                )}
                <Legend 
                    wrapperStyle={{
                        fontFamily: "'Roboto', Arial, sans-serif",
                        fontSize: '24px',
                        color: RacingGreenPalette.text.primary
                    }}
                />
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default IndicatorsRadarChart;
