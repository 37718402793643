import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Typography,
    Button,
    Alert,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    ListSubheader
} from '@mui/material';
import {
    Close as CloseIcon,
    GroupAdd as GroupAddIcon,
    Error as ErrorIcon,
    Business as BusinessIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import axios from 'axios';
import config from '../../../config';
import EditableSpeakerMatchingPreview from './SpeakerMatchingPreview';
import RacingGreenPalette from '../../../RacingGreenPalette';

const MAX_POLLING_TIME = 180000; // 3 minutes maximum polling time
const POLLING_INTERVAL = 2000;   // 2 seconds between polls

const SectionHeader = ({ children }) => (
    <ListSubheader sx={{
        fontWeight: 'bold',
        color: RacingGreenPalette.primary.main,
        lineHeight: '2.5em',
        backgroundColor: 'background.paper'
    }}>
        {children}
    </ListSubheader>
);

const sortTeamsByName = (a, b) => a.name.localeCompare(b.name);

const LoadingState = ({ message }) => (
    <Box sx={{ textAlign: 'center', py: 4 }}>
        <CircularProgress size={40} sx={{ mb: 3 }} />
        <Typography variant="h6" gutterBottom>
            {message}
        </Typography>
    </Box>
);

const TeamSelectionAndStart = ({
    currentTeam,
    availableTeams,
    onTeamSelect,
    error,
    onStart,
    isProcessing,
    hasExistingMappings,
    onEditExisting,
    loadingMessage,
    teamsLoading
}) => {
    const hasSelectableTeams = Array.isArray(availableTeams) && availableTeams.some(item =>
        React.isValidElement(item) && item.type === MenuItem && item.props.value
    );

    if (teamsLoading) {
         return <LoadingState message="Loading Teams..." />;
    }

    if (!hasSelectableTeams && !teamsLoading) {
        return (
            <Alert severity="warning" sx={{ mt: 2 }}>
                No teams available to assign. Please ensure you are part of a team.
            </Alert>
        );
    }

    return (
        <Box sx={{ textAlign: 'center', maxWidth: 480, mx: 'auto', py: 4 }}>
            {isProcessing ? (
                <LoadingState message={loadingMessage} />
            ) : (
                <>
                    <BusinessIcon 
                        sx={{ 
                            fontSize: 48,
                            color: 'primary.main',
                            mb: 3
                        }}
                    />
                    <Typography variant="h6" gutterBottom>
                        Speaker Matching
                    </Typography>
                    <Typography 
                        color="text.secondary" 
                        sx={{ mb: 4 }}
                    >
                        Select a team to start matching speakers in the transcript with their identities.
                    </Typography>

                    <Box sx={{ mt: 4 }}>
                        <FormControl 
                            error={!!error} 
                            sx={{ 
                                mb: 4,
                                width: '70%',
                                mx: 'auto'
                            }}
                        >
                            <InputLabel id="team-select-label">Select Team</InputLabel>
                            <Select
                                labelId="team-select-label"
                                id="team-select"
                                value={currentTeam?.id || ''}
                                label="Select Team"
                                onChange={(e) => {
                                    if (typeof e.target.value === 'string' && e.target.value.startsWith('header-')) return;
                                    onTeamSelect(e.target.value)
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: '300px',
                                        }
                                    }
                                }}
                            >
                                {availableTeams}
                            </Select>
                            {error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>                            
                            <Button
                                variant="contained"
                                onClick={onEditExisting}
                                startIcon={<EditIcon />}
                                disabled={!currentTeam || isProcessing}
                                sx={{ 
                                    borderRadius: 1,
                                    px: 3,
                                    py: 1,
                                    width: '280px'
                                }}
                            >
                                Match Speakers Manually
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};


const SpeakerMatchingDialog = ({
    open,
    onClose,
    transcriptId,
    meetingId,
    onMatchingComplete,
    currentTeam,
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(currentTeam?.id || '');
    const [teamError, setTeamError] = useState(null);
    const [predictedMatches, setPredictedMatches] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);
    const [audioSnippets, setAudioSnippets] = useState({});
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [hasExistingMappings, setHasExistingMappings] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [taskId, setTaskId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [pollingInterval, setPollingInterval] = useState(null);
    const [allApiTeams, setAllApiTeams] = useState([]);
    const [managerTeamIds, setManagerTeamIds] = useState(new Set());
    const [coachTeamIds, setCoachTeamIds] = useState(new Set());
    const [teamsLoading, setTeamsLoading] = useState(true);


    useEffect(() => {
        if (open) {
            setActiveStep(0);
            setError(null);
            setIsProcessing(false);
            setSelectedTeamId(currentTeam?.id || '');
            setTeamError(null);
            setPredictedMatches(null);
            setAudioSnippets({});
            setCheckingStatus(true);
            setTeamsLoading(true);
            fetchTeamsAndRoles().then(() => {
                 checkSpeakerMappingStatus();
            });
        } else {
            if (pollingInterval) {
                clearInterval(pollingInterval);
                setPollingInterval(null);
            }
        }
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [open, currentTeam, transcriptId]);


    const fetchTeamsAndRoles = async () => {
        setTeamsLoading(true);
        setManagerTeamIds(new Set());
        setCoachTeamIds(new Set());
        setAllApiTeams([]);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/user/teams/`);
            const fetchedTeams = response.data.allTeams || [];
            const managerTeams = response.data.teamsUserIsManager || [];
            const coachTeams = response.data.teamsUserIsCoach || [];

            setManagerTeamIds(new Set(managerTeams.map(t => t.id)));
            setCoachTeamIds(new Set(coachTeams.map(t => t.id)));
            setAllApiTeams(fetchedTeams);

        } catch (error) {
            console.error('Error fetching teams:', error);
            setError('Failed to load available teams.');
            setManagerTeamIds(new Set());
            setCoachTeamIds(new Set());
            setAllApiTeams([]);
        } finally {
            setTeamsLoading(false);
        }
    };


    const availableTeamsDropdownItems = useMemo(() => {
        const items = [];

        const managedTeams = allApiTeams
            .filter(team => managerTeamIds.has(team.id))
            .sort(sortTeamsByName);
        const coachedTeams = allApiTeams
            .filter(team => coachTeamIds.has(team.id) && !managerTeamIds.has(team.id))
            .sort(sortTeamsByName);
        const otherTeams = allApiTeams
            .filter(team => !managerTeamIds.has(team.id) && !coachTeamIds.has(team.id))
            .sort(sortTeamsByName);

        const myTeams = [...managedTeams, ...otherTeams].sort(sortTeamsByName);
        if (myTeams.length > 0) {
            items.push(<SectionHeader key="header-my-teams">My Teams</SectionHeader>);
            myTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        if (coachedTeams.length > 0) {
            items.push(<SectionHeader key="header-coached">Coached Teams</SectionHeader>);
            coachedTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        const hasSelectable = items.some(item => React.isValidElement(item) && item.type === MenuItem);
        if (!hasSelectable && !teamsLoading) {
             items.push(<MenuItem key="no-teams" disabled>No teams available</MenuItem>);
        }

        return items;
    }, [allApiTeams, managerTeamIds, coachTeamIds, teamsLoading]);


    const checkSpeakerMappingStatus = async () => {
        try {
            setCheckingStatus(true);
            const response = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcriptId}/speaker_matching_status/`
            );
            setHasExistingMappings(response.data.status === 'completed');
        } catch (error) {
            console.error('Error checking speaker mapping status:', error);
            setError('Failed to check speaker mapping status');
        } finally {
            setCheckingStatus(false);
        }
    };

    const fetchExistingMappings = async () => {
        try {
            setIsProcessing(true);
            setLoadingMessage('Loading Existing Mappings...');
            setLoadingAudio(true);
    
            if (selectedTeamId) {
                await fetchTeamMembers(selectedTeamId);
            } else {
                console.warn('No selected team available when fetching mappings');
            }
    
            const speakersResponse = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcriptId}/get_speakers_initial/`,
                {
                    params: { meetingId }
                }
            );
    
            const speakers = speakersResponse.data.speakers.map(speaker => ({
                speakerId: speaker.speakerId,
                userId: speaker.userId,
                userName: speaker.speakerName,
                confidence: speaker.automatedMatchConfidence,
                isEdited: true,
                isAutomatedMatch: speaker.isAutomatedMatch,
                isGuest: speaker.isGuest
            }));
    
            setPredictedMatches(speakers);
    
            await fetchAudioSnippets();
            
            setActiveStep(1);
    
        } catch (error) {
            console.error('Error fetching existing mappings:', error);
            setError(error.response?.data?.error || error.message || 'Failed to load existing mappings');
        } finally {
            setIsProcessing(false);
            setLoadingAudio(false);
            setLoadingMessage('');
        }
    };
    

    const fetchTeamMembers = async (teamId) => {
        try {
            setTeamError(null);
            const response = await axios.get(
                `${config.backendUrlApiV1}/team/${teamId}/members/`
            );
            
            setTeamMembers(response.data);
            
            return response.data;
        } catch (error) {
            console.error('Error fetching team members:', error);
            setTeamMembers([]);
            setTeamError(
                error.response?.data?.error || 
                error.message || 
                'Failed to fetch team members'
            );
            return [];
        }
    };
    
        

    const fetchAudioSnippets = async () => {
        try {
            setLoadingAudio(true);
            setLoadingMessage('Loading Audio Snippets...');
            const response = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcriptId}/get_audio_snippets/`,
                {
                    params: { meetingId }
                }
            );
            setAudioSnippets(response.data.audioSnippets || {});
        } catch (error) {
            console.error('Error fetching audio snippets:', error);
            setError('Failed to load audio snippets');
        } finally {
            setLoadingAudio(false);
            setLoadingMessage('');
        }
    };
    

    const handleTeamSelect = async (teamId) => {
        setSelectedTeamId(teamId);
        setTeamError(null);
        setError(null);

        if (teamId) {
            await fetchTeamMembers(teamId);
        } else {
            setTeamMembers([]);
        }
    };
    

    const getStatusMessage = (status, meta) => {
        if (meta?.status) {
            return meta.status;
        }
        
        switch (status) {
            case 'PENDING':
                return 'Initializing...';
            case 'STARTED':
                return 'Processing started...';
            case 'PROCESSING':
                return 'Processing speakers...';
            case 'SUCCESS':
                return 'Completed successfully';
            case 'FAILURE':
                return 'Processing failed';
            default:
                return 'Analyzing speakers...';
        }
    };
    
    const pollTaskStatus = async (taskId) => {
        try {
            const response = await axios.get(
                `${config.backendUrlApiV1}/task-status/${taskId}/`
            );
            
            const { status, state, result, error, completed } = response.data;
            
            setLoadingMessage(getStatusMessage(status, response.data?.meta));
            
            if (completed) {
                setIsProcessing(false);
                setLoadingMessage('');
                
                if (error) {
                    setError(error);
                    return true;
                }
                
                if (result?.matches) {
                    setPredictedMatches(result.matches);
                    await fetchAudioSnippets();
                    setActiveStep(1);
                    return true;
                }
                
                setError('Invalid response format from server');
                return true;
            }
            
            return false;
            
        } catch (error) {
            console.error('Polling error:', error);
            return false;
        }
    };
    
    const startPolling = async (taskId) => {
        const MAX_POLLING_TIME = 180000;
        const POLLING_INTERVAL = 2000;
        const startTime = Date.now();
        
        const poll = async () => {
            if (Date.now() - startTime > MAX_POLLING_TIME) {
                clearInterval(pollingInterval);
                setError('Operation timed out. Please try again.');
                setIsProcessing(false);
                setLoadingMessage('');
                return;
            }
            
            const isDone = await pollTaskStatus(taskId);
            if (isDone) {
                clearInterval(pollingInterval);
            }
        };
        
        const pollingInterval = setInterval(poll, POLLING_INTERVAL);
        setPollingInterval(pollingInterval);
        
        await poll();
    };

    const startMatching = async () => {
        try {
            setIsProcessing(true);
            setLoadingMessage('Starting Speaker Analysis...');
            setError(null);
            
            if (!selectedTeamId) {
                throw new Error('Missing required data');
            }
    
            const response = await axios.post(
                `${config.backendUrlApiV1}/match-speakers-automated/`, {
                    transcript_id: transcriptId,
                    team_id: selectedTeamId,
                    create_voice_prints: true
                }
            );
    
            if (response.data.error) {
                throw new Error(response.data.error);
            }
    
            setTaskId(response.data.task_id);
            await startPolling(response.data.task_id);
    
        } catch (error) {
            console.error('Speaker matching error:', error);
            setError(error.response?.data?.error || error.message || 'Failed to start speaker matching');
            setIsProcessing(false);
            setLoadingMessage('');
        }
    };

    useEffect(() => {
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [pollingInterval]);

    useEffect(() => {
        if (open && selectedTeamId) {
            fetchTeamMembers(selectedTeamId);
        }
    }, [open, selectedTeamId]);
    
    

    const handleConfirmMatches = async (matches) => {
        try {
            setIsProcessing(true);
            setError(null);
    
            if (!selectedTeamId) {
                throw new Error("No team selected for applying matches.");
            }

            const updates = matches.map(match => {
                const initialMatch = predictedMatches.find(m => m.speakerId === match.speakerId);
                const wasChanged = !initialMatch || 
                                 initialMatch.userId !== match.userId ||
                                 initialMatch.userName !== match.userName;

                return {
                    speaker_id: match.speakerId,
                    name: match.userName,
                    user_id: match.userId,
                    confidence: match.confidence,
                    is_guest: match.isGuest,
                    is_automated_match: !wasChanged && initialMatch?.confidence >= 0.85
                };
            });
            const updatesWithIsGuest = updates.filter(update => update.is_guest !== undefined);
            if (updatesWithIsGuest.length !== updates.length) {
                throw new Error('All updates must contain is_guest');
            }

            await axios.post(`${config.backendUrlApiV1}/transcript/${transcriptId}/edit_speaker_bulk/`, {
                meetingId,
                teamId: selectedTeamId,
                updates
            });

            setActiveStep(2);

            if (onMatchingComplete) {
                onMatchingComplete();
            }
        } catch (error) {
            console.error('Error applying matches:', error);
            setError(error.response?.data?.error || error.message || 'Failed to apply speaker matches');
        } finally {
            setIsProcessing(false);
        }
    };


    const handleClose = () => {
        if (!isProcessing) {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
            onClose();
        }
    };

    const renderStepContent = () => {
        const selectedTeamObject = allApiTeams.find(t => t.id === selectedTeamId);

        if (checkingStatus || teamsLoading) {
            return <LoadingState message={teamsLoading ? "Loading Teams..." : "Checking Speaker Status"} />;
        }

        if (isProcessing || loadingAudio) {
            return <LoadingState message={loadingMessage} />;
        }

        switch (activeStep) {
            case 0:
                return (
                    <TeamSelectionAndStart
                        currentTeam={selectedTeamObject}
                        availableTeams={availableTeamsDropdownItems}
                        onTeamSelect={handleTeamSelect}
                        error={teamError}
                        onStart={startMatching}
                        isProcessing={isProcessing}
                        hasExistingMappings={hasExistingMappings}
                        onEditExisting={fetchExistingMappings}
                        loadingMessage={loadingMessage}
                        teamsLoading={teamsLoading}
                    />
                );
            case 1:
                if (!predictedMatches) {
                    return <LoadingState message={loadingMessage || "Loading Speaker Matches..."} />;
                }
                return (
                    <EditableSpeakerMatchingPreview
                        matches={predictedMatches}
                        teamMembers={teamMembers}
                        audioSnippets={audioSnippets}
                        onConfirm={handleConfirmMatches}
                        onCancel={handleClose}
                        isEditingExisting={hasExistingMappings}
                    />
                );
            case 2:
                return (
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Speaker Matching Complete
                        </Typography>
                        <Typography color="text.secondary" sx={{ mb: 3 }}>
                            All speakers have been successfully matched. You can now close this dialog.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ mt: 2 }}
                        >
                            Close
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog 
            open={open}
            onClose={isProcessing ? undefined : handleClose}
            maxWidth={false}
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 1,
                    height: '90vh',
                    width: '90vw',
                    maxWidth: '1200px',
                    maxHeight: '700px'
                }
            }}
        >
            <DialogTitle sx={{ 
                borderColor: 'divider',
                px: 3,
                py: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>

                <IconButton
                    edge="end"
                    onClick={handleClose}
                    disabled={isProcessing}
                    aria-label="close"
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ 
                p: 3,
                height: 'calc(100% - 64px)',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {error && (
                    <Alert 
                        severity="error" 
                        sx={{ 
                            mb: 3,
                            backgroundColor: 'error.lighter',
                            color: 'error.dark',
                            '& .MuiAlert-icon': {
                                color: 'error.main'
                            }
                        }}
                        icon={<ErrorIcon />}
                        action={
                            <Button 
                                color="inherit"
                                size="small" 
                                onClick={() => setError(null)}
                                sx={{ fontWeight: 500 }}
                            >
                                DISMISS
                            </Button>
                        }
                    >
                        {error}
                    </Alert>
                )}

                <Box sx={{ flex: 1, overflow: 'auto' }}>
                    {renderStepContent()}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SpeakerMatchingDialog;
