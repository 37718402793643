import React, { useState } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Box,
    Divider,
    Chip,
    Tooltip,
    Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MicIcon from '@mui/icons-material/Mic';
import config from '../../config';

const TeamCard = ({ team, onClick, onDeleteClick }) => {
    const [voicePrintLoading, setVoicePrintLoading] = useState(false);

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        onDeleteClick();
    };

    const createVoicePrints = async (e) => {
        e.stopPropagation();
        try {
            setVoicePrintLoading(true);
            
            await axios.post(`${config.backendUrlApiV1}/create-speaker-embeddings/`, {
                team_id: team.id,
                force_update: true
            });

        } catch (error) {
            console.error('Error creating voice prints:', error);
        } finally {
            setVoicePrintLoading(false);
        }
    };

    return (
        <Card
            onClick={onClick}
            sx={{
                borderRadius: 2,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.08)',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                backgroundColor: '#fff',
                '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
                },
                border: '1px solid',
                borderColor: 'grey.200',
            }}
        >
            <CardContent sx={{ 
                p: 3,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2.5
            }}>
                {/* Header Section */}
                <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
                    <Box>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontWeight: 600,
                                color: theme => theme.palette.primary.main,
                                mb: 0.5
                            }}
                        >
                            {team.name}
                        </Typography>
                        <Typography 
                            variant="body2" 
                            sx={{ 
                                color: theme => theme.palette.grey[600],
                                fontSize: '0.875rem'
                            }}
                        >
                            {team.company?.name || 'No Company'}
                        </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                        
                        <IconButton
                            onClick={handleDeleteClick}
                            size="small"
                            sx={{
                                color: 'grey.400',
                                '&:hover': {
                                    color: 'error.main',
                                }
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>

                <Divider sx={{ borderColor: 'grey.200' }} />

                {/* Manager Section */}
                <Box>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'grey.700',
                            fontWeight: 600,
                            mb: 1
                        }}
                    >
                        Manager
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={0.5}>
                        {team.manager ? (
                            <Chip
                            label={team.manager.name}
                            size="small"
                            sx={{
                                backgroundColor: 'primary.50',
                                color: 'primary.700',
                                fontWeight: 500,
                                '&:hover': {
                                    backgroundColor: 'primary.100',
                                }
                            }}
                            />
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Manager Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>

                {/* Coaches Section */}
                <Box>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'grey.700',
                            fontWeight: 600,
                            mb: 1
                        }}
                    >
                        Coaches
                    </Typography>
                    <Box display="flex" flexWrap="wrap" gap={0.5}>
                        {team.coaches && team.coaches.length > 0 ? (
                            team.coaches.map((coach) => (
                                <Chip
                                key={coach.id}
                                label={coach.name}
                                size="small"
                                sx={{
                                    backgroundColor: 'success.50',
                                    color: 'success.700',
                                    fontWeight: 500,
                                    '&:hover': {
                                        backgroundColor: 'success.100',
                                    }
                                }}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Coaches Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>

                {/* Members Section */}
                <Box>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'grey.700',
                            fontWeight: 600,
                            mb: 1
                        }}
                    >
                        Members
                    </Typography>
                    <Box 
                        display="flex" 
                        flexWrap="wrap" 
                        gap={0.5}
                        sx={{
                            maxHeight: '120px',
                            overflowY: 'auto',
                            pr: 1,
                            '&::-webkit-scrollbar': {
                                width: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                background: '#888',
                                borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                background: '#666',
                            },
                        }}
                    >
                        {team.members && team.members.length > 0 ? (
                            team.members.map((member) => (
                                <Chip
                                key={member.id}
                                label={
                                    member.name.length > 15
                                    ? `${member.name.slice(0, 12)}...`
                                    : member.name
                                }
                                size="small"
                                sx={{
                                    backgroundColor: 'grey.100',
                                    color: 'grey.700',
                                    fontWeight: 500,
                                    '&:hover': {
                                        backgroundColor: 'grey.200',
                                    }
                                }}
                                title={member.name}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Members Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Divider sx={{ borderColor: 'grey.200' }} />
                <Button
                    variant="outlined"
                    size="small"
                    onClick={createVoicePrints}
                    disabled={voicePrintLoading || (!team.members.length && !team.manager)}
                    startIcon={<MicIcon />}
                    sx={{
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontSize: '0.813rem',
                        fontWeight: 500,
                        px: 2,
                        borderColor: 'grey.300',
                        color: 'grey.700',
                        '&:hover': {
                            borderColor: 'primary.main',
                            backgroundColor: 'primary.50',
                        }
                    }}
                >
                    {voicePrintLoading ? 'Creating...' : 'Create Voice Prints'}
                </Button>
            </CardContent>
        </Card>
    );
};

export default TeamCard;
