import React, { useState, useEffect } from 'react';
import { 
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import axios from 'axios';
import config from '../config';

const PersonalityAnalysisGrid = () => {
  const [loadingStates, setLoadingStates] = useState({
    personality: false,
    grid1: false,
    grid2: false,
    grid3: false
  });
  
  const [personalityData, setPersonalityData] = useState([
    { trait: 'Neuroticism', score: 0 },
    { trait: 'Extraversion', score: 0 },
    { trait: 'Openness', score: 0 },
    { trait: 'Agreeableness', score: 0 },
    { trait: 'Conscientiousness', score: 0 },
  ]);

  useEffect(() => {
    fetchExistingAnalysis();
  }, []);

  const fetchExistingAnalysis = async () => {
    try {
      const response = await axios.get(`${config.backendUrlApiV1}/personality-analysis/`);
      if (response.data) {
        const data = response.data;
        setPersonalityData([
          { trait: 'Neuroticism', score: data.neuroticism },
          { trait: 'Extraversion', score: data.extraversion },
          { trait: 'Openness', score: data.openness },
          { trait: 'Agreeableness', score: data.agreeableness },
          { trait: 'Conscientiousness', score: data.conscientiousness },
        ]);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        console.error('Error fetching existing analysis:', error);
      }
    }
  };

  const handleAnalyze = async (gridId) => {
    setLoadingStates(prev => ({ ...prev, [gridId]: true }));
    
    if (gridId === 'personality') {
      try {
        const response = await axios.post(`${config.backendUrlApiV1}/personality-analysis/`);
        const data = response.data;
        
        setPersonalityData([
          { trait: 'Neuroticism', score: data.neuroticism },
          { trait: 'Extraversion', score: data.extraversion },
          { trait: 'Openness', score: data.openness },
          { trait: 'Agreeableness', score: data.agreeableness },
          { trait: 'Conscientiousness', score: data.conscientiousness },
        ]);
      } catch (error) {
        console.error('Error analyzing personality:', error);
      }
    }
    
    setLoadingStates(prev => ({ ...prev, [gridId]: false }));
  };

  const placeholderBoxes = Array(3).fill(null).map((_, index) => {
    const gridId = `grid${index + 1}`;
    return (
      <Grid item xs={12} md={6} key={`placeholder-${index}`}>
        <Paper
          sx={{
            p: 3,
            height: '300px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgba(0,0,0,0.02)',
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.02)',
              boxShadow: 3
            }
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Future Analysis {index + 2}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleAnalyze(gridId)}
              disabled={loadingStates[gridId]}
              sx={{ 
                bgcolor: '#ff6b6b',
                '&:hover': {
                  bgcolor: '#ff5252'
                }
              }}
            >
              {loadingStates[gridId] ? (
                <>
                  <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
                  Analyzing...
                </>
              ) : (
                'Analyze'
              )}
            </Button>
          </Box>
          <Box sx={{ 
            flex: 1, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}>
            {loadingStates[gridId] ? (
              <CircularProgress size={40} sx={{ color: '#ff6b6b' }} />
            ) : (
              <Typography color="text.secondary">
                No data available
              </Typography>
            )}
          </Box>
        </Paper>
      </Grid>
    );
  });

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 3 }}>
        Personality Analysis
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 3,
              height: '300px',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: 3
              }
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Big 5 Personality Traits
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleAnalyze('personality')}
                disabled={loadingStates.personality}
                sx={{ 
                  bgcolor: '#ff6b6b',
                  '&:hover': {
                    bgcolor: '#ff5252'
                  }
                }}
              >
                {loadingStates.personality ? (
                  <>
                    <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
                    Analyzing...
                  </>
                ) : (
                  'Analyze'
                )}
              </Button>
            </Box>
            <Box sx={{ flex: 1, position: 'relative' }}>
              {loadingStates.personality ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress size={40} sx={{ color: '#ff6b6b' }} />
                </Box>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={personalityData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="trait" 
                      angle={-45}
                      textAnchor="end"
                      height={60}
                    />
                    <YAxis 
                      domain={[0, 100]}
                      label={{ 
                        value: 'Score', 
                        angle: -90, 
                        position: 'insideLeft',
                        offset: 10
                      }}
                    />
                    <Tooltip />
                    <Bar 
                      dataKey="score" 
                      fill="#ff6b6b"
                      animationDuration={1000}
                    />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Paper>
        </Grid>

        {placeholderBoxes}
      </Grid>
    </Box>
  );
};

export default PersonalityAnalysisGrid;
