import React, {useState, useEffect, useCallback} from 'react';
import {Box, List, ListItemButton, ListItemText} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import './SettingsPanel.css';
import {useAuth} from "../../auth/AuthProvider";
import Settings from './Settings';
import Feedback from "./Feedback";

const SettingsPanel = ({setToggleFunction}) => {
    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const handleClick = useCallback((event) => {
        event.stopPropagation();
        setOpen(!open);
    }, [open]);

    const closeAll = useCallback((event) => {
        if (event) {
            event.stopPropagation();
        }
        setOpen(false);
        setSettingsOpen(false);
        setFeedbackOpen(false);
    }, []);

    useEffect(() => {
        setToggleFunction(() => handleClick);
    }, [setToggleFunction, handleClick]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeAll();
            }
        };

        const handleClickOutside = (event) => {
            if (open && !event.target.closest('.popover-box') && !event.target.closest('.settings-item')) {
                closeAll(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, closeAll]);

    const handleLogout = (event) => {
        event.stopPropagation();
        logout();
        // navigate('/');
    };

    const handleSettingsOpen = (event) => {
        event.stopPropagation();
        setSettingsOpen(true);
        setOpen(false);
    };

    const handleFeedbackOpen = (event) => {
        event.stopPropagation();
        setFeedbackOpen(true);
        setOpen(false);
    };

    return (
        <div className="action-button-container">
            {open && (
                <Box className="popover-box">
                    <List>
                        <ListItemButton onClick={handleFeedbackOpen}>
                            <ListItemText primary="Give feedback"/>
                        </ListItemButton>
                        {/* <ListItemButton onClick={handleSettingsOpen}>
                            <ListItemText primary="Settings"/>
                        </ListItemButton> */}
                        <ListItemButton onClick={handleSettingsOpen}>
                            <ListItemText primary="Profile"/>
                        </ListItemButton>
                        <ListItemButton onClick={handleLogout} className="MuiListItem-divider">
                            <ListItemText primary="Log out"/>
                        </ListItemButton>
                    </List>
                </Box>
            )}
            <Settings open={settingsOpen} onClose={closeAll}/>
            <Feedback open={feedbackOpen} onClose={closeAll}/>
        </div>
    );
};

export default SettingsPanel;
