import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Typography,
    CircularProgress,
    Tabs,
    Tab,
    Paper,
    IconButton,
    Tooltip,
    Snackbar
} from '@mui/material';
import {
    InfoOutlined as InfoOutlinedIcon,
    ContentCopy as ContentCopyIcon,
    PersonOutline as PersonOutlineIcon,
    CalendarToday as CalendarTodayIcon,
    Check as CheckIcon
} from '@mui/icons-material';
import axios from 'axios';
import config from "../../../config";
import SpeakerMatchingWrapper from './SpeakerMatchingWrapper';
import ReactMarkdown from 'react-markdown';

const ActionPointItem = ({ item, index }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = `Task: ${item.task || item}${item.assignee ? `\nAssignee: ${item.assignee}` : ''}${item.deadline ? `\nDeadline: ${item.deadline}` : ''}${item.notes ? `\nNotes: ${item.notes}` : ''}`;
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Box 
            sx={{ 
                mb: 3,
                p: 3,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                transition: 'all 0.2s ease-in-out',
                border: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    transform: 'translateY(-2px)',
                    '& .copy-button': {
                        opacity: 1,
                    }
                }
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5, mb: 1.5 }}>
                <Box
                    sx={{
                        width: 28,
                        height: 28,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'primary.main',
                        color: 'primary.contrastText',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        flexShrink: 0,
                        mt: 0.25
                    }}
                >
                    {index + 1}
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography 
                            variant="subtitle1" 
                            sx={{ 
                                fontWeight: 500,
                                color: 'text.primary',
                                lineHeight: 1.4,
                                mb: 0.5
                            }}
                        >
                            {item.task || item}
                        </Typography>
                        <Tooltip title="Copy to clipboard">
                            <IconButton 
                                onClick={handleCopy}
                                size="small"
                                className="copy-button"
                                sx={{ 
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    ml: 1
                                }}
                            >
                                {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {(item.assignee || item.deadline) && (
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                gap: 2, 
                                color: 'text.secondary',
                                bgcolor: 'action.hover',
                                p: 1,
                                borderRadius: 1,
                                mt: 1
                            }}
                        >
                            {item.assignee && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <PersonOutlineIcon sx={{ fontSize: '1rem' }} />
                                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                                        {item.assignee}
                                    </Typography>
                                </Box>
                            )}
                            {item.deadline && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                    <CalendarTodayIcon sx={{ fontSize: '1rem' }} />
                                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                                        {item.deadline}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {item.notes && (
                <Box 
                    sx={{ 
                        ml: 5.25,
                        pl: 2,
                        borderLeft: '2px solid',
                        borderColor: 'primary.main',
                        bgcolor: 'primary.lighter',
                        p: 2,
                        borderRadius: '0 8px 8px 0'
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'text.secondary',
                            lineHeight: 1.6
                        }}
                    >
                        {item.notes}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const DecisionItem = ({ item, index }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = `Decision: ${item.decision || item}${item.context ? `\nContext: ${item.context}` : ''}`;
        navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Box 
            sx={{ 
                mb: 3,
                p: 3,
                borderRadius: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                transition: 'all 0.2s ease-in-out',
                border: '1px solid',
                borderColor: 'divider',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    transform: 'translateY(-2px)',
                    '& .copy-button': {
                        opacity: 1,
                    }
                }
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5, mb: 1.5 }}>
                <Box
                    sx={{
                        width: 28,
                        height: 28,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'primary.main',
                        color: 'primary.contrastText',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        flexShrink: 0,
                        mt: 0.25
                    }}
                >
                    {index + 1}
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography 
                            variant="subtitle1" 
                            sx={{ 
                                fontWeight: 500,
                                color: 'text.primary',
                                lineHeight: 1.4
                            }}
                        >
                            {item.decision || item}
                        </Typography>
                        <Tooltip title="Copy to clipboard">
                            <IconButton 
                                onClick={handleCopy}
                                size="small"
                                className="copy-button"
                                sx={{ 
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    ml: 1
                                }}
                            >
                                {copied ? <CheckIcon color="success" /> : <ContentCopyIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            {item.context && (
                <Box 
                    sx={{ 
                        ml: 5.25,
                        pl: 2,
                        borderLeft: '2px solid',
                        borderColor: 'primary.main',
                        bgcolor: 'primary.lighter',
                        p: 2,
                        borderRadius: '0 8px 8px 0'
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'text.secondary',
                            lineHeight: 1.6
                        }}
                    >
                        {item.context}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

const CopyAllButton = ({ onClick }) => (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Tooltip title="Copy all items">
            <IconButton 
                onClick={onClick}
                sx={{ 
                    bgcolor: 'action.hover',
                    '&:hover': {
                        bgcolor: 'action.selected'
                    }
                }}
            >
                <ContentCopyIcon />
            </IconButton>
        </Tooltip>
    </Box>
);
const TranscriptViewer = ({ 
    details, 
    detailsLoading,
    onSpeakerMatchingComplete,
    removeDividers = false
}) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [checkingStatus, setCheckingStatus] = useState(true);
    const [needsSpeakerMatching, setNeedsSpeakerMatching] = useState(true);

    useEffect(() => {
        const checkSpeakerStatus = async () => {
            if (!details?.transcript?.metaData?.transcriptId) {
                setCheckingStatus(false);
                return;
            }
            
            try {
                setCheckingStatus(true);
                const response = await axios.get(
                    `${config.backendUrlApiV1}/transcript/${details.transcript.metaData.transcriptId}/speaker_matching_status`
                );
                setNeedsSpeakerMatching(response.data.status === 'pending');
            } catch (error) {
                console.error('Error checking speaker status:', error);
                // If there's an error, default to needing speaker matching
                setNeedsSpeakerMatching(true);
            } finally {
                setCheckingStatus(false);
            }
        };

        checkSpeakerStatus();
    }, [details?.transcript?.metaData?.transcriptId]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Show loading state while checking speaker status
    if (detailsLoading || checkingStatus) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    // Show speaker matching if needed
    if (needsSpeakerMatching && details?.transcript?.metaData?.transcriptId) {
        return (
            <SpeakerMatchingWrapper
                transcriptId={details.transcript.metaData.transcriptId}
                meetingId={details.transcript.metaData.meetingId}
                onMatchingComplete={() => {
                    setNeedsSpeakerMatching(false);
                    if (onSpeakerMatchingComplete) {
                        onSpeakerMatchingComplete();
                    }
                }}
            />
        );
    }

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
                borderBottom: removeDividers ? 'none' : 1, 
                borderColor: 'divider'
            }}>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="Summary" />
                    <Tab label={`Action Points (${details?.summary?.actionItems?.length || 0})`} />
                    <Tab label={`Decisions (${details?.summary?.decisions?.length || 0})`} />
                    <Tab label="Transcript" />
                </Tabs>
            </Box>

            <Box sx={{ 
                flex: 1, 
                overflow: 'auto', 
                p: 2,
                maxHeight: '95vh',
                '& .MuiDivider-root': {
                    display: removeDividers ? 'none' : 'flex'
                }
            }}>
                <TabPanel value={selectedTab} index={0}>
                    <SummaryTab summary={details?.summary?.summary} />
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                    <ActionPointsTab actionItems={details?.summary?.actionItems} />
                </TabPanel>

                <TabPanel value={selectedTab} index={2}>
                    <DecisionsTab decisions={details?.summary?.decisions} />
                </TabPanel>

                <TabPanel value={selectedTab} index={3}>
                    <TranscriptTab utterances={details?.transcript?.utterances} />
                </TabPanel>
            </Box>
        </Box>
    );
};

const SummaryTab = ({ summary }) => (
    <Box>
        {summary ? (
            <Box sx={{ 
                p: 3,
                bgcolor: 'background.paper',
                // boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                typography: 'body1',
                lineHeight: 1.6,
                '& p': {
                    mb: 1.5 
                },
                '& h1, & h2, & h3, & h4, & h5, & h6': {
                   mt: 2,
                   mb: 1,
                   fontWeight: 500
                },
                '& ul, & ol': {
                    pl: 3,
                    mb: 1.5
                },
                '& li': {
                    mb: 0.5
                }
            }}>
                <ReactMarkdown>{summary}</ReactMarkdown>
            </Box>
        ) : (
            <EmptyState
                title="No Summary Yet"
                message="Meeting summary will appear here once processed"
            />
        )}
    </Box>
);

const ActionPointsTab = ({ actionItems = [] }) => {
    const [showCopySnackbar, setShowCopySnackbar] = useState(false);

    const handleCopyAll = () => {
        const textToCopy = `# Action Points\n\n${actionItems.map((item, index) => {
            return `${index + 1}. **${item.task || item}**${
                item.assignee ? `\n   - Assignee: ${item.assignee}` : ''
            }${
                item.deadline ? `\n   - Deadline: ${item.deadline}` : ''
            }${
                item.notes ? `\n   - Notes: ${item.notes}` : ''
            }`
        }).join('\n\n')}`;
        
        navigator.clipboard.writeText(textToCopy);
        setShowCopySnackbar(true);
    };

    return (
        <Box sx={{ 
            pb: 2
        }}>
            {actionItems.length > 0 ? (
                <>
                    <CopyAllButton onClick={handleCopyAll} />
                    {actionItems.map((item, index) => (
                        <ActionPointItem key={index} item={item} index={index} />
                    ))}
                    <Snackbar
                        open={showCopySnackbar}
                        autoHideDuration={2000}
                        onClose={() => setShowCopySnackbar(false)}
                        message="All action points copied to clipboard"
                    />
                </>
            ) : (
                <EmptyState
                    title="No Action Points Yet"
                    message="Action points assigned during the meeting will appear here"
                />
            )}
        </Box>
    );
};

const DecisionsTab = ({ decisions = [] }) => {
    const [showCopySnackbar, setShowCopySnackbar] = useState(false);

    const handleCopyAll = () => {
        const textToCopy = `# Decisions\n\n${decisions.map((item, index) => {
            return `${index + 1}. **${item.decision || item}**${
                item.context ? `\n   - Context: ${item.context}` : ''
            }`
        }).join('\n\n')}`;
        
        navigator.clipboard.writeText(textToCopy);
        setShowCopySnackbar(true);
    };

    return (
        <Box sx={{ 
            pb: 2
        }}>
            {decisions.length > 0 ? (
                <>
                    <CopyAllButton onClick={handleCopyAll} />
                    {decisions.map((item, index) => (
                        <DecisionItem key={index} item={item} index={index} />
                    ))}
                    <Snackbar
                        open={showCopySnackbar}
                        autoHideDuration={2000}
                        onClose={() => setShowCopySnackbar(false)}
                        message="All decisions copied to clipboard"
                    />
                </>
            ) : (
                <EmptyState
                    title="No Decisions Yet"
                    message="Decisions made during the meeting will appear here"
                />
            )}
        </Box>
    );
};

const TranscriptTab = ({ utterances = [] }) => (
    <Box sx={{ 
        p: 3 
    }}>
        {utterances.length > 0 ? (
            <> 
                {utterances.map((utterance, index) => (
                    <TranscriptLine key={index} utterance={utterance} />
                ))}
            </>
        ) : (
            <EmptyState
                title="No Transcript Available"
                message="The meeting transcript will appear here once processed"
            />
        )}
    </Box>
);

const EmptyState = ({ title, message }) => (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 8,
        textAlign: 'center'
    }}>
        <InfoOutlinedIcon sx={{ 
            fontSize: '3rem',
            color: 'text.disabled',
            mb: 2
        }} />
        <Typography variant="h6" color="text.disabled" gutterBottom>
            {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {message}
        </Typography>
    </Box>
);

const TranscriptLine = ({ utterance }) => (
    <Box sx={{ py: 1.5 }}>
        <Typography 
            variant="subtitle2" 
            sx={{ 
                color: 'text.primary',
                fontWeight: 600,
                fontSize: '0.875rem',
                mb: 0.5,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
        >
            {utterance.speakerName}
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                • {utterance.startTimeFormatted}
            </Typography>
        </Typography>
        <Typography variant="body1" sx={{ 
            whiteSpace: 'pre-wrap',
            lineHeight: 1.6,
            color: 'text.primary'
        }}>
            {utterance.text}
        </Typography>
    </Box>
);

export default TranscriptViewer;
