import React, { useState } from 'react';
import { 
  Typography, 
  Grid, 
  Box, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Paper,
  Divider,
  useMediaQuery
} from '@mui/material';
import InteractionMap from './InteractionMap';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, ResponsiveContainer, Legend } from 'recharts';
import Transcription from "../transcript/Transcription";
import './MeetingAnalysis.css';
import IndicatorsRadarChart from './IndicatorsRadarChart';
import config from "../../../config";
import UserChip from "../../../components/usersearchinput/UserChip";
import RacingGreenPalette, { textColors } from '../../../RacingGreenPalette';
import theme from '../../../theme';

// Import MUI icons
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShareIcon from '@mui/icons-material/Share';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import VideocamIcon from '@mui/icons-material/Videocam';
import BusinessIcon from '@mui/icons-material/Business';

// Using our racing green color palette
const colors = RacingGreenPalette.chartColors;

const MeetingAnalysis = ({ data, transcript, onDataUpdate }) => {
    const [view, setView] = useState('all');
    const [transcriptData, setTranscriptData] = useState(transcript);
    const [speakerColors, setSpeakerColors] = useState({});
    

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    if (!data || Object.keys(data).length === 0) {
        return null;
    }
    
    const meetingTitle = transcript?.metaData?.meetingName || 'Unknown Meeting Name';
    const metaData = transcript.metaData;
    const viewOptions = Object.keys(data).sort((a, b) => a.localeCompare(b));

    const allData = data.all || {};
    const filteredData = view === 'all' ? allData : data[view] || {};

    const pieData = (allData.ratio || []).map(speaker => ({
        name: speaker.speakerName,
        value: speaker.speakerRatio * 100,
        speakerId: speaker.speakerId,
    }));

    const radarData = (filteredData.llmIndicators || []).map(indicator => ({
        indicator: indicator,
        score: filteredData[indicator]?.score || 0
    })).filter(item => item.score > 0);
    
    const allRadarData = allData.llmIndicators.map(indicator => ({
        indicator: indicator,
        score: allData[indicator]?.score || 0
    })).filter(item => item.score > 0);
    
    // Prepare metadata items with icons for better visual cues
    const metadataItems = [
        { icon: <InfoIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Meeting ID", value: metaData.meetingId },
        { icon: <AssessmentIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Transcript ID", value: metaData.transcriptId },
        { icon: <TodayIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Date", value: metaData.meetingDate },
        { icon: <AccessTimeIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Duration", value: metaData.meetingDuration },
        { icon: <VideocamIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Platform", value: metaData.meetingPlatform },
        { icon: <BusinessIcon sx={{ color: RacingGreenPalette.primary.main }} />, label: "Team", value: metaData.meetingTeam.name }
    ];

    // Define analysis components with improved card styling
    const analysisComponents = [
        {
            title: "Meeting Overview",
            icon: <InfoIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />,
            importance: "primary",
            content: (
                <div className="metadata-container">
                    {metadataItems.map((item, idx) => (
                        <div className="metadata-row" key={idx}>
                            <div className="metadata-icon">{item.icon}</div>
                            <Typography variant="body2" className="metadata-label" 
                                sx={{ 
                                    color: textColors.primary, 
                                    fontSize: '14px',
                                    fontFamily: '"Roboto", Arial, sans-serif'
                                }}>
                                {item.label}:
                            </Typography>
                            <Typography className="metadata-value" 
                                sx={{ 
                                    color: textColors.primary,
                                    fontSize: '14px',
                                    fontFamily: '"Roboto", Arial, sans-serif'
                                }}>
                                {item.value}
                            </Typography>
                        </div>
                    ))}
                    <Divider className="metadata-divider" />
                    <div className="metadata-row participants">
                        <div className="participants-header">
                            <GroupIcon sx={{ color: RacingGreenPalette.primary.main }} />
                            <Typography variant="subtitle2" className="metadata-label"
                                sx={{ 
                                    color: textColors.primary,
                                    fontSize: '14px',
                                    fontFamily: '"Roboto", Arial, sans-serif'
                                }}>
                                Access:
                            </Typography>
                        </div>
                        <div className="metadata-participants-grid">
                            {metaData.meetingParticipants.slice(0, 6).map(participant => (
                                <UserChip 
                                    key={participant.id} 
                                    user={participant}
                                    className="participant-chip"
                                    sx={{
                                        backgroundColor: RacingGreenPalette.background.highlight,
                                        '&:hover': {
                                            backgroundColor: `${RacingGreenPalette.primary.light}22`
                                        },
                                        fontSize: '14px',
                                        fontFamily: '"Roboto", Arial, sans-serif',
                                        color: textColors.primary
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            ),
            gridProps: {xs: 12, md: 6},
        },
        {
            title: "Key Indicators",
            color: theme.palette.secondary.main,
            icon: <AssessmentIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />,
            importance: "primary",
            content: <IndicatorsRadarChart 
                data={radarData} 
                view={view}
                allData={allRadarData}
            />,
            gridProps: {xs: 12, md: 6},
        },
        {
            title: "Interaction Map",
            color: theme.palette.primary.main,
            icon: <ShareIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />,
            importance: "secondary",
            content: <InteractionMap data={allData.interactionMap}/>,
            gridProps: {xs: 12, md: 6},
        },
        {
            title: "Speaking Time Distribution",
            icon: <AccessTimeIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />,
            importance: "secondary",
            content: (
                <div className="chart-container speaking-time">
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={pieData}
                                cx="50%"
                                cy="50%"
                                outerRadius="65%"
                                innerRadius="40%"
                                fill={RacingGreenPalette.primary.main}
                                paddingAngle={2}
                                labelLine={false}
                                label={({name, percent}) => `${(percent * 100).toFixed(0)}%`}
                            >
                                {pieData.map((entry, index) => {
                                    // Use the speaker color from Transcription if available
                                    const speakerColor = (entry.speakerId && speakerColors[entry.speakerId]) || 
                                                        (speakerColors[entry.name]) || 
                                                        colors[index % colors.length];
                                    
                                    return (
                                        <Cell 
                                            key={`cell-${index}`} 
                                            id={index} 
                                            fill={speakerColor}
                                            stroke={theme.palette.background.paper}
                                            strokeWidth={2}
                                        />
                                    );
                                })}
                            </Pie>
                            <Legend 
                                layout="vertical" 
                                verticalAlign="middle" 
                                align="right"
                                wrapperStyle={{ 
                                    fontSize: '12px', 
                                    paddingRight: '5px',
                                    color: RacingGreenPalette.text.primary
                                }}
                                formatter={(value, entry) => {
                                    const { payload } = entry;
                                    const speakerColor = (payload.speakerId && speakerColors[payload.speakerId]) || 
                                                        (speakerColors[payload.name]) || 
                                                        colors[entry.index % colors.length];
                                    
                                    return <span style={{ color: speakerColor, fontWeight: 500 }}>{value}</span>;
                                }}
                            />
                            <RechartsTooltip 
                                formatter={(value) => [`${value.toFixed(1)}%`, 'Speaking Time']}
                                labelFormatter={(name) => `${name}`}
                                contentStyle={{
                                    backgroundColor: RacingGreenPalette.background.card,
                                    borderColor: RacingGreenPalette.primary.light,
                                    color: RacingGreenPalette.text.primary
                                }}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            ),
            gridProps: {xs: 12, md: 6},
        },
    ];

    // Handle speaker colors update from Transcription component
    const handleSpeakerColorsUpdate = (colors) => {
        setSpeakerColors(colors);
    };

    return (
        <Box className="dashboard-container" sx={{ backgroundColor: 'white' }}>
            <Paper elevation={0} className="page-header" 
                sx={{ backgroundColor: RacingGreenPalette.background.card }}>
                <div className="title-container">
                    <Typography variant="h4" className="dashboard-title"
                        sx={{ color: textColors.primary }}>
                        {meetingTitle}
                    </Typography>
                </div>
                <div className="dashboard-controls">
                    <FormControl className="view-select" variant="outlined" size="small">
                        <InputLabel id="view-select-label"
                            sx={{ color: RacingGreenPalette.text.secondary }}>
                            Data View
                        </InputLabel>
                        <Select
                            labelId="view-select-label"
                            id="view-select"
                            value={view}
                            label="Data View"
                            onChange={(e) => setView(e.target.value)}
                            sx={{ 
                                color: RacingGreenPalette.text.primary,
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: `${RacingGreenPalette.primary.main}33`,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: RacingGreenPalette.primary.light,
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: RacingGreenPalette.primary.main,
                                }
                            }}
                        >
                            {viewOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option === 'all' ? 'All Participants' : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Paper>
            
            <Grid container spacing={2} className="dashboard-content">
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        {analysisComponents.map((component, index) => (
                            <Grid item {...component.gridProps} key={index}>
                                <Paper 
                                    elevation={2} 
                                    className={`dashboard-card ${component.importance}`}
                                    sx={{ 
                                        backgroundColor: RacingGreenPalette.background.card,
                                        borderTop: `1px solid ${RacingGreenPalette.primary.main}`
                                    }}
                                >
                                    <div className="card-header" style={{ backgroundColor: 'white' }}>
                                        <div className="card-title">
                                            {component.icon}
                                            <Typography 
                                                variant="h6" 
                                                className="card-title-text"
                                                sx={{ 
                                                    color: textColors.primary 
                                                }}
                                            >
                                                {component.title}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Divider sx={{ backgroundColor: `${RacingGreenPalette.primary.main}22` }} />
                                    <Box className="card-content static-content">
                                        {component.content}
                                    </Box>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={2} className="dashboard-card transcript"
                        sx={{ 
                            backgroundColor: RacingGreenPalette.background.card,
                            borderTop: `1px solid ${RacingGreenPalette.accent.green3}`
                        }}>
                        <div className="card-header" style={{ backgroundColor: 'white' }}>
                            <div className="card-title">
                                <DescriptionIcon fontSize="small" sx={{ color: RacingGreenPalette.primary.main }} />
                                <Typography 
                                    variant="h6" 
                                    className="card-title-text"
                                    sx={{ 
                                        color: textColors.highlight 
                                    }}
                                >
                                    Transcript
                                </Typography>
                            </div>
                        </div>
                        <Divider sx={{ backgroundColor: `${RacingGreenPalette.primary.main}22` }} />
                        <Box className="transcript-container" 
                            sx={{ backgroundColor: RacingGreenPalette.background.card }}>
                            <Transcription 
                                data={transcriptData} 
                                setData={setTranscriptData}
                                onSpeakerColorsUpdate={handleSpeakerColorsUpdate}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MeetingAnalysis;
