import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Typography,
    TextField,
    MenuItem,
    Button,
    Alert,
    Box,
    Stack,
    Paper,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalOverlay from '../../components/modal/ModalOverlay';
import config from '../../config';
import { UserMultiSelectionInput, UserSelectionInput } from '../../components/usersearchinput/UserSelection';

const CreateTeamPanel = ({ isOpen, onClose, teamId, onSave, initialCompanyId }) => {
    const [teamName, setTeamName] = useState('');
    const [description, setDescription] = useState('');
    const [manager, setManager] = useState(null);
    const [members, setMembers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [availableCoaches, setAvailableCoaches] = useState([]);
    const [selectedCoaches, setSelectedCoaches] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [voicePrintLoading, setVoicePrintLoading] = useState(false);
    const [voicePrintError, setVoicePrintError] = useState('');


    const fetchUserCompanies = useCallback(async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/user/companies/`);
            const userCompanies = response.data.allCompanies;
            setCompanies(userCompanies);
            
            let defaultCompany = null;
            if (!teamId && initialCompanyId) {
                 defaultCompany = userCompanies.find(c => c.id === initialCompanyId);
            } else if (userCompanies.length === 1) {
                 defaultCompany = userCompanies[0];
            }
            
            if (defaultCompany) {
                setSelectedCompany(defaultCompany);
                await fetchCompanyUsers(defaultCompany.id);
            }
        } catch (err) {
            console.error('Error fetching user companies:', err);
            setError('Failed to fetch companies. Please try again.');
        }
    }, [initialCompanyId, teamId]);

    const fetchCompanyUsers = useCallback(async (companyId) => {
        if (!companyId) return;
        try {
            setLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/companies/${companyId}/users/`);
            setAvailableUsers(response.data.members || []);
            setAvailableCoaches(response.data.coaches || []);
        } catch (err) {
            console.error('Error fetching company users:', err);
            setError('Failed to fetch company users. Please try again.');
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchTeamData = useCallback(async () => {
        if (!teamId) return;
        try {
            setLoading(true);
            const response = await axios.get(`${config.backendUrlApiV1}/team/${teamId}/`);
            const teamData = response.data;

            setTeamName(teamData.name || '');
            setDescription(teamData.description || '');
            setManager(teamData.manager || null);
            setMembers(teamData.members || []);
            setSelectedCoaches(teamData.coaches || []);
            
            const companyFromTeam = companies.find(c => c.id === teamData.company?.id) || teamData.company || null;
            setSelectedCompany(companyFromTeam);

            if (companyFromTeam) {
                await fetchCompanyUsers(companyFromTeam.id);
            }
        } catch (err) {
            console.error('Error fetching team data:', err);
            setError('Failed to fetch team data. Please try again.');
        } finally {
            setLoading(false);
        }
    }, [teamId, fetchCompanyUsers, companies]);

    useEffect(() => {
        if (isOpen) {
            fetchUserCompanies();
        }
    }, [isOpen, fetchUserCompanies]);

    useEffect(() => {
        if (isOpen && teamId && companies.length > 0) {
            fetchTeamData();
        } else if (isOpen && !teamId) {
            resetForm();
        }
    }, [isOpen, teamId, fetchTeamData, companies]);

    const resetForm = useCallback(() => {
        setTeamName('');
        setDescription('');
        setManager(null);
        setMembers([]);
        setSelectedCoaches([]);
        setError('');
        
        let defaultCompany = null;
        if (initialCompanyId) {
             defaultCompany = companies.find(c => c.id === initialCompanyId);
        } else if (companies.length === 1) {
             defaultCompany = companies[0];
        }
        
        setSelectedCompany(defaultCompany);
        if (defaultCompany) {
            fetchCompanyUsers(defaultCompany.id);
        } else {
            setAvailableUsers([]);
            setAvailableCoaches([]);
        }
    }, [companies, initialCompanyId, fetchCompanyUsers]);

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        const company = companies.find((c) => c.id === companyId);
        setSelectedCompany(company);
        setManager(null);
        setMembers([]);
        setSelectedCoaches([]);
        fetchCompanyUsers(companyId);
    };

    const handleUserSelect = (role, user) => {
        if (role === 'members') {
            setMembers((prev) => [...prev, user]);
        } else if (role === 'coaches') {
            setSelectedCoaches((prev) => [...prev, user]);
        } else if (role === 'manager') {
            setManager(user);
        }
    };
    
    const handleUserRemove = (role, userId) => {
        if (role === 'members') {
            setMembers((prev) => prev.filter((user) => user.id !== userId));
        } else if (role === 'coaches') {
            setSelectedCoaches((prev) => prev.filter((user) => user.id !== userId));
        } else if (role === 'manager') {
            setManager(null);
        }
    };

    const handleSave = async () => {
        try {
            // Validate required fields
            if (!selectedCompany?.id) {
                setError('Company is required.');
                return;
            }
    
            if (!teamName.trim()) {
                setError('Team name is required.');
                return;
            }
    
            setLoading(true);
            setError('');
    
            const teamData = {
                name: teamName.trim(),
                description: description.trim(),
                company_id: selectedCompany.id,
                manager_id: manager?.id || null,
                members_ids: members.map(m => m.id),
                coaches_ids: selectedCoaches.map(c => c.id)
            };
    
            let response;
            if (teamId) {
                response = await axios.put(
                    `${config.backendUrlApiV1}/team/${teamId}/`, 
                    teamData
                );
            } else {
                response = await axios.post(
                    `${config.backendUrlApiV1}/team/`, 
                    teamData
                );
            }
    
            if (response.data) {
                onSave(response.data);
                onClose();
            }
        } catch (err) {
            console.error('Error saving team:', err);
            if (err.response?.data?.error) {
                // Use the specific error message from the backend
                setError(err.response.data.error);
            } else {
                setError('An error occurred while saving the team. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const searchInputStyles = {
        containerSx: {
            width: '100%'
        },
        searchBoxSx: {
            width: '100%',
            '& .MuiTextField-root': {
                width: '100%'
            }
        }
    };

    return (
        <ModalOverlay 
            title={teamId ? 'Edit Team' : 'Create Team'} 
            isOpen={isOpen} 
            onClose={onClose}
        >

    {/* return (
        <ModalOverlay 
            title={teamId ? 'Edit Team' : 'Create Team'} 
            isOpen={isOpen} 
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                sx: {
                    minHeight: '80vh',
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        > */}
            <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid', 
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <Typography variant="h6">
                    {teamId ? 'Edit Team' : 'Create Team'}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box sx={{ 
                flex: 1, 
                overflowY: 'auto',
                p: 3
            }}>
                <Stack spacing={3}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                '& .MuiAlert-message': { color: 'error.main' }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
                            Company *
                        </Typography>
                        <TextField
                            select
                            value={selectedCompany?.id || ''}
                            onChange={handleCompanyChange}
                            fullWidth
                            required
                            disabled={loading || !!teamId || companies.length <= 1}
                            sx={{
                                backgroundColor: 'background.paper',
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    }
                                }
                            }}
                        >
                            {!selectedCompany && <MenuItem value="" disabled>Select a Company</MenuItem>}
                            {companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    {company.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>

                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
                            Team Name *
                        </Typography>
                        <TextField
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            fullWidth
                            required
                            disabled={loading}
                            placeholder="Enter team name"
                            sx={{
                                backgroundColor: 'background.paper',
                                '& .MuiOutlinedInput-root': {
                                    '&:hover fieldset': {
                                        borderColor: 'primary.main',
                                    }
                                }
                            }}
                        />
                    </Box>

                    <Paper 
                elevation={0}
                sx={{ 
                    p: 2.5,
                    backgroundColor: 'grey.50',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Coaches ({selectedCoaches.length})
                </Typography>
                <UserMultiSelectionInput
                    role="coaches"
                    availableUsers={availableCoaches.filter(
                        (c) => !selectedCoaches.some((s) => s.id === c.id)
                    )}
                    selectedUsers={selectedCoaches}
                    onSelect={handleUserSelect}
                    onRemove={handleUserRemove}
                    {...searchInputStyles}
                />
            </Paper>

            <Paper 
                elevation={0}
                sx={{ 
                    p: 2.5,
                    backgroundColor: 'grey.50',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Manager
                </Typography>
                <UserSelectionInput
                    role="manager"
                    availableUsers={availableUsers.filter((u) => !manager || u.id !== manager.id)}
                    selectedUser={manager}
                    onSelect={handleUserSelect}
                    onRemove={handleUserRemove}
                    placeholder="Search for a manager"
                    {...searchInputStyles}
                />
            </Paper>

            <Paper 
                elevation={0}
                sx={{ 
                    p: 2.5,
                    backgroundColor: 'grey.50',
                    border: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                    Team Members ({members.length})
                </Typography>
                <UserMultiSelectionInput
                    role="members"
                    availableUsers={availableUsers.filter((u) => !members.some((m) => m.id === u.id))}
                    selectedUsers={members}
                    onSelect={handleUserSelect}
                    onRemove={handleUserRemove}
                    {...searchInputStyles}
                />
            </Paper>
                </Stack>
            </Box>

            <Box sx={{ 
                p: 2,
                borderTop: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1
            }}>
                <Button 
                    onClick={onClose}
                    sx={{ 
                        minWidth: 100,
                        color: 'text.secondary'
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={handleSave}
                    variant="contained"
                    disabled={loading}
                    sx={{ 
                        minWidth: 100
                    }}
                >
                    {loading ? 'Saving...' : 'Save'}
                </Button>
            </Box>
        </ModalOverlay>
    );
};

export default CreateTeamPanel;
