import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    Typography,
    ListSubheader,
    useTheme
} from '@mui/material';
import axios from 'axios';
import config from '../config';
import RacingGreenPalette from '../RacingGreenPalette';

const TEAMS_CACHE_KEY = 'cachedTeams';
const ALL_TEAMS_ID = '00000000-0000-0000-0000-000000000000';
const NO_TEAM_ID = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

const sortTeamsByName = (a, b) => a.name.localeCompare(b.name);

const SectionHeader = ({ children }) => (
    <ListSubheader sx={{
        fontWeight: 'bold',
        color: RacingGreenPalette.primary.main,
        lineHeight: '2.5em',
        backgroundColor: 'background.paper'
    }}>
        {children}
    </ListSubheader>
);

const TeamFilterDropdown = ({ onFilterChange, currentFilter, userId }) => {
    const theme = useTheme();
    const defaultTeamOptions = useMemo(() => [
        { id: ALL_TEAMS_ID, name: 'All Teams' },
        { id: NO_TEAM_ID, name: 'No Team' }
    ], []);

    const [allApiTeams, setAllApiTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [managerTeamIds, setManagerTeamIds] = useState(new Set());
    const [coachTeamIds, setCoachTeamIds] = useState(new Set());

    useEffect(() => {
        const fetchTeams = async () => {
            setIsLoading(true);
            setManagerTeamIds(new Set());
            setCoachTeamIds(new Set());
            setAllApiTeams([]);

            const userSpecificCacheKey = `${TEAMS_CACHE_KEY}_${userId || 'me'}`;
            
            try {
                const url = new URL(`${config.backendUrlApiV1}/user/teams/`);
                if (userId && userId !== 'me') {
                    url.searchParams.append('userId', userId);
                }
                const response = await axios.get(url.toString());
                const managerIds = new Set(response.data.teamsUserIsManager.map(t => t.id));
                const coachIds = new Set(response.data.teamsUserIsCoach.map(t => t.id));

                setManagerTeamIds(managerIds);
                setCoachTeamIds(coachIds);

                const apiTeams = response.data.allTeams.filter(team =>
                    team.id !== ALL_TEAMS_ID && team.id !== NO_TEAM_ID
                );

                setAllApiTeams(apiTeams);

                localStorage.setItem(userSpecificCacheKey, JSON.stringify({
                    teams: response.data.allTeams,
                    timestamp: Date.now()
                }));
            } catch (error) {
                console.error('Error fetching teams:', error);
                setManagerTeamIds(new Set());
                setCoachTeamIds(new Set());
                setAllApiTeams([]);
            } finally {
                setIsLoading(false);
            }
        };
        
        fetchTeams();
    }, [userId]);

    useEffect(() => {
        if (userId) {
            onFilterChange(ALL_TEAMS_ID);
        }
    }, [userId, onFilterChange]);

    const handleTeamChange = (event) => {
        const value = event.target.value;
        if (typeof value === 'string' && (value.startsWith('header-'))) {
            return;
        }
        onFilterChange(value);
    };

    const dropdownItems = useMemo(() => {
        const managedTeams = allApiTeams
            .filter(team => managerTeamIds.has(team.id))
            .sort(sortTeamsByName);
        const coachedTeams = allApiTeams
            .filter(team => coachTeamIds.has(team.id) && !managerTeamIds.has(team.id))
            .sort(sortTeamsByName);
        const otherTeams = allApiTeams
            .filter(team => !managerTeamIds.has(team.id) && !coachTeamIds.has(team.id))
            .sort(sortTeamsByName);

        const items = [];

        // 1. Add default options ("All Teams", "No Team") first
        defaultTeamOptions.forEach(team => {
            items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
        });

        // 2. Combine managed and other teams for the "My Teams" section
        const myTeams = [...managedTeams, ...otherTeams];
        const hasMyTeams = myTeams.length > 0;

        // 3. If user has managed or other teams, add "My Teams" header and list them
        if (hasMyTeams) {
            items.push(<SectionHeader key="header-my-teams">My Teams</SectionHeader>);

            // Sort the combined list of managed and other teams
            myTeams.sort(sortTeamsByName);

            // Add MenuItems for each team under the header
            myTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        // 4. Add "Coached Teams" section if there are any
        if (coachedTeams.length > 0) {
            items.push(<SectionHeader key="header-coached">Coached Teams</SectionHeader>);
            coachedTeams.forEach(team => {
                items.push(<MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>);
            });
        }

        return items;

    }, [allApiTeams, managerTeamIds, coachTeamIds, defaultTeamOptions]);

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="team-filter-label">
                    Filter by Team {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
                </InputLabel>
                <Select
                    labelId="team-filter-label"
                    id="team-filter-select"
                    value={isLoading && !dropdownItems.some(item => React.isValidElement(item) && item.props.value === (currentFilter || ALL_TEAMS_ID)) ? '' : (currentFilter || ALL_TEAMS_ID)}
                    label="Filter by Team"
                    onChange={handleTeamChange}
                    disabled={isLoading}
                    sx={{
                        backgroundColor: 'background.paper',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[300],
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[400],
                        },
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 400,
                            },
                        },
                    }}
                >
                    {isLoading ? (
                        <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                       dropdownItems.length > 0 ? dropdownItems : <MenuItem disabled>No teams found</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Box>
    );
};

export default TeamFilterDropdown;
