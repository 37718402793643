import React from 'react';
import {Typography, Box} from '@mui/material';
import Teams from './Teams';
import './css/Company.css';
import RacingGreenPalette from '../../RacingGreenPalette';


const Company = () => {
    return (
        <Box 
            className="tab-curved-view"
            sx={{ 
                display: 'flex',          // Use flexbox
                flexDirection: 'column',  // Arrange children vertically
                height: '100%',           // Fill height allocated by parent container
            }}
        >
            <Box className="tab-header">
                <Typography 
                    variant="h5" 
                    sx={{ 
                        color: RacingGreenPalette.primary.main,
                        fontWeight: 600,
                        py: 2, 
                        px: 3, 
                    }}
                >
                    Team Management
                </Typography>
            </Box>
            <Box 
                className="tab-panel" 
                sx={{ 
                    flexGrow: 1,              // Allow this panel to grow and fill space
                    display: 'flex',          // Use flexbox internally
                    flexDirection: 'column',  // Arrange children (Teams component) vertically
                    overflow: 'hidden',       // Prevent this panel itself from scrolling
                }}
            >
                <Teams/>
            </Box>
        </Box>
    );
};

export default Company;
