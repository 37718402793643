import React, { useEffect, useRef } from 'react';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
import './InteractionMap.css';
import RacingGreenPalette from '../../../RacingGreenPalette';

const InteractionMap = ({ data }) => {
    const networkRef = useRef(null);

    useEffect(() => {
        if (networkRef.current && data && data.participants && data.interactions) {
            const { participants, interactions } = data;

            if (participants.length === 0 || interactions.length === 0) {
                return;
            }

            // Calculate optimal layout positions in a circle
            const angleStep = (2 * Math.PI) / participants.length;
            const radius = 100 + (participants.length > 10 ? (participants.length - 10) * 5 : 0);
            
            // Calculate interaction counts per participant
            const participantInteractionCounts = {};
            
            // Initialize all participants with zero count
            participants.forEach(participant => {
                participantInteractionCounts[participant.id] = 0;
            });
            
            // Count all interactions for each participant
            interactions.forEach(interaction => {
                participantInteractionCounts[interaction.from] = 
                    (participantInteractionCounts[interaction.from] || 0) + interaction.count;
                participantInteractionCounts[interaction.to] = 
                    (participantInteractionCounts[interaction.to] || 0) + interaction.count;
            });
            
            // Find min and max interaction counts for nodes
            const participantCounts = Object.values(participantInteractionCounts);
            const minNodeCount = Math.min(...participantCounts);
            const maxNodeCount = Math.max(...participantCounts);
            
            // Create a node color function that maps interaction count to color intensity
            const getNodeColor = (count) => {
                // Normalize count between 0 and 1
                const normalizedValue = maxNodeCount > minNodeCount 
                    ? (count - minNodeCount) / (maxNodeCount - minNodeCount)
                    : 0.5;
                
                // Create darker shades for higher counts
                // Start with the lighter green and move toward darkest
                const baseColor = RacingGreenPalette.primary.light; // Start with lighter green
                const darkestColor = RacingGreenPalette.primary.dark; // End with darkest
                
                // RGB values for colors
                const startRGB = hexToRgb(baseColor);
                const endRGB = hexToRgb(darkestColor);
                
                // Interpolate between colors
                const r = Math.round(startRGB.r - normalizedValue * (startRGB.r - endRGB.r));
                const g = Math.round(startRGB.g - normalizedValue * (startRGB.g - endRGB.g));
                const b = Math.round(startRGB.b - normalizedValue * (startRGB.b - endRGB.b));
                
                return `rgb(${r}, ${g}, ${b})`;
            };
            
            // Calculate node size based on interaction count
            const getNodeSize = (count) => {
                return mapRange(count, minNodeCount, maxNodeCount, 15, 30);
            };
            
            const nodes = new DataSet(
                participants.map((participant, index) => {
                    const x = radius * Math.cos(index * angleStep);
                    const y = radius * Math.sin(index * angleStep);
                    const interactionCount = participantInteractionCounts[participant.id];
                    const nodeColor = getNodeColor(interactionCount);
                    const nodeSize = getNodeSize(interactionCount);
                    
                    return {
                        id: participant.id,
                        label: String(participant.name),
                        shape: 'dot',
                        x: x,
                        y: y,
                        size: nodeSize, // Dynamic node size based on interaction count
                        title: `Total interactions: ${interactionCount}`,
                        color: {
                            background: nodeColor,
                            border: RacingGreenPalette.secondary.main,
                            highlight: {
                                background: RacingGreenPalette.primary.light,
                                border: RacingGreenPalette.secondary.light
                            }
                        },
                        font: { 
                            color: RacingGreenPalette.text.primary,
                            size: 14,
                            strokeWidth: 1.5,
                            strokeColor: '#ffffff'
                        }
                    };
                })
            );

            // Find min and max interaction counts for scaling edges
            const minEdgeCount = Math.min(...interactions.map(i => i.count));
            const maxEdgeCount = Math.max(...interactions.map(i => i.count));
            
            // Precompute edge colors and store them in a map to keep them stable
            const edgeColorMap = new Map();
            
            // Generate edge colors based on count (darker = higher count)
            const getEdgeColor = (count) => {
                // Check if we already calculated this color
                if (edgeColorMap.has(count)) {
                    return edgeColorMap.get(count);
                }
                
                // Normalize count between 0 and 1
                const normalizedValue = maxEdgeCount > minEdgeCount 
                    ? (count - minEdgeCount) / (maxEdgeCount - minEdgeCount)
                    : 0.5;
                
                // Create darker shades for higher counts
                // Start with the lightest green and move toward darkest
                const baseColor = RacingGreenPalette.accent.green4; // Start with lightest
                const darkestColor = RacingGreenPalette.primary.dark; // End with darkest
                
                // RGB values for colors (approximate)
                const startRGB = hexToRgb(baseColor);
                const endRGB = hexToRgb(darkestColor);
                
                // Interpolate between colors
                const r = Math.round(startRGB.r - normalizedValue * (startRGB.r - endRGB.r));
                const g = Math.round(startRGB.g - normalizedValue * (startRGB.g - endRGB.g));
                const b = Math.round(startRGB.b - normalizedValue * (startRGB.b - endRGB.b));
                
                const color = `rgb(${r}, ${g}, ${b})`;
                // Store in map for future use
                edgeColorMap.set(count, color);
                
                return color;
            };
            
            // Precompute all colors before creating edges
            interactions.forEach(interaction => {
                getEdgeColor(interaction.count);
            });
            
            // Create fixed edge data that won't change when nodes are moved
            const edgeData = interactions.map((interaction) => {
                const edgeColor = getEdgeColor(interaction.count);
                const width = mapRange(interaction.count, minEdgeCount, maxEdgeCount, 1.5, 8);
                
                return {
                    from: interaction.from,
                    to: interaction.to,
                    width: width, // Dynamic width based on count
                    title: `Interactions: ${String(interaction.count)}`,
                    color: {
                        color: edgeColor,
                        highlight: RacingGreenPalette.secondary.light,
                        opacity: 0.9 // Slightly increased opacity
                    },
                    smooth: { type: 'continuous' },
                    fixed: true, // Mark as fixed to prevent changes
                    physics: false // Disable physics for edges
                };
            });
            
            const edges = new DataSet(edgeData);

            const networkData = { nodes: nodes, edges: edges };

            const options = {
                nodes: {
                    borderWidth: 2,
                    shadow: {
                        enabled: true,
                        color: 'rgba(0,0,0,0.2)',
                        size: 5
                    },
                    font: {
                        color: '#333333', // Dark grey/black text to match other visualizations
                        size: 15,
                        face: 'Roboto, Arial, sans-serif',
                        bold: 'true',
                        strokeWidth: 1.5, // Reduced stroke for cleaner look
                        strokeColor: '#ffffff' // White outline
                    }
                },
                edges: {
                    selectionWidth: 2,
                    smooth: { type: 'continuous' },
                    arrows: {
                        to: {
                            enabled: false
                        }
                    },
                    hoverWidth: 1.5, // Slight increase on hover
                },
                physics: {
                    enabled: true, // Enable physics initially
                    stabilization: {
                        enabled: true,
                        iterations: 100,
                        fit: true
                    },
                    barnesHut: {
                        gravitationalConstant: -15000,
                        springConstant: 0.05,
                        springLength: 100,
                        avoidOverlap: 0.5
                    }
                },
                interaction: {
                    hover: true,
                    tooltipDelay: 200,
                    hideEdgesOnDrag: false, // Show edges during drag for better UX
                    multiselect: false,
                    dragNodes: true,
                    dragView: true
                }
            };

            const network = new Network(networkRef.current, networkData, options);

            // Disable physics after stabilization for better performance
            network.on("stabilizationIterationsDone", function() {
                network.setOptions({ physics: { enabled: false } });
            });
            
            // Prevent edge colors from changing when nodes are dragged
            network.on("dragStart", function() {
                // Lock the edges during drag operations
                edges.forEach(edge => {
                    const edgeData = edges.get(edge.id);
                    if (edgeData) {
                        // Clone the edge to prevent modification of the original
                        const edgeClone = {...edgeData};
                        edges.update(edgeClone);
                    }
                });
            });
            
            // Final protection against color changes
            let originalEdgeData = {};
            let originalNodeData = {};
            
            // Store original edge and node data
            edges.forEach(edge => {
                originalEdgeData[edge.id] = {...edge};
            });
            
            nodes.forEach(node => {
                originalNodeData[node.id] = {...node};
            });
            
            // Add a monitoring system to restore colors if they somehow change
            network.on("afterDrawing", function() {
                // Check if any edge colors have changed and restore them
                edges.forEach(edge => {
                    const currentEdge = edges.get(edge.id);
                    const originalEdge = originalEdgeData[edge.id];
                    
                    if (originalEdge && currentEdge && 
                        JSON.stringify(currentEdge.color) !== JSON.stringify(originalEdge.color)) {
                        // Restore original color
                        edges.update({
                            id: edge.id,
                            color: originalEdge.color
                        });
                    }
                });
                
                // Check and restore node colors too
                nodes.forEach(node => {
                    const currentNode = nodes.get(node.id);
                    const originalNode = originalNodeData[node.id];
                    
                    if (originalNode && currentNode && 
                        JSON.stringify(currentNode.color) !== JSON.stringify(originalNode.color)) {
                        // Restore original color
                        nodes.update({
                            id: node.id,
                            color: originalNode.color
                        });
                    }
                });
            });

            const resizeNetwork = () => {
                network.fit({
                    animation: {
                        duration: 1000,
                        easingFunction: 'easeOutQuint'
                    }
                });
            };

            resizeNetwork();
            window.addEventListener('resize', resizeNetwork);

            return () => {
                window.removeEventListener('resize', resizeNetwork);
            };
        }
    }, [data]);

    // Helper function to convert hex to RGB
    function hexToRgb(hex) {
        // Remove # if present
        hex = hex.replace(/^#/, '');
        
        // Parse hex values
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        
        return { r, g, b };
    }
    
    // Helper function to map values from one range to another
    function mapRange(value, inMin, inMax, outMin, outMax) {
        // Handle case where min equals max
        if (inMin === inMax) return outMin;
        
        return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    }

    if (!data || !data.participants || !data.interactions) {
        return (
            <div className="interaction-map-container">
                <div className="interaction-map-empty">No interaction data available</div>
            </div>
        );
    }

    return (
        <div className="interaction-map-container">
            <div ref={networkRef} className="interaction-map" />
        </div>
    );
};

export default InteractionMap;
