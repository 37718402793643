import React, { useState } from 'react';
import { Button, Stack, CircularProgress } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import config from '../config';
import RacingGreenPalette from '../RacingGreenPalette'; // Import the palette

const AuthButton = ({ 
    icon: Icon, 
    label, 
    onClick, 
    color,
    borderColor,
    hoverBg,
    hoverBorder,
    ariaLabel 
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        try {
            await onClick();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            variant="outlined"
            startIcon={!isLoading && <Icon />}
            onClick={handleClick}
            disabled={isLoading}
            fullWidth
            aria-label={ariaLabel}
            sx={{
                height: '50px',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: 500,
                textTransform: 'none',
                backgroundColor: '#FFFFFF',
                border: `2px solid ${borderColor}`, // Use borderColor prop
                color: color, // Use color prop
                transition: 'all 0.3s ease',
                position: 'relative',
                '&:hover': {
                    backgroundColor: hoverBg, // Use hoverBg prop
                    borderColor: hoverBorder, // Use hoverBorder prop
                    transform: 'scale(1.02)',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                },
                '&:active': {
                    transform: 'scale(0.98)',
                },
                '&:focus-visible': {
                    outline: 'none',
                    // Use racing green for focus outline - using rgba derived from primary.main
                    boxShadow: `0 0 0 3px ${RacingGreenPalette.primary.main}4D`, // Append alpha hex value (30% opacity)
                },
                '@media (max-width: 600px)': {
                    height: '48px',
                    fontSize: '15px',
                }
            }}
        >
            {isLoading ? (
                <CircularProgress 
                    size={24} 
                    sx={{ 
                        color: RacingGreenPalette.primary.main, // Use primary green for spinner
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-12px'
                    }} 
                />
            ) : label}
        </Button>
    );
};

export const GoogleAuth = () => {
    const handleGoogleLogin = () => {
        const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

        const scope = [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/calendar.events.readonly'
        ].join(' ');

        const params = {
            response_type: 'code',
            client_id: config.googleClientId,
            redirect_uri: config.googleRedirectUri,
            prompt: 'consent',
            access_type: 'offline',
            scope: scope,
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location.href = `${GOOGLE_AUTH_URL}?${urlParams}`;
    };

    return (
        <AuthButton
            icon={GoogleIcon}
            label="Continue with Google"
            onClick={handleGoogleLogin}
            color={RacingGreenPalette.text.secondary} // Use palette text color
            borderColor={RacingGreenPalette.text.disabled} // Use palette disabled color for border
            hoverBg={RacingGreenPalette.background.main} // Use palette background for hover
            hoverBorder={RacingGreenPalette.primary.main} // Use palette primary for hover border
            ariaLabel="Continue with Google account"
        />
    );
};


export const MicrosoftAuth = () => {
    const handleMicrosoftLogin = () => {
        const MICROSOFT_AUTH_URL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
        const scope = [
            'openid',
            'email',
            'profile',
            'offline_access',
            'User.Read',
            'Calendars.Read'
        ].join(' ');

        const params = {
            client_id: config.microsoftClientId,
            response_type: 'code',
            redirect_uri: config.microsoftRedirectUri,
            scope: scope,
            response_mode: 'query',
            prompt: 'consent'
        };

        const urlParams = new URLSearchParams(params).toString();
        window.location.href = `${MICROSOFT_AUTH_URL}?${urlParams}`;
    };

    return (
        <AuthButton
            icon={MicrosoftIcon}
            label="Continue with Microsoft"
            onClick={handleMicrosoftLogin}
            color={RacingGreenPalette.text.secondary} // Use palette text color
            borderColor={RacingGreenPalette.text.disabled} // Use palette disabled color for border
            hoverBg={RacingGreenPalette.background.main} // Use palette background for hover
            hoverBorder={RacingGreenPalette.primary.main} // Use palette primary for hover border
            ariaLabel="Continue with Microsoft account"
        />
    );
};


const AuthButtons = () => (
    <Stack spacing="15px" width="100%">
        <GoogleAuth />
        <MicrosoftAuth />
    </Stack>
);

export default AuthButtons;
