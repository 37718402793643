import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';

const ProfileSettingsForm = ({ profileData, handleProfileChange, handleFocusAreasChange, saving }) => {
    return (
        <>
            <Grid container spacing={2} sx={{ mb: 3}}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First Name"
                        name="firstName"
                        value={profileData.firstName}
                        onChange={handleProfileChange}
                        fullWidth
                        margin="dense"
                        disabled={saving}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Last Name"
                        name="lastName"
                        value={profileData.lastName}
                        onChange={handleProfileChange}
                        fullWidth
                        margin="dense"
                        disabled={saving}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Job Title"
                        name="jobTitle"
                        value={profileData.jobTitle}
                        onChange={handleProfileChange}
                        fullWidth
                        margin="dense"
                        disabled={saving}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Email Address"
                        name="email"
                        value={profileData.email}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                {/* Removed empty Grid item */}
                <Grid item xs={12}>
                    <TextField
                        label="Personal Growth Goals"
                        name="personalGrowthGoals"
                        value={profileData.personalGrowthGoals}
                        onChange={handleProfileChange}
                        multiline
                        rows={3}
                        fullWidth
                        margin="dense"
                        disabled={saving}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ProfileSettingsForm;
